import { Name } from "app/core/decorators";
import { CalendarWorkShiftPattern } from "app/core/models/entity-models/calendarWorkShiftPattern";
import { CalendarWorkShiftException } from "app/core/models/entity-models/calendarWorkShiftException";
import { WorkShift } from "app/core/models/entity-models/workShift";
import { CalendarWorkShift } from "app/core/models/entity-models/calendarWorkShift";
import { CalendarWorkShiftWorkDay } from "app/core/models/entity-models/calendarWorkShiftWorkDay";
import { Calendar } from "app/core/models/entity-models/calendar";
import { BaseModel } from "..";

/** Calendars : Work Shifts */
@Name("CalendarWorkShift")
export class CalendarWorkShiftFull extends CalendarWorkShift {
    name = "CalendarWorkShift";

    /** Calendar Code */
    public calendar: Calendar;
    /** Work Shift Code */
    public workShift: WorkShift;

    public calendarWorkShiftPatterns: Array<CalendarWorkShiftPattern>;
    public workShiftExceptions: Array<CalendarWorkShiftException>;
    public calendarWorkShiftDays: Array<CalendarWorkShiftWorkDay>;

    public mapRelatedTypes() {
        if (this.calendar) {
            this.calendar = BaseModel.changeType(
                this.calendar,
                Calendar
            ) as any;
        }
        if (this.workShift) {
            this.workShift = BaseModel.changeType(
                this.workShift,
                WorkShift
            ) as any;
        }
    }
}
