import { BaseModel } from "..";
import { Name } from "app/core/decorators";

/** Calendars : Time Buckets */
@Name("CalendarTimeBucket")
export class CalendarTimeBucket extends BaseModel {
    name = "CalendarTimeBucket";
    public calendarCE: string;
    public timeBucketTY: string;
    public timeBucketSQ: number;
    public timeBucketStartTimeDT: Date;
    public timeBucketEndTimeDT: Date;
}
