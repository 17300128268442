import { formatDate } from "@angular/common";
import {
    UnitOfMeasure,
    QrmProductBomFull,
    SalesOrderLine,
    QrmProductBom,
} from "./entity-models";

export class QrmSimulation {
    //simulation
    public qrmProductBom: QrmProductBom;
    public unitOfMeasure: UnitOfMeasure;
    public qrmProductBomKey: string;
    public unitCE: string;
    public neededQT: number;
    public startDT: string | Date;
    public dueDT: string | Date;

    //feasible date
    public isLoading?: boolean;

    public feasibleTimeTY?: string;
    public forceFutureStartFL?: boolean;
    public useCellCapacityThresholdFL?: boolean;
    public includeOverdueFL?: boolean;
    public ignoreUnreleasedTicketsFL?: boolean;
    public ignoreUnscheduledTicketsFL?: boolean;
    public incrementTimeQT?: number;
    public incrementTimeUnitCE?: string;
    public maximumIterationNB?: number;
    public latestFeasibleTimeDT?: Date;

    public feasibleStartTimeDT?: Date;
    public feasibleDueTimeDT?: Date;

    // generate ticket
    public pastAllowedFL?: boolean;
    public autoReleaseFL?: boolean;
    public autoScheduleFL?: boolean;
    public rushOrderFL?: boolean;

    //feasible date and generate ticket
    public processTimeMethodTY?: string;
    public processTimeDetailTY?: string;
    public excludePhantomFL?: boolean;
    salesOrderLine: SalesOrderLine;
    displayAdvanced: boolean = false;
    calculatedEndDT: Date;
    calculatedStartDT: Date;
    showProducts: boolean;
    productCE: string;
    salesOrderNO: string;
    salesOrderLineSQ: string;
    customerNO: string;
    constructor() {
        this.feasibleTimeTY = "START";
        var tomorrow = new Date();
        tomorrow.setDate(new Date().getDate() + 1);
        this.startDT = formatDate(tomorrow, "yyyy-MM-ddT00:00", "en");
        this.processTimeMethodTY = "PROCESSTIME";
        this.processTimeDetailTY = "SHIFT";
        this.excludePhantomFL = true;
        this.forceFutureStartFL = true;
        this.useCellCapacityThresholdFL = true;
        this.includeOverdueFL = true;
        this.ignoreUnreleasedTicketsFL = true;
        this.ignoreUnscheduledTicketsFL = true;
        this.incrementTimeQT = 1;
        this.incrementTimeUnitCE = "day";
        this.maximumIterationNB = 10;

        this.pastAllowedFL = false;
        this.autoReleaseFL = true;
        this.autoScheduleFL = true;
    }
}
