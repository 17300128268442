import { TransTracingBase } from "app/core/models";
import { Name } from "app/core/decorators";

/** Focused Target Market Segment - Translations */
@Name("FocusedTrgMarketSgmTrans")
export class FocusedTrgMarketSgmTrans extends TransTracingBase {
    name = "FocusedTrgMarketSgmTrans";
    /** Capacity Cluster Code                                               PK */
    public ftmsCE: string;
    /** Focused Target Market Segment Label */
    public ftmsLB: string;
    /** Focused Target Market Segment Description */
    public ftmsDS: string;
    /** Focused Target Market Segment Info (Hyperlink) */
    public ftmsUR: string;
    /** Focused Target Market Segment Info (Hypertext) */
    public ftmsHT: string;
    /** Focused Target Market Segment Info (Preview of the Hypertext)       Virtual attribute */
    public ftmsPH: string;
}
