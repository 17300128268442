import { Name } from "app/core/decorators";
import { QrmProductBomExplosionFull } from "app/core/models/entity-models/qrmProductBomExplosionFull";
import { BaseModel } from "app/core/models";
import { TreeNode } from "primeng/api";

/** QRM Products : Bills of Material : Explosion */
@Name("QrmProductBomExplosion")
export class QrmProductBomExplosion extends BaseModel {
    name = "QrmProductBomExplosion";
    /** QRM Product Code                                                                        PK */
    public qrmProductCE: string;
    /** QRM Product Bill of Material Sequence Number                                            PK */
    public qrmProductBomSQ: number;
    /** Explosion Line Sequence Number                                                          PK */
    public explosionLineSQ: number;
    /** Explosion Level Sequence Number */
    public explosionLevelSQ: number;

    //#region parent ID
    /** Bill of Material QRM Product Code of Part QRM Product */
    public partQrmProductCE: string;
    /** Bill of Material QRM Product Bill of Material Sequence Number of Part QRM Product */
    public partQrmProductBomSQ: number;
    //#endregion

    /** Bill of Material QRM Product Bill of Material Part Sequence Number */
    public partQrmProductBomPartSQ: number;
    /** Exploded QRM Product Code */
    public explQrmProductCE: string;
    /** Exploded QRM Product Bill of Material Sequence Number */
    public explQrmProductBomSQ: number;
    /** Part Quantity per Parent (per Batch) */
    public partQT: number;
    /** Part Quantity Unit of Measure Code */
    public partQuantityUnitCE: string;
    /** Part Quantity per Parent (per Batch) in Stocking Unit */
    public stockQT: number;
    /** Stock Quantity Unit of Measure Code */
    public stockQuantityUnitCE: string;
    /** Multiply With Quantity Type */
    public multiplyWithQuantityTY: string;
    /** Expected Scrap Percentage */
    public expectedScrapPC: number;
    /** Expected Yield Percentage */
    public expectedYieldPC: number;
    /** Earliest Start Date */
    public earliestStartDT: Date;
    /** Latest Start Date */
    public latestStartDT: Date;
    /** Earliest End Date */
    public earliestEndDT: Date;
    /** Latest End Date */
    public latestEndDT: Date;
    /** Expected Amount of Processing Time */
    public processTimeQT: number;
    /** Expected Amount of Processing Time Unit Code */
    public processTimeUnitCE: string;
    /** Expected Processing Time Type */
    public processTimeTY: string;
    /** Needed Capacity Cluster Quantity */
    public capacityClusterQT: number;
    /** Capacity Cluster Code */
    public capacityClusterCE: string;
    /** QRM Cell Code */
    public qrmCellCE: string;

    branchStartDT: Date;

    get isBOM(): boolean {
        if (!this.explQrmProductBomSQ) {
            return false;
        }
        return true;
    }

    public static getTree(
        bomExplosions: Array<QrmProductBomExplosionFull>,
        rootLevel: number = 0
    ): QrmProductBomExplosionFull[] {
        if (!bomExplosions) return;

        for (let i = 0; i < bomExplosions.length; i++) {
            const children = bomExplosions.filter(
                (be) =>
                    be.partQrmProductCE === bomExplosions[i].explQrmProductCE &&
                    be.partQrmProductBomSQ ===
                        bomExplosions[i].explQrmProductBomSQ &&
                    be.parentExplosionLineSQ ===
                        bomExplosions[i].explosionLineSQ
            );
            bomExplosions[i].children = children.length > 0 ? children : null;
        }
        const root = bomExplosions.filter(
            (be) => be.explosionLevelSQ === rootLevel
        );

        return root;
    }

    public static getTreeTable(
        bomExplosions: Array<QrmProductBomExplosionFull>,
        parent
    ): TreeNode<QrmProductBomExplosionFull>[] {
        const result = bomExplosions.filter(
            (d) => d.parentExplosionLineSQ === parent
        );

        if (!result && !result.length) {
            return null;
        }

        return result.map(
            (x) =>
                ({
                    data: x,
                    children: this.getTreeTable(
                        bomExplosions,
                        x.explosionLineSQ
                    ),
                } as TreeNode<QrmProductBomExplosionFull>)
        );
    }
}
