import { Organization } from "app/core/models/entity-models/organization";
import { Name } from "app/core/decorators";
import { IBaseEntity } from "app/core/interfaces/IBaseEntity";
import { Calendar } from "app/core/models/entity-models/calendar";

/** Organizations */
@Name("Organization")
export class OrganizationFull extends Organization implements IBaseEntity {
    name = "Organization";
    /** General Calendar */
    public calendar: Calendar;
    focusedTrgMarketSgm: any;
}
