import { TransTracingBase } from "app/core/models";
import { Name } from "app/core/decorators";

/** Units of Measure - Translations */
@Name("UnitOfMeasureTrans")
export class UnitOfMeasureTrans extends TransTracingBase {
    name = "UnitOfMeasureTrans";
    /** Unit of Measure Code                                PK */
    public unitCE: string;
    /** Unit of Measure Label */
    public unitLB: string;
    /** Unit of Measure Description */
    public unitDS: string;
    /** Unit of Measure Info (Hyperlink) */
    public unitUR: string;
    /** Unit of Measure Info (Hypertext) */
    public unitHT: string;
    /** Unit of Measure Info (Preview of the Hypertext)     Virtual attribute */
    public unitPH: string;
}
