import { QrmProductFamily } from "app/core/models/entity-models/qrmProductFamily";
import { Name } from "app/core/decorators";
import { IBaseEntity } from "app/core/interfaces/IBaseEntity";

/** QRM Product Families */
@Name("QrmProductFamily")
export class QrmProductFamilyFull
    extends QrmProductFamily
    implements IBaseEntity
{
    name = "QrmProductFamily";
    /** Parent QRM Product Family */
    public qrmProductFamily: QrmProductFamily;
}
