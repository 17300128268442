import { TransTracingBase } from "app/core/models";
import { Name } from "app/core/decorators";

/** Sales Orders : Statuses - Translations */
@Name("SalesOrderStatusTrans")
export class SalesOrderStatusTrans extends TransTracingBase {
    name = "SalesOrderStatusTrans";
    /** Sales Order Status Code                                PK */
    public salesOrderStatusCE: string;
    /** Sales Order Status Label */
    public salesOrderStatusLB: string;
    /** Sales Order Status Description */
    public salesOrderStatusDS: string;
    /** Sales Order Status Info (Hyperlink) */
    public salesOrderStatusUR: string;
    /** Sales Order Status Info (Hypertext) */
    public salesOrderStatusHT: string;
}
