import { UnitOfMeasureTrans } from "app/core/models/entity-models/unitOfMeasureTrans";
import { Name } from "app/core/decorators";
import { IBaseEntity } from "app/core/interfaces/IBaseEntity";

/** Units of Measure */
@Name("UnitOfMeasure")
export class UnitOfMeasure extends UnitOfMeasureTrans implements IBaseEntity {
    name = "UnitOfMeasure";
    baseEditRouteSegment = "unit-of-measure";
    public get id(): string {
        return this.unitCE;
    }
    public errorMessage: string;
    /** Unit of Measure Type */
    public unitTY: string;
    /** Number of Rounding Decimals */
    public roundingDecimalNB: number;

    /** <unitCE> <unitLB> <unitTY> */
    protected get caption(): string {
        return `${this.unitLB}`;
    }

    protected get displayWithChips() {
        return `${this.unitLB} (${this.unitTY})`;
    }

    public listItemLayout(): object {
        return {
            label: {
                property: "unitLB",
            },
            inBrackets: {
                property: "tracingDT",
            },
            key: {
                property: "unitCE",
            },
            title: {
                property: "unitDS",
            },
            description: {
                property: "tracingDT",
            },
            leftBottomList: [],
            rightBottomList: [
                {
                    property: "unitTY",
                },
                {
                    toSlice: 200,
                    property: "roundingDecimalNB",
                },
            ],
        };
    }
}
