import { NgModule, Injector } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import "hammerjs";
import { AppComponent } from "app/app.component";
import { LayoutModule } from "app/layout/layout.module";
import { AuthenticationService } from "app/core/authentication/authentication.service";

import { APP_BASE_HREF } from "@angular/common";
import {
    PaginatorIntlService,
    AuditInterceptorService,
    EntityService,
} from "app/core/services";
import { MatMomentDatetimeModule } from "@mat-datetimepicker/moment";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { DateAdapter } from "@angular/material/core";
import { MatLegacyPaginatorIntl as MatPaginatorIntl } from "@angular/material/legacy-paginator";
import { MatLegacySnackBarModule as MatSnackBarModule } from "@angular/material/legacy-snack-bar";
import { RouterModule, Routes } from "@angular/router";
import { MatLegacyDialogModule as MatDialogModule } from "@angular/material/legacy-dialog";
import { AuthHttpInterceptor, AuthModule } from "@auth0/auth0-angular";
import {
    TranslateModule,
    TranslateService,
} from "./core/lib/translate/public_api";
import { SharedDirectivesModule } from "./core/directives/directives";
import { environment } from "environments/environment";
export let AppInjector: Injector;
const routes: Routes = [
    {
        path: "",
        loadChildren: () =>
            import("app/layout/layout.module").then((m) => m.LayoutModule),
    },
];
@NgModule({
    declarations: [AppComponent],
    imports: [
        RouterModule.forRoot(routes),
        BrowserModule,
        LayoutModule,
        BrowserAnimationsModule,
        HttpClientModule,
        MatSnackBarModule,
        HttpClientModule,
        TranslateModule.forRoot(),
        MatDialogModule,
        MatMomentDatetimeModule,
        SharedDirectivesModule,
        AuthModule.forRoot({
            domain: environment.auth0Domain,
            clientId: environment.auth0ClientId,
            authorizationParams: {
                redirect_uri: window.location.origin,
            },
            httpInterceptor: {
                allowedList: [
                    {
                        uri: "*",
                        tokenOptions: {
                            authorizationParams: {
                                audience: environment.auth0Audience,
                                scope: "openid profile email",
                            },
                        },
                    },
                ],
            },
        }),
    ],
    bootstrap: [AppComponent],
    providers: [
        AuthenticationService,
        EntityService,
        {
            provide: MatPaginatorIntl,
            useFactory: (translate: TranslateService) => {
                const service = new PaginatorIntlService();
                service.injectTranslateService(translate);
                return service;
            },
            deps: [TranslateService],
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuditInterceptorService,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthHttpInterceptor,
            multi: true,
        },
        { provide: DateAdapter, useClass: MomentDateAdapter },
        { provide: APP_BASE_HREF, useValue: "/" },
    ],
})
export class AppModule {
    constructor(private readonly injector: Injector) {
        AppInjector = this.injector;
    }
}
