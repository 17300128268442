import { QrmProductBomTrans } from "app/core/models/entity-models/qrmProductBomTrans";
import { Name } from "app/core/decorators";
import { IBaseEntity } from "app/core/interfaces/IBaseEntity";

/** QRM Products : Bills of Material */
@Name("QrmProductBom")
export class QrmProductBom extends QrmProductBomTrans implements IBaseEntity {
    name = "QrmProductBom";
    baseEditRouteSegment = "qrm-product-bom";
    public get id(): string {
        if (!this.qrmProductCE || !this.qrmProductBomSQ) return null;
        return `${this.qrmProductCE},${this.qrmProductBomSQ}`;
    }
    public errorMessage: string;

    /** QRM Product Bill of Material Type */
    public qrmProductBomTY: string;
    /** Default Batch Quantity */
    public defaultBatchQT: number;
    /** Default Batch Quantity Unit Code */
    public defaultBatchUnitCE: string;
    /** Expected Yield Percentage */
    public expectedYieldPC: number;
    /** Capacity Cluster Code */
    public capacityClusterCE: string;
    /** Quantity per Capacity Cluster */
    public capacityClusterQT: number;
    /** Capacity Cluster Quantity Unit Code */
    public capacityClusterQTUnitCE: string;
    /** Lead Time */
    public leadTimeQT: number;
    /** Lead Time Unit Code */
    public leadTimeUnitCE: string;
    /** Fixed Process Time */
    public fixedProcessTimeNB: number;
    /** Fixed Process Time Unit Code */
    public fixedProcessTimeUnitCE: string;
    /** Variable Process Time per Unit */
    public variableProcessTimeNB: number;
    /** Variable Process Time per Unit Unit Code */
    public variableProcessTimeUnitCE: string;
    /** Variable Process Unit Code */
    public variableProcessUnitCE: string;

    /** <qrmProductBomLB> <qrmProductCE> */
    public get caption(): string {
        return `${this.qrmProductBomLB} ${this.qrmProductCE} ${this.qrmProductBomDS}`;
    }

    public get qrmProductBomKey(): string {
        return this.qrmProductCE + "," + this.qrmProductBomSQ;
    }
    public set qrmProductBomKey(v: string) {
        if (v && v.split(",").length > 1) {
            this.qrmProductCE = v.split(",")[0];
            this.qrmProductBomSQ = +v.split(",")[1];
        } else {
            this.qrmProductBomSQ = null;
        }
    }
    public listItemLayout(): object {
        return {
            label: { property: "qrmProductBomLB" },
            inBrackets: { property: "defaultBatchUnitCE" },
            key: { property: "tracingDT" },
            title: { property: "qrmProductBomDS" },
            description: { property: "tracingDT" },
            leftBottomList: [],
            rightBottomList: [
                { property: "qrmProductBomTY" },
                { toSlice: 200, property: "leadTimeQT" },
                { toSlice: 200, property: "defaultBatchQT" },
                { toSlice: 200, property: "capacityClusterQT" },
                { toSlice: 200, property: "expectedYieldPC" },
            ],
        };
    }
}
