import { Name } from "app/core/decorators";
import { ProjectTrans } from "app/core/models/entity-models/projectTrans";
import { IBaseEntity } from "app/core/interfaces/IBaseEntity";

/** Projects */
@Name("Project")
export class Project extends ProjectTrans implements IBaseEntity {
    name = "Project";
    baseEditRouteSegment = "project";

    public get id(): string {
        return this.projectNO;
    }

    public errorMessage: string;

    /** Project Status Code */
    public projectStatusCE: string;
    /** Forward Project Status Code             Virtual Attribute */
    public fwdProjectStatusCE: string;
    /** Reverse Project Status Code             Virtual Attribute */
    public revProjectStatusCE: string;
    /** Project Type */
    public projectTY: string;
    /** Customer Number */
    public customerNO: string;
    /** Project Report Type Code*/
    public projectReportTypeCE: string;

    /** <projectNO> <projectLB> */
    public get caption(): string {
        return `${this.projectNO} ${this.projectLB}`;
    }

    public listItemLayout(): object {
        return {
            label: { property: "projectLB" },
            inBrackets: { property: "tracingDT" },
            key: { property: "projectNO" },
            title: { property: "projectDS" },
            description: { property: "tracingDT" },
            leftBottomList: [],
            rightBottomList: [
                { property: "projectTY" },
                { toSlice: 200, property: "projectReportTypeCE" },
                { toSlice: 200, property: "customerNO" },
                { toSlice: 200, property: "projectStatusCE" },
            ],
        };
    }
}
