import { Name } from "app/core/decorators";
import { QrmCellRoutingCapacity } from "app/core/models/entity-models/qrmCellRoutingCapacity";
import { QrmCell } from "app/core/models/entity-models/qrmCell";

/** QRM Cells */
@Name("QrmCellRoutingCapacity")
export class QrmCellRoutingCapacityFull extends QrmCellRoutingCapacity {
    name = "QrmCellRoutingCapacity";
    /** QRM Cell */
    public qcelQrmCell: QrmCell;
    /** Paired QRM Cell */
    public prdQrmCell: QrmCell;

    get fromToLabel(): string {
        return `${this.qcelQrmCell.qrmCellLB} - ${this.prdQrmCell.qrmCellLB}`;
    }
}
