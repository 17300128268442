import { Name } from "app/core/decorators";
import { SalesOrderLine } from "app/core/models/entity-models/salesOrderLine";
import { Product } from "app/core/models/entity-models/product";
import { SalesOrder } from "app/core/models/entity-models/salesOrder";
import { SalesOrderLineStatus } from "app/core/models/entity-models/salesOrderLineStatus";
import { QrmJobTicket } from "app/core/models/entity-models/qrmJobTicket";
import { UnitOfMeasure } from "app/core/models/entity-models/unitOfMeasure";

/** Sales Order Line*/
@Name("SalesOrderLine")
export class SalesOrderLineFull extends SalesOrderLine {
    /** Sales Order*/
    public salesOrder: SalesOrder;
    /** Product Code */
    public product: Product;
    /** Sales Order Line Quantity Unit */
    public unitOfMeasure: UnitOfMeasure;

    public qrmJobTicket: QrmJobTicket;
    public masterQrmJobTicket: QrmJobTicket;
    public solsSalesOrderLineStatus: SalesOrderLineStatus;
    masterQrmJobTicketNO: any;
}
