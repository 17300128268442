<mat-toolbar class="p-0 mat-elevation-z1">
    <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">
        <div fxFlex="40" fxLayout="row" fxLayoutAlign="start center">
            <button mat-icon-button
                    class="navbar-toggle-button"
                    *ngIf="
                    !hiddenNavbar &&
                    !rightNavbar &&
                    (navbarFolded || !navbarOpened)
                "
                    (click)="toggleSidebarFoldOpen('navbar')"
                    matTooltip="{{ 'NAV.EXPAND' | translate }}">
                <mat-icon class="secondary-text">menu</mat-icon>
            </button>
            <div class="toolbar-separator"
                 *ngIf="
                    !hiddenNavbar &&
                    !rightNavbar &&
                    (navbarFolded || !navbarOpened)
                "></div>
            <div fxLayout="row"
                 fxLayoutAlign="start center"
                 *ngIf="horizontalNavbar">
                <div class="logo ml-16">
                    <img class="logo-icon"
                         src="assets/logo/black-baseline-timer-24px.svg" />
                </div>
            </div>
            <div class="module-title">
                <span class="mat-tooltip-trigger material-icons-outlined">
                    {{
                    icon
                    }}
                </span>
                <h1>{{ title }}</h1>
            </div>
            <!-- <div class="px-8 px-md-16">
            <fuse-shortcuts [navigation]="navigation"></fuse-shortcuts>
        </div> -->
            <!--<div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">
            &nbsp;&nbsp;&nbsp;
            <mat-slide-toggle style="font-size:13px;" color="primary" [(ngModel)]="listStyle" (change)="changeStyle()">Change Layout</mat-slide-toggle>
        </div>-->
        </div>
        <div fxFlex="20" fxFill fxLayoutAlign="center center"  >
            <div fxHide
            fxShow.gt-sm>
                <img src="../../../../assets/logo/logoWFOIcon.svg" style="height:65px" />
            </div>
            <div fxHide
            fxShow.gt-sm>
                <div style="margin-left:10px;line-height:20px;font-size:large">3rd Wave<br />FlowOpt</div>
            </div>
        </div>

        <div class=""
             fxFlex="40"
             fxLayout="row"
             fxLayoutAlign="end center">
            <button mat-button
                    [matMenuTriggerFor]="userMenu"
                    class="user-button">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <img class="avatar mr-0 mr-sm-16"
                         src="assets/users/avatar.png" />
                    <span *ngIf="currentUser && currentUser.user"
                          class="username mr-12"
                          fxHide
                          fxShow.gt-sm>{{ currentUser.user.userName }}</span>
                    <mat-icon class="s-16" fxHide.xs>keyboard_arrow_down</mat-icon>
                </div>
            </button>
            <mat-menu #userMenu="matMenu" [overlapTrigger]="false">
                <button mat-menu-item class="" (click)="logout()">
                    <mat-icon>exit_to_app</mat-icon>
                    <span>{{ "LOGIN.LOGOUT" | translate }}</span>
                </button>
            </mat-menu>
            <div style="margin: 0 45px 0 0; display: flex">
                <mat-icon [color]="signalRConnectionStatus ? 'accent' : 'warn'">
                    {{ signalRConnectionStatus ? "power" : "power_off" }}
                </mat-icon>
            </div>
            <button mat-button
                    fxHide
                    fxShow.gt-xs
                    class="language-button"
                    [matMenuTriggerFor]="languageMenu"
                    (click)="onLanguageClick()">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <img *ngIf="getFlagLocation(selectedLanguage)"
                         class="flag"
                         [src]="getFlagLocation(selectedLanguage)" />
                    <span class="iso text-uppercase ml-4"
                          *ngIf="selectedLanguage">{{ selectedLanguage.toUpperCase() }}</span>
                </div>
            </button>

            <mat-menu #languageMenu="matMenu" [overlapTrigger]="false">
                <button mat-menu-item
                        *ngFor="let lang of languages"
                        (click)="setLanguage(lang)">
                    <span fxLayout="row" fxLayoutAlign="start center">
                        <img *ngIf="getFlagLocation(selectedLanguage)"
                             class="flag mr-16"
                             [src]="getFlagLocation(lang.languageCE)" />
                        <span class="iso">{{ lang.languageLB }}</span>
                    </span>
                </button>
            </mat-menu>
        </div>
    </div>
</mat-toolbar>
