import { Name } from "app/core/decorators";
import { QrmCellTempCapacDeviation } from "app/core/models/entity-models/qrmCellTempCapacDeviation";
import { QrmCell } from "app/core/models/entity-models/qrmCell";
import { CapacityCluster } from "app/core/models/entity-models/capacityCluster";

/** QRM Cells : Temporary Capacity Deviations */
@Name("QrmCellTempCapacDeviation")
export class QrmCellTempCapacDeviationFull extends QrmCellTempCapacDeviation {
    name = "QrmCellTempCapacDeviation";
    /** QRM Cell */
    public qrmCell: QrmCell;
    /** Capacity Cluster */
    public qrmCellCapacityCluster: CapacityCluster;
    /** Polca Card Capacity Cluster */
    public polcaCardeCapacityCluster: CapacityCluster;
}
