import { Name } from "app/core/decorators";
import { TransTracingBase } from "app/core/models/transTracingBase";

/** Project Buget Versions - Translations */
@Name("ProjectBudgetVersionTrans")
export class ProjectBudgetVersionTrans extends TransTracingBase {
    name = "ProjectBudgetVersionTrans";
    /** Project Number                 PK */
    public projectNO: string;
    /** Project Budget Version Number  PK */
    public projectBudgetVersionNO: string;
    /** Project Budget Version Label */
    public projectBudgetVersionLB: string;
    /** Project Budget Version Description */
    public projectBudgetVersionDS: string;
    /** Project Budget Version Info (Hyperlink) */
    public projectBudgetVersionUR: string;
    /** Project Budget Version Info (Hypertext) */
    public projectBudgetVersionHT: string;
}
