import { Name } from "app/core/decorators";
import { IStatusEntity } from "app/core/interfaces/IStatusEntity";
import { SalesOrderStatusTrans } from "./salesOrderStatusTrans";

/** SalesOrderStatus : Statuses */
@Name("SalesOrderStatus")
export class SalesOrderStatus
    extends SalesOrderStatusTrans
    implements IStatusEntity
{
    name = "SalesOrderStatus";
    baseEditRouteSegment = "ticket-status";
    fwStatusCE: string;
    revStatusCE: string;

    //** Status Sequence Number */
    public statusSQ: number;
    //** Is this Status to be used as Default Status? */
    public defaultStatusFL: string;
    //** Force the Default at Creation? */
    public forceDefaultAtCreationFL: string;
    //** SalesOrderStatus Type */
    public salesOrderStatusTY: string;

    get id(): string {
        return this.salesOrderStatusCE;
    }

    set id(value: string) {
        this.salesOrderStatusCE = value;
    }

    errorMessage: string;

    get statusCE() {
        return this.salesOrderStatusCE;
    }
    set statusCE(value) {
        this.salesOrderStatusCE = value;
    }
    public get statusDS(): string {
        return this.salesOrderStatusDS;
    }
    public set statusDS(value) {
        this.salesOrderStatusDS = value;
    }
    public get statusLB(): string {
        return this.salesOrderStatusLB;
    }

    public get displayWithChips(): string {
        return `${this.salesOrderStatusCE} - ${this.salesOrderStatusLB}`;
    }

    public listItemLayout(): object {
        return {
            label: {
                property: "salesOrderStatusLB",
            },
            inBrackets: {
                property: "tracingDT",
            },
            key: {
                property: "salesOrderStatusCE",
            },
            title: {
                property: "salesOrderStatusDS",
            },
            description: {
                property: "tracingDT",
            },
            leftBottomList: [],
            rightBottomList: [
                {
                    property: "salesOrderStatusTY",
                },
            ],
        };
    }
}
