import { TracingBase } from "app/core/models";
import { Name } from "app/core/decorators";
import { IBaseEntity } from "app/core/interfaces/IBaseEntity";

/** QRM Routing Capacity (Polca) Cards */
@Name("QrmRoutingCapacityCard")
export class QrmRoutingCapacityCard extends TracingBase implements IBaseEntity {
    name = "QrmRoutingCapacityCard";
    baseEditRouteSegment = "qrm-routing-capacity";
    get id(): string {
        return this.routingCapacityCardCE;
    }
    errorMessage: string;

    /** Routing Capacity (Polca) Card Code      PK */
    public routingCapacityCardCE: string;
    /** QRM Cell Code */
    public qrmCellCE: string;
    /** Paired QRM Cell Code */
    public pairedQrmCellCE: string;
    /** Assigned QRM Job Ticket Number */
    public qrmJobTicketNO: string;

    public listItemLayout(): object {
        return {
            label: { property: "tracingDT" },
            inBrackets: { property: "tracingDT" },
            key: { property: "routingCapacityCardCE" },
            title: { property: "pairedQrmCellCE" },
            description: { property: "tracingDT" },
            leftBottomList: [],
            rightBottomList: [],
        };
    }
}
