import { QrmJobTicketStatusTrans } from "app/core/models/entity-models/qrmJobTicketStatusTrans";
import { Name } from "app/core/decorators";
import { IStatusEntity } from "app/core/interfaces/IStatusEntity";

/** QRM Job Tickets : Statuses */
@Name("QrmJobTicketStatus")
export class QrmJobTicketStatus
    extends QrmJobTicketStatusTrans
    implements IStatusEntity
{
    name: string = "QrmJobTicketStatus";
    baseEditRouteSegment = "ticket-status";
    fwStatusCE: string;
    revStatusCE: string;

    //** Status Sequence Number */
    public statusSQ: number;
    //** Is this Status to be used as Default Status? */
    public defaultStatusFL: string;
    //** Force the Default at Creation? */
    public forceDefaultAtCreationFL: string;
    //** QRM Job Ticket Status Type */
    public qrmJobTicketStatusTY: string;

    get id(): string {
        return this.qrmJobTicketStatusCE;
    }

    set id(value: string) {
        this.qrmJobTicketStatusCE = value;
    }

    errorMessage: string;

    get statusCE() {
        return this.qrmJobTicketStatusCE;
    }
    set statusCE(value) {
        this.qrmJobTicketStatusCE = value;
    }
    public get statusDS(): string {
        return this.qrmJobTicketStatusDS;
    }
    public set statusDS(value) {
        this.qrmJobTicketStatusDS = value;
    }
    public get statusLB(): string {
        return this.qrmJobTicketStatusLB;
    }

    public get displayWithChips(): string {
        return `${this.qrmJobTicketStatusCE} - ${this.qrmJobTicketStatusLB}`;
    }

    public listItemLayout(): object {
        return {
            label: {
                property: "qrmJobTicketStatusLB",
            },
            inBrackets: {
                property: "tracingDT",
            },
            key: {
                property: "qrmJobTicketStatusCE",
            },
            title: {
                property: "qrmJobTicketStatusDS",
            },
            description: {
                property: "tracingDT",
            },
            leftBottomList: [],
            rightBottomList: [
                {
                    property: "qrmJobTicketStatusTY",
                },
            ],
        };
    }
}
