
interface Array<T> {
    sortBy(prop: string): Array<T>;
    sortByMultiple(props: Array<string>): Array<T>;
    distinct(): Array<T>;
    clear(): void;
    range(start: number, end: number): Array<T>;
    clone(): Array<T>;
}

/**
 * Sort objects of array by given property name
 * @param prop 
 * @returns {} 
 */
Array.prototype.sortBy = function (prop: string) {
    return this.sort((first, second) => {
        if (first[prop] < second[prop]) return -1;
        if (first[prop] > second[prop]) return 1;
        return 0;
    });
}

/**
 * Sort objects of array by given properties name
 * @param props
 * @returns {} 
 */
Array.prototype.sortByMultiple = function (props: Array<string>) {
    return _.sortBy(this, props);
}

/**
 * Unique values
 * @returns {} 
 */
Array.prototype.distinct = function () {
    return this.filter((value: any, index: number, self: any) => self.indexOf(value) === index);
}

/**
 * Empty the Array (not creating another instance)
 * @returns {} 
 */
Array.prototype.clear = function () {
    //this.slice(0, this.length);
    while (this.length) { this.pop(); };
}

Array.prototype.range = function(start: number, end: number) {
    return Array.from({ length: (end - start) }, (v, k) => k + start);
}

Array.prototype.clone = function() {
    const clonedArr = [];
    this.forEach(item => clonedArr.push(Object.assign({}, item)));

    return clonedArr;
}

if (!Array.prototype.includes) {
    Array.prototype.includes = function () {
        "use strict";
        return Array.prototype.indexOf.apply(this, arguments) !== -1;
    };
}