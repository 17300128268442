import { Injectable } from "@angular/core";
import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpEvent,
} from "@angular/common/http";
import { ErrorHandlerService, AppTranslationService } from "../";
import { Observable } from "rxjs";
import { AuthenticationService } from "../../authentication/authentication.service";
import { switchMap, timeout } from "rxjs/operators";
import { environment } from "../../../../environments/environment";

@Injectable({
    providedIn: "root",
})
export class AuditInterceptorService implements HttpInterceptor {
    private translationReloading: boolean;

    constructor(private readonly authService: AuthenticationService) {}

    /**
     * Intercept the HTTP call
     * Close the error message if there is one
     * Add Authorization token to HttpHeader
     * @param request
     * @param next
     */
    public intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        if (!request.headers.has("Content-Type")) {
            request = request.clone({
                headers: request.headers.set(
                    "Content-Type",
                    "application/json"
                ),
            });
        }

        request = request.clone({
            headers: request.headers.set("Accept", "application/json"),
        });

        if (localStorage.getItem("language")) {
            request = request.clone({
                headers: request.headers.set(
                    "UserContextLanguage",
                    localStorage.getItem("language")
                ),
            });
        }

        return this.authService.getToken().pipe(
            switchMap((accessToken: string) => {
                if (accessToken && accessToken.length > 0)
                    request = request.clone({
                        headers: request.headers.set(
                            "Authorization",
                            "Bearer " + accessToken
                        ),
                    });
                return next
                    .handle(request)
                    .pipe(timeout(environment.httpRequestTimeout));
            })
        );
    }
}
