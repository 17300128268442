import { TransTracingBase } from "app/core/models";
import { Name } from "app/core/decorators";

/** QRM Products : Bills of Material - Translations */
@Name("QrmProductBomTrans")
export class QrmProductBomTrans extends TransTracingBase {
    name = "QrmProductBomTrans";
    /** QRM Product Code                                                    PK */
    public qrmProductCE: string;
    /** QRM Product Bill of Material Sequence Number                        PK */
    public qrmProductBomSQ: number;
    /** QRM Product Bill of Material Label */
    public qrmProductBomLB: string;
    /** QRM Product Bill of Material Description */
    public qrmProductBomDS: string;
    /** QRM Product Bill of Material Info (Hyperlink) */
    public qrmProductBomUR: string;
    /** QRM Product Bill of Material Info (Hypertext) */
    public qrmProductBomHT: string;
    /** QRM Product Bill of Material Drawing (Hyperlink) */
    public qrmProductBomDrawingUR: string;
}
