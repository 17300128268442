import { Name } from "app/core/decorators";
import { ProjectBudgetVersionTrans } from "app/core/models/entity-models/projectBudgetVersionTrans";
import { IBaseEntity } from "app/core/interfaces/IBaseEntity";

/** Project Buget Versions */
@Name("ProjectBudgetVersion")
export class ProjectBudgetVersion
    extends ProjectBudgetVersionTrans
    implements IBaseEntity
{
    name = "ProjectBudgetVersion";
    baseEditRouteSegment = "project-budget-version";
    public get id(): string {
        if (!this.projectNO || !this.projectBudgetVersionNO) return null;
        return `${this.projectNO},${this.projectBudgetVersionNO}`;
    }
    public errorMessage: string;

    /** Project Budget Version Sequence */
    public projectBudgetVersionSQ: number;
    /** Project Budget Version Release Sequence */
    public projectBudgetReleaseSQ: number;
    /** Is this version active?*/
    public activeVersionFL: string;
    /** Project Budget Time Quantity */
    public budgetTimeQT: number;
    /** Project Budget Time Quantity Unit Code */
    public budgetTimeUnitCE: string;
    /** Project Budget AM */
    public budgetVersionAM: number;

    public get activeVersionFLBoolean(): boolean {
        if (this.activeVersionFL === "Y") return true;
        return false;
    }

    public set activeVersionFLBoolean(value: boolean) {
        if (value) this.activeVersionFL = "Y";
        else this.activeVersionFL = "N";
    }
    budgetTimeUnit: any;

    public listItemLayout(): object {
        return {
            label: { property: "projectBudgetVersionLB" },
            inBrackets: { property: "tracingDT" },
            key: { property: "projectNO" },
            title: { property: "projectBudgetVersionDS" },
            description: { property: "tracingDT" },
            leftBottomList: [],
            rightBottomList: [],
        };
    }
}
