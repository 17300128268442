import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class ToolbarService {
    public onStyleChanged: BehaviorSubject<boolean>;
    public titleChanged: BehaviorSubject<string>;
    public iconChanged: BehaviorSubject<string>;

    constructor() {
        this.onStyleChanged = new BehaviorSubject(false);
        this.titleChanged = new BehaviorSubject(null);
        this.iconChanged = new BehaviorSubject(null);
    }
}
