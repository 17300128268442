import { NgModule } from "@angular/core";

import { FuseNavigationModule } from "app/layout/components/navigation/navigation.module";
import { FuseSharedModule } from "app/core/@fuse/shared.module";

import { NavbarVerticalStyle2Component } from "app/layout/components/navbar/vertical/style-2/style-2.component";
import { TranslateModule } from "app/core/lib/translate/public_api";
import { LayoutDirectivesModule } from "app/layout/directives/directives";
import { MatLegacyButtonModule as MatButtonModule } from "@angular/material/legacy-button";
import { MatIconModule } from "@angular/material/icon";
import { MatLegacyTooltipModule as MatTooltipModule } from "@angular/material/legacy-tooltip";

@NgModule({
    declarations: [NavbarVerticalStyle2Component],
    imports: [
        MatButtonModule,
        MatIconModule,
        MatTooltipModule,
        MatButtonModule,
        TranslateModule,
        FuseSharedModule,
        FuseNavigationModule,
        LayoutDirectivesModule,
    ],
    exports: [NavbarVerticalStyle2Component],
})
export class NavbarVerticalStyle2Module {}
