import { TransTracingBase } from "app/core/models";
import { Name } from "app/core/decorators";

/** Capacity Clusters - Translations */
@Name("CapacityClusterTrans")
export class CapacityClusterTrans extends TransTracingBase {
    name = "CapacityClusterTrans";
    /** Capacity Cluster Code                                 PK */
    public capacityClusterCE: string;
    /** Capacity Cluster Label */
    public capacityClusterLB: string;
    /** Capacity Cluster Description */
    public capacityClusterDS: string;
    /** Capacity Cluster Info (Hyperlink) */
    public capacityClusterUR: string;
    /** Capacity Cluster Info (Hypertext) */
    public capacityClusterHT: string;
    /** Capacity Cluster Info (Preview of the Hypertext)      Virtual attribute */
    public capacityClusterPH: string;
}
