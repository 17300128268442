import { Unsubscriber } from ".";
import { Directive, Input } from "@angular/core";
import {
    IEntityDefinition,
    IEntityAttribute,
    IPossibleValueSetValue,
} from "app/core/models/base-entity/interfaces";
@Directive()
export abstract class BaseEntityInput extends Unsubscriber {
    @Input()
    public entityDefinition: IEntityDefinition;
    @Input()
    public entityAttributes: { [key: string]: IEntityAttribute };
    @Input()
    public attributePossibleValueSets: {
        [key: string]: IPossibleValueSetValue;
    };
    @Input()
    public transParams: { [key: string]: string };
    @Input()
    public baseEditRouteSegment: string;
    @Input()
    public toSlice = 192;
    isInRelatedTab = false;
}
