import { TracingBase } from "app/core/models";
import { Name } from "app/core/decorators";
import { IBaseEntity } from "app/core/interfaces/IBaseEntity";

/** Calendars : Non-Working Days */
@Name("CalendarNonWorkDay")
export class CalendarNonWorkDay extends TracingBase implements IBaseEntity {
    name = "CalendarNonWorkDay";
    get id(): string {
        if (!this.calendarCE || !this.nonWorkingDayDT) return null;
        return `${this.calendarCE},${this.nonWorkingDayDT}`;
    }
    errorMessage: string;
    /** Calendar Code               PK */
    public calendarCE: string;
    public calendarLB: string;
    /** Non-Working Day Date        PK */
    public nonWorkingDayDT: Date;
    workingDayDT: any;
}
