import { TracingBase } from "..";
import { IPlSqlPackageProcParameter } from "./interfaces";
import { Name } from "../../decorators";
import { PossibleValueSetValue } from ".";
import { Observable } from "rxjs";

@Name("DP3PlSqlPackageProcParameter")
export class PlSqlPackageProcParameter
    extends TracingBase
    implements IPlSqlPackageProcParameter
{
    name = "DP3PlSqlPackageProcParameter";
    rushOrderFL: string;
    autoScheduleFL: string;
    possibleValueSet: Observable<PossibleValueSetValue>;
    public projectCE: string;
    public packageCE: string;
    public procedureCE: string;
    public parameterCE: string;
    public parameterDS: string;
    public sequenceNO: number;
    public parameterTY: string;
    public parameterNM: string;
    public variableTY: string;
    public dataTypeCE: string;
    public lengthOrPrecision: number;
    public scale: number;
    public entityCE: string;
    public attributeCE: string;
    public recArrTypeDefinitionCE: string;
    public databaseTypeNM: string;
    public defaultVL: string;
    public dataDefinitionString: string;
    public parameterDeclarationString: string;
    public possibleValueSetCE: string;

    public get inputFieldType(): string {
        switch (this.dataType) {
            case "number":
                return "number";
            default:
                return "text";
        }
    }

    public get dataType(): string {
        switch (this.dataTypeCE) {
            case "DATE":
                return "Date";
            case "NUMBER":
            case "INTEGER":
                return "number";
            default:
                return "string";
        }
    }
}
