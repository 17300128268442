import { IBaseEntity, IBaseEntityStatic } from "../interfaces/IBaseEntity";

/ class decorator /;
function staticImplements<T>() {
    return <U extends T>(constructor: U) => {
        constructor;
    };
}

@staticImplements<IBaseEntityStatic>()
export class BaseModel {
    key: string;
    name: string;
    baseEditRouteSegment: string;
    private _isLogicallyDeleted: boolean;
    public get isLogicallyDeleted(): boolean {
        return this._isLogicallyDeleted;
    }
    public set isLogicallyDeleted(value: boolean) {
        this._isLogicallyDeleted = value;
    }
    _id: string;
    public get id(): string {
        return this._id;
    }
    public set id(value) {
        this._id = value;
    }

    public static mapToType<
        T extends { new (...args: any[]): any; prototype: any }
    >(type: T, entity: any): T {
        const obj = new type(entity);
        Object.assign(obj, entity);
        return obj;
    }

    public static changeType<
        T extends { new (...args: any[]): any; prototype: any }
    >(object: any, type: T): T {
        const obj = new type(object);
        Object.assign(obj, object);
        return obj;
    }

    public static listItemLayout() {
        return {};
    }
}
