import { Name } from "app/core/decorators";
import { UnitOfMeasure } from "app/core/models/entity-models/unitOfMeasure";
import { ProjectFull } from "app/core/models/entity-models/projectFull";
import { ProjectBudgetVersion } from "app/core/models/entity-models/projectBudgetVersion";

/** Project Buget Versions */
@Name("ProjectBudgetVersion")
export class ProjectBudgetVersionFull extends ProjectBudgetVersion {
    name = "ProjectBudgetVersion";

    /** Project  */
    public project: ProjectFull;
    /** Project Budget Time Quantity Unit */
    public unitOfMeasure: UnitOfMeasure;
}
