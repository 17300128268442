import { NgModule } from '@angular/core';
import { MinNumberDirective } from './directive/min-number-input.directive';
import { MaxNumberDirective } from './directive/max-number-input.directive';



@NgModule({
    imports: [
    ],
    declarations: [MinNumberDirective, MaxNumberDirective],
    exports: [MinNumberDirective, MaxNumberDirective]
})
export class InputMinMaxNumberModule { }