import { QrmJobTicket } from "app/core/models/entity-models/qrmJobTicket";
import { CapacityCluster } from "app/core/models/entity-models/capacityCluster";
import { QrmCell } from "app/core/models/entity-models/qrmCell";
import { UnitOfMeasure } from "app/core/models/entity-models/unitOfMeasure";
import { QrmProduct } from "app/core/models/entity-models/qrmProduct";
import { QrmJobTicketStatus } from "app/core/models/entity-models/qrmJobTicketStatus";
import { Name } from "app/core/decorators";

/** QRM Job Tickets Full */
@Name("QrmJobTicket")
export class QrmJobTicketFull extends QrmJobTicket {
    name = "QrmJobTicket";
    /** QRM Job Ticket Unit of Measure */
    public jtqUnitOfMeasure: UnitOfMeasure;
    /** QRM Job Ticket Status */
    public qrmJobTicketStatus: QrmJobTicketStatus;
    /** QRM Job Ticket Qrm Product */
    public qrmProduct: QrmProduct;
    /** QRM Job Ticket Expected Amount of Processing Time Unit */
    public xptUnitOfMeasure: UnitOfMeasure;
    /** QRM Job Ticket Qrm Cell */
    public qrmCell: QrmCell;
    /** QRM Job Ticket Capacity Cluster */
    public capacityCluster: CapacityCluster;
    /** QRM Cell of the Downstream QRM Job Ticket */
    public downstreamQrmCell: QrmCell;

    public mapRelatedToTypes() {
        if (this.jtqUnitOfMeasure) {
            this.jtqUnitOfMeasure = UnitOfMeasure.mapToType(
                UnitOfMeasure,
                this.jtqUnitOfMeasure
            ) as any;
        }
        if (this.xptUnitOfMeasure) {
            this.xptUnitOfMeasure = UnitOfMeasure.mapToType(
                UnitOfMeasure,
                this.xptUnitOfMeasure
            ) as any;
        }
        if (this.qrmJobTicketStatus) {
            this.qrmJobTicketStatus = QrmJobTicketStatus.mapToType(
                QrmJobTicketStatus,
                this.qrmJobTicketStatus
            ) as any;
        }
        if (this.qrmProduct) {
            this.qrmProduct = QrmProduct.mapToType(
                QrmProduct,
                this.qrmProduct
            ) as any;
        }
        if (this.qrmCell) {
            this.qrmCell = QrmCell.mapToType(QrmCell, this.qrmCell) as any;
        }
        if (this.downstreamQrmCell) {
            this.downstreamQrmCell = QrmCell.mapToType(
                QrmCell,
                this.downstreamQrmCell
            ) as any;
        }
        if (this.capacityCluster) {
            this.capacityCluster = CapacityCluster.mapToType(
                CapacityCluster,
                this.capacityCluster
            ) as any;
        }
    }
}
