import { VisualStyleTrans } from "app/core/models/entity-models/visualStyleTrans";
import { Name } from "app/core/decorators";
import { IBaseEntity } from "app/core/interfaces/IBaseEntity";

/** Visual Styles */
@Name("VisualStyle")
export class VisualStyle extends VisualStyleTrans implements IBaseEntity {
    name = "VisualStyle";
    baseEditRouteSegment = "visual-style";
    errorMessage: string;
    public get id(): string {
        return this.visualStyleCE;
    }

    /** Background Red Color Value */
    public backgroundRedColorVL: number;
    /** Background Green Color Value */
    public backgroundGreenColorVL: number;
    /** Background Blue Color Value */
    public backgroundBlueColorVL: number;
    /** Foreground Red Color Value */
    public foregroundRedColorVL: number;
    /** Foreground Green Color Value */
    public foregroundGreenColorVL: number;
    /** Foreground Blue Color Value */
    public foregroundBlueColorVL: number;
    /** Fill Pattern Type */
    public fillPatternTY: string;

    /** Background Color */
    public get backgroundColor(): string {
        return `rgb(${this.backgroundRedColorVL},${this.backgroundGreenColorVL},${this.backgroundBlueColorVL})`;
    }

    /** Foreground Color */
    public get foregroundColor(): string {
        return `rgb(${this.foregroundRedColorVL},${this.foregroundGreenColorVL},${this.foregroundBlueColorVL})`;
    }

    /** <visualStyleCE> <visualStyleLB> */
    public get caption(): string {
        return `${this.visualStyleCE} ${this.visualStyleLB}`;
    }

    public listItemLayout(): object {
        return {
            label: {
                property: "visualStyleLB",
            },
            inBrackets: {
                property: "tracingDT",
            },
            key: {
                property: "visualStyleCE",
            },
            title: {
                property: "visualStyleDS",
            },
            description: {
                property: "tracingDT",
            },
            leftBottomList: [],
            rightBottomList: [
                {
                    property: "fillPatternTY",
                },
                {
                    toSlice: 200,
                    property: "visualStyleUR",
                },
            ],
        };
    }
}
