import { TransTracingBase } from "app/core/models";
import { Name } from "app/core/decorators";

/** QRM Cells - Translations */
@Name("QrmCellTrans")
export class QrmCellTrans extends TransTracingBase {
    name = "QrmCellTrans";
    /** QRM Cell Code                                PK */
    public qrmCellCE: string;
    /** QRM Cell Label */
    public qrmCellLB: string;
    /** QRM Cell Description */
    public qrmCellDS: string;
    /** QRM Cell Info (Hyperlink) */
    public qrmCellUR: string;
    /** QRM Cell Info (Hypertext) */
    public qrmCellHT: string;
    /** QRM Cell Info (Preview of the Hypertext)     Virtual attribute */
    public qrmCellPH: string;
}
