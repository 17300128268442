import { TracingBase } from "app/core/models";
import { Name } from "app/core/decorators";
import { IBaseEntity } from "app/core/interfaces/IBaseEntity";

/** Calendars : Work Shifts */
@Name("CalendarWorkShift")
export class CalendarWorkShift extends TracingBase implements IBaseEntity {
    name = "CalendarWorkShift";
    get id(): string {
        if (!this.calendarCE || !this.workShiftCE) return null;
        return `${this.calendarCE},${this.workShiftCE}`;
    }
    errorMessage: string;

    /** Calendar Code          PK */
    public calendarCE: string;
    /** Work Shift Code        PK */
    public workShiftCE: string;
}
