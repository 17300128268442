import { Name } from "app/core/decorators";
import { TracingBase } from "..";
import { IEntityDefinition } from "./interfaces";

@Name("DP3Entity")
export class EntityDefinition extends TracingBase implements IEntityDefinition {
    name = "DP3Entity";
    public projectCE: string;
    public entityCE: string;
    public entityDS: string;
    public entityNM: string;
    public entityID: string;
    public entityHT: string;
    public entityTY: string;
    public activeFL: string;
    public entityPH: string;
    public logicalDeleteFL: string;
    public physicalDeleteFL: string;
    public transactionLogsFL: string;
    public entityPackageFL: string;
    public entityStatusFL1: string;
    public entityStatusFL2: string;
    public entityStatusFL3: string;
    public isTranslationEntityFL: string;
    public primaryKeyTY: string;
    public primaryKeyByUserFL: string;
    public pkDefaultValueAttributeCE: string;
    public pkDefaultValuePackageCE: string;
    public pkDefaultValueProcedureCE: string;
    public pkDefaultValueParameterVL: string;
    public pkDefaultValueFunctionCall: string;
    public translationEntityCE: string;
    public subtypeOfEntityCE: string;
    public statusEntityCE: string;
    public extraAttributesEntityCE: string;
    public basketEntityCE: string;
    public keyTranslationAttributeCE: string;
    public orderByClauseTX: string;
    public orderByClausePV: string;
    public orderByClauseLinePV: string;
    public virtualEntityPackageCE: string;
    public virtualEntityProcedureCE: string;
    public virtualEntityFunctionCall: string;
    public entityPackageNM: string;
    public entityObjectTypeNM: string;
    public entityTableTypeNM: string;
    public virtualEntityObjectTypeNM: string;
    public virtualEntityTableTypeNM: string;
    public primaryKeyConstraintCE: string;
    public viewSelectStatement: string;
    public viewSelectStatementPV: string;
    public entityCreationScript: string;
    public entityCreationScriptPV: string;

    public get allowLogicalDelete(): boolean {
        return this.logicalDeleteFL.localeCompare("Y") === 0;
    }
    public get allowPhysicalDelete(): boolean {
        return this.physicalDeleteFL.localeCompare("Y") === 0;
    }
}
