import { QrmProductTrans } from "app/core/models/entity-models/qrmProductTrans";
import { Name } from "app/core/decorators";
import { QrmProductFamily } from "app/core/models/entity-models/qrmProductFamily";
import { IBaseEntity } from "app/core/interfaces/IBaseEntity";
import { QrmProductBom } from "./qrmProductBom";
import { QrmProductFamilyFull } from ".";

/** QRM Products */
@Name("QrmProduct")
export class QrmProduct extends QrmProductTrans implements IBaseEntity {
    name = "QrmProduct";
    baseEditRouteSegment = "qrm-product";
    hideSelect: boolean;
    public get id(): string {
        return this.qrmProductCE;
    }
    public errorMessage: string;

    /** QRM Product Reference */
    public qrmProductRF: string;
    /** Procurement Type */
    public procurementTY: string;
    /** Is the Product to be Made or Purchased on Demand? */
    public onDemandFL: string;
    /** QRM Product Family Code */
    public qrmProductFamilyCE: string;
    /** Stocking Unit Code */
    public stockingUnitCE: string;
    /** Lead Time */
    public leadTimeQT: number;
    /** Lead Time Unit Code */
    public leadTimeUnitCE: string;
    /** QRM Job Ticket Number for As-Is MCT-Map */
    public asIsQrmJobTicketNO: string;
    /** QRM Job Ticket Number for To-Be MCT-Map */
    public toBeQrmJobTicketNO: string;
    /** Conversion Factor for Packing Unit */
    public packingConversionFC: number;
    /** Unit of Packing Code */
    public packingConversionUnitCE: string;
    /** Conversion Factor for Length Unit */
    public lengthConversionFC: number;
    /** Unit of Length Code */
    public lengthConversionUnitCE: string;
    /** Conversion Factor for Area Unit */
    public areaConversionFC: number;
    /** Unit of Area Code */
    public areaConversionUnitCE: string;
    /** Conversion Factor for Volume Unit */
    public volumeConversionFC: number;
    /** Unit of Volume Code */
    public volumeConversionUnitCE: string;
    /** Conversion Factor for Weight Unit */
    public weightConversionFC: number;
    /** Unit of Weight Code */
    public weightConversionUnitCE: string;

    /** qrmProductCE qrmProductLB */
    public get caption(): string {
        return `${this.qrmProductLB} ${this.qrmProductDS}`;
    }

    public get onDemandFLBoolean(): boolean {
        if (this.onDemandFL === "Y") return true;
        return false;
    }

    public set onDemandFLBoolean(value: boolean) {
        if (value) this.onDemandFL = "Y";
        else this.onDemandFL = "N";
    }

    public children: Array<QrmProduct>;

    public get displayWithChips() {
        return `${this.qrmProductCE} - ${this.qrmProductLB}`;
    }

    public static getTree(
        products: Array<QrmProduct>,
        groups: Array<QrmProductFamily>
    ): Array<QrmProduct> {
        const tree = new Array<QrmProduct>();

        groups.forEach((_) => {
            const productsFamily = new QrmProduct();
            productsFamily.qrmProductCE = _.qrmProductFamilyCE;
            productsFamily.qrmProductLB = _.qrmProductFamilyLB;
            productsFamily.children = products.filter(
                (p) => p.qrmProductFamilyCE === _.qrmProductFamilyCE
            );
            if (productsFamily.children.length > 0) tree.push(productsFamily);
        });

        return tree;
    }
    public static getBoomTree(
        products: Array<QrmProduct>,
        groups: Array<QrmProductFamily>,
        booms: Array<QrmProductBom>
    ): Array<any> {
        const tree = new Array<QrmProduct>();
        let map = {};

        groups.forEach((_) => {
            _.hideSelect = true;
            map[_.qrmProductFamilyCE] = _;
        });
        var productFamilies = QrmProductFamilyFull.getTree(groups);
        products.forEach((product) => {
            product.children = [];
            product.hideSelect = true;
            map[product.qrmProductCE] = product;
            map[product.qrmProductFamilyCE].children.push(product);
        });
        booms.forEach((bom) => {
            map[bom.qrmProductCE].children.push(bom);
        });

        return productFamilies;
    }

    stkUnitOfMeasure: any;
    ltmUnitOfMeasure: any;

    public listItemLayout(): object {
        return {
            label: { property: "qrmProductRF" },
            inBrackets: { property: "tracingDT" },
            key: { property: "qrmProductCE" },
            title: { property: "qrmProductDS" },
            description: { property: "tracingDT" },
            leftBottomList: [],
            rightBottomList: [
                { property: "procurementTY" },
                { toSlice: 200, property: "leadTimeQT" },
                { toSlice: 200, property: "stockingUnitCE" },
            ],
        };
    }
}
