import { Injectable } from "@angular/core";
import { Language } from "app/core/models/entity-models";
import { BehaviorSubject, map, Observable, takeUntil } from "rxjs";
import { EntityService, UtilService } from "app/core/services";
import { environment } from "environments/environment";
import { registerLocaleData } from "@angular/common";
import { TranslateService } from "app/core/lib/translate/public_api";
import { HttpClient, HttpParams } from "@angular/common/http";
import { IKeyValue } from "app/core/models/interfaces";
import {
    PossibleValueSetValue,
    ProjectText,
} from "app/core/models/base-entity";
const englishUS = "en-US";
const englishUK = "en-UK";
const english = "en";

@Injectable({
    providedIn: "root",
})
export class AppTranslationService {
    private _languages: Array<Language>;
    public isLoadingLanguages: BehaviorSubject<boolean>;
    public loadLanguagesAndTranslations: BehaviorSubject<boolean>;
    public currentLanguage: BehaviorSubject<string>;
    public currentLanguageCode: BehaviorSubject<string>;
    public translationChanged: BehaviorSubject<{
        lang: string;
        translation: any;
    }>;
    public format12H: BehaviorSubject<boolean>;

    public get languages(): Array<Language> {
        return this._languages;
    }

    public set languages(value: Array<Language>) {
        this._languages = value;
        this.initLanguages();
    }

    private entityDefinitions: any[] = [];
    private possibleValueSetsValues: any[] = [];

    public attributePrompt(entityId: string, attributeID: string): string {
        var attrdef = this.CheckIsLoaded(entityId, attributeID);
        return attrdef.attributeMediumPromptTX;
    }

    public possibleValue(
        entityId: string,
        attributeID: string,
        attributeValue: string
    ): string {
        var attrDef = this.CheckIsLoaded(entityId, attributeID);
        if (attrDef.possibleValueSetCE) {
            return this.CheckPVSIsLoaded(
                attrDef.possibleValueSetCE,
                attributeValue
            );
        }
        return attributeValue;
        //    var entDef = this.CheckIsLoaded(entityId, attributeID);
        //    let tmp = entDef.attributes.find((x) => x.attributeID == attributeID);
        //    return tmp.attributeMediumPromptTX;
    }
    private CheckPVSIsLoaded(possibleValueSetCE, possibleValueSetValue) {
        var pvsv = this.possibleValueSetsValues.find(
            (x) =>
                x.possibleValueSetCE == possibleValueSetCE &&
                x.possibleVL == possibleValueSetValue
        );
        if (!pvsv) {
            pvsv = {
                possibleValueSetCE: possibleValueSetCE,
                possibleVL: possibleValueSetValue,
                valueLB: possibleValueSetValue,
            };
            this.possibleValueSetsValues.push(pvsv);
            const params = new Array<IKeyValue>();
            params.push({
                key: "listOfPossibleValueSetCE",
                value: possibleValueSetCE,
            });
            this.entityService
                .query(PossibleValueSetValue, { params: params })
                .subscribe((pvsvs: any[]) => {
                    pvsvs.forEach((x) => {
                        let itemIndex = this.possibleValueSetsValues.findIndex(
                            (y) =>
                                y.possibleValueSetCE == x.possibleValueSetCE &&
                                y.possibleVL == x.possibleVL
                        );
                        if (itemIndex == -1)
                            this.possibleValueSetsValues.push(x);
                        else this.possibleValueSetsValues[itemIndex] = x;
                    });
                });
        }
        return pvsv.valueLB;
    }
    private CheckIsLoaded(entityId: string, attributeID: string): any {
        var entDef = this.entityDefinitions.find((x) => x.entityId == entityId);
        var attrdef;
        if (!entDef) {
            attrdef = {
                attributeID: attributeID,
                attributeShortPromptTX: attributeID,
                attributeMediumPromptTX: attributeID,
                attributeLongPromptTX: attributeID,
            };
            entDef = { entityId: entityId, attributes: [attrdef] };
            this.entityDefinitions.push(entDef);
            this.entityService.definitionForEntity(entityId).subscribe((ed) => {
                let itemIndex = this.entityDefinitions.findIndex(
                    (x) => x.entityId == entityId
                );
                this.entityDefinitions[itemIndex] = ed;
            });
            //this.entityDefinitions[entityid].replace("l");
        } else
            attrdef = entDef.attributes.find(
                (x) => x.attributeID == attributeID
            );
        if (!attrdef) {
            attrdef = {
                attributeID: attributeID,
                attributeShortPromptTX: attributeID,
                attributeMediumPromptTX: attributeID,
                attributeLongPromptTX: attributeID,
            };
            entDef.attributes.push(attrdef);
        }
        return attrdef;
    }

    public get translateService(): TranslateService {
        return this._translateService;
    }

    private _currentLanguage: string;

    constructor(
        private readonly _translateService: TranslateService,
        private readonly utilService: UtilService,
        private readonly httpClient: HttpClient,
        private readonly entityService: EntityService
    ) {
        if (!this.translateService.defaultLang) {
            this.translateService.defaultLang = environment.defaultLanguage;
        }
        if (!this.translateService.currentLang) {
            this.translateService.currentLang =
                this.translateService.defaultLang;
        }

        this.format12H = new BehaviorSubject<boolean>(undefined);
        this.currentLanguage = new BehaviorSubject<string>(undefined);
        this.currentLanguageCode = new BehaviorSubject<string>(undefined);
        this.translationChanged = new BehaviorSubject<{
            lang: string;
            translation: any;
        }>(undefined);
        this.isLoadingLanguages = new BehaviorSubject<boolean>(false);
        this.loadLanguagesAndTranslations = new BehaviorSubject<boolean>(false);
        this.loadLanguages().subscribe();
    }

    public ngOnDestroy(): void {}

    /**
     * Load translations into translateService for the given languages.
     * If languages is null, then it loads languages.
     * @param languages
     */
    public initLanguages() {
        if (!this.languages) {
            return;
        }

        const currentLanguage = localStorage.getItem("language");

        if (this.translateService.defaultLang !== environment.defaultLanguage) {
            this.translateService.setDefaultLang(environment.defaultLanguage);
        }

        this.languages.forEach((language) => {
            if (!this.translateService.langs.includes(language.languageCE)) {
                this.translateService.addLangs([language.languageCE]);
            }
        });

        const browserLanguage = this.translateService.getBrowserCultureLang();
        const useLanguage = currentLanguage
            ? currentLanguage
            : browserLanguage
            ? browserLanguage.slice(0, 2)
            : this.translateService.defaultLang;
        this.format12H.next(
            useLanguage === english &&
                browserLanguage &&
                browserLanguage === englishUS
        );

        this.setCurrentLanguage(
            this.languages.find(
                (language) => language.languageCE === useLanguage
            ).languageCE
        );
    }

    /**
     * Set translation for the current language
     * @param translation
     */
    public setTranslation(translation: any) {
        this.translateService.setTranslation(
            this.translateService.currentLang,
            translation
        );
        this.translationChanged.next({
            lang: this.translateService.currentLang,
            translation: translation,
        });
    }

    /**
     * Set language as current language.
     * @param language
     */
    public setCurrentLanguage(languageCE: string) {
        if (!languageCE) {
            return;
        }
        const params = new HttpParams().append("languageCE", languageCE);
        sessionStorage.clear();
        this.httpClient
            .post(
                `${
                    environment.apiBaseUrl
                }/CslPlatform3/SetUserLanguage?${params.toString()}`,
                null
            )
            .pipe(
                map((_) => {
                    this.loadDataForUserLanguage(languageCE);
                    return _;
                })
            )
            .subscribe();
    }

    private loadDataForUserLanguage(languageCE: string) {
        if (this.translateService.currentLang !== languageCE) {
            this.translateService.use(languageCE);
        }

        this.format12H.next(
            languageCE === english &&
                this.translateService.getBrowserCultureLang() === englishUS
        );
        try {
            localStorage.setItem("language", languageCE);
        } catch (e) {
            alert("Quota exceeded!");
        }

        if (!this.translationExists(languageCE)) {
            this.loadTranslations();
        } else if (languageCE) {
            this.translationChanged.next({
                lang: languageCE,
                translation: this.translateService.translations[languageCE],
            });
        }
        this._currentLanguage = languageCE;
        this.currentLanguage.next(languageCE);
        if (
            this.currentLanguageCode &&
            this.currentLanguageCode.value !== languageCE
        ) {
            this.currentLanguageCode.next(languageCE);
        }
    }

    private loadLanguages(): Observable<any> {
        return this.entityService.queryV2(Language).pipe(
            map((languages: Array<Language>) => {
                if (!languages || languages.length === 0) {
                    languages.push(environment.defaultLanguageInst as Language);
                }
                this.languages = languages;
                this.loadLanguagesAndTranslations.next(false);
                return languages;
            })
        );
    }

    private loadTranslations() {
        this.entityService
            .queryProjectText()
            .subscribe((translations: Array<ProjectText>) => {
                const currentTrans = translations.reduce(
                    (currentTrans, trans) => {
                        if (!currentTrans[trans.ownerCE]) {
                            currentTrans[trans.ownerCE] = {};
                        }

                        currentTrans[trans.ownerCE][trans.textCE] =
                            trans.textTX;

                        return currentTrans;
                    },
                    {}
                );

                this.setTranslation(currentTrans);
            });
    }
    /**
     * Gets the flag location for the language.
     * @param language
     */
    public getLanguageFlag(languageCE: string): string {
        if (!languageCE) return "";
        else
            return this.utilService.getPngImage(
                `flags/${
                    languageCE
                        ? (languageCE === english
                              ? environment.enFlag
                              : languageCE
                          ).toUpperCase()
                        : ""
                }`
            );
    }

    /**
     * Gets ISO code of the current language.
     * */
    public getCurrentLanguageCode(): string {
        return this._currentLanguage ? this._currentLanguage : undefined;
    }

    /**
     * Gets the currently used language.
     * */
    public getCurrentLanguage(): string {
        return this.translateService.currentLang;
    }

    /**
     * Gets the currently used language with dialect.
     * */
    public getCurrentLanguageWithDialect(): string {
        switch (this.translateService.currentLang) {
            case english: {
                const browserLanguage =
                    this.translateService.getBrowserCultureLang();
                switch (browserLanguage) {
                    case english:
                        return englishUK;
                    default:
                        return browserLanguage;
                }
            }
            default: {
                return this.translateService.currentLang;
            }
        }
    }

    /**
     * Builds format of date and time.
     * @param setTime
     */
    public getDateTimeFormat(
        setTime: boolean,
        long?: boolean
    ): {
        year: string;
        month: string;
        day: string;
        hour: string;
        minute: string;
    } {
        const format = {
            weekday: long ? "long" : undefined,
            year: "numeric",
            month: long ? "long" : "short",
            day: "numeric",
            hour: setTime ? "2-digit" : undefined,
            minute: setTime ? "2-digit" : undefined,
        };
        return format;
    }

    /**
     * Check if there are translations for a language
     * @param languageCE
     */
    public translationExists(languageCE: string): boolean {
        return (
            this.translateService.translations[languageCE] &&
            Object.getOwnPropertyNames(
                this.translateService.translations[languageCE]
            ).length > 0
        );
    }
}
