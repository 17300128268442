import { NgModule } from "@angular/core";
import { MatLegacyButtonModule as MatButtonModule } from "@angular/material/legacy-button";
import { MatIconModule } from "@angular/material/icon";
import { MatToolbarModule } from "@angular/material/toolbar";
import { RouterModule } from "@angular/router";
import { FuseSharedModule } from "app/core/@fuse/shared.module";
import { FooterComponent } from "app/layout/components/footer/footer.component";

@NgModule({
    declarations: [FooterComponent],
    imports: [
        RouterModule,
        MatButtonModule,
        MatIconModule,
        MatToolbarModule,
        FuseSharedModule,
    ],
    exports: [FooterComponent],
})
export class FooterModule {}
