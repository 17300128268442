import { Injectable } from "@angular/core";

@Injectable({ providedIn: "root" })
export class CamelPascalMapper {
    /**
     * Maps an object to a certain type that is using Camel notation.
     * @param rawData
     * @param type
     */
    public toCamelInstance<
        T extends { new (...args: any[]): any; prototype: any }
    >(rawData: any, type: T): T | Array<T> {
        let data: T;
        let rawValue: any;
        const scope = this;

        if (!rawData) {
            return rawData;
        }

        if (rawData instanceof Array) {
            return rawData.map((v) => {
                if (typeof v === "object") {
                    v = scope.toCamelInstance(v, type) as T;
                }
                return v;
            });
        } else {
            if (type.name === "String" || type.name === "Number") {
                return rawData;
            }

            data = new type(rawData);
            const properties = Object.getOwnPropertyNames(rawData);
            if (!properties) {
                return undefined;
            }

            properties.forEach((_) => {
                if (
                    !rawData.hasOwnProperty(_) &&
                    !rawData.hasOwnProperty(_.toInitUpperCase())
                ) {
                    return;
                }

                // 0 || undefined returns undefined instead of 0 so we have to check that case seperately
                if (rawData[_] === 0) rawValue = rawData[_];
                else if (rawData[_] === false) rawValue = rawData[_];
                // '' || undefined returns undefined instead of an empty string - this matters when comparing entities in the crud update
                else if (rawData[_] === "") rawValue = rawData[_];
                // null || undefined returns undefined instead of null  - i don't know if this matters anywhere but i added it anyway
                else if (rawData[_] === null) rawValue = rawData[_];
                else rawValue = rawData[_] || rawData[_.toInitUpperCase()];
                if (
                    rawValue instanceof Array ||
                    (rawValue !== null &&
                        rawValue !== undefined &&
                        rawValue.constructor === Object)
                ) {
                    data[_] = this.toCamelInstance(rawValue, Object);
                } else {
                    data[_] = rawValue;
                }
            });
        }
        return data;
    }

    /**
     * Maps an instance of a certain type that is using Camel notation to an Object using Pascal notation.
     * @param camelData
     */
    public toPascalInstance<
        T extends { new (...args: any[]): any; prototype: any }
    >(camelData: T | Array<T>): any | Array<any> {
        if (!camelData) return camelData;

        if (camelData instanceof Array) {
            return camelData.map((_) => this.toPascalInstance(_));
        } else {
            if (this.isPascal(camelData)) {
                return camelData;
            }
            const data = new Object();
            const properties = Object.getOwnPropertyNames(camelData);
            if (!properties) {
                return undefined;
            }

            properties.forEach((_) => {
                if (
                    camelData[_] instanceof Array ||
                    (camelData[_] !== null &&
                        camelData[_] !== undefined &&
                        camelData[_].constructor === Object)
                ) {
                    data[_.toInitUpperCase()] = this.toPascalInstance(
                        camelData[_]
                    );
                } else {
                    data[_.toInitUpperCase()] = camelData[_];
                }
            });
            return data;
        }
    }

    /**
     * Maps the 3rdWave API response to Camel notation object.
     * @param rawData
     * @param type
     */
    public map3rdWaveResponse<
        T extends { new (...args: any[]): any; prototype: any }
    >(rawData: any, type: T): any {
        if (!rawData) return null;
        //#region If Camel notation
        if (rawData.hasOwnProperty("theList") && rawData["theList"]) {
            return this.toCamelInstance<T>(rawData["theList"], type);
        }
        if (rawData.hasOwnProperty("theRecord") && rawData["theRecord"]) {
            return this.toCamelInstance<T>(rawData["theRecord"], type);
        }
        //#endregion
        //#region If Pascal notation
        if (rawData.hasOwnProperty("TheList") && rawData["TheList"]) {
            return this.toCamelInstance<T>(rawData["TheList"], type);
        }
        if (rawData.hasOwnProperty("TheRecord") && rawData["TheRecord"]) {
            return this.toCamelInstance<T>(rawData["TheRecord"], type);
        }
        //#endregion
        return this.toCamelInstance<T>(rawData, type);
    }

    /**
     * Is target using pascal notation?
     * @param target
     */
    private isPascal(target: any): boolean {
        if (!target) {
            return undefined;
        }
        const isPascalNotation =
            Object.getOwnPropertyNames(target).find(
                (_) => _ !== _.toInitUpperCase()
            ) === undefined;
        return isPascalNotation;
    }

    /**
     * Is target using camel notation?
     * @param target
     */
    private isCamel(target: any): boolean {
        if (!target) {
            return undefined;
        }
        const isCamelNotation =
            Object.getOwnPropertyNames(target).find(
                (_) => _ !== _.toInitLowerCase()
            ) === undefined;
        return isCamelNotation;
    }
}
