import { UserRole } from "./enums";

export class CurrentUser {
    userID: string;
    success: boolean;
    user: User;
    errorMessage: string;
    rememberMe: boolean;
    authorized: boolean;
}

export class User {
    userId: string;
    userName: string;
    userRoles: UserRoles[];
    userParameters: userParameters[];
    functionPermissions: Permission[];
    projectOptions: string[];
    securityToken: string;
    tokenEndOfLife: Date;
    userContext: UserContext;
    authorized: boolean;
    emailVerified: any;
    navigation: any;
    languageCE: any;
    environmentLB: string = "FlowoptLB";
    environmentCE: string = "FlowoptCE";
}

export class userParameters {
    id: string;
    value: string;
}

export class UserRoles {
    key: string;
    value: string;
}

export class Permission {
    functionID: string;
    functionName: string;
    view: boolean;
    update: boolean;
    create: boolean;
    delete: boolean;
    action: boolean;
}

export class UserContext {
    user: string;
    project: string;
    applicationProject: string;
    application: string;
    language: string;
    authorizationLevel: string;
    organization: string;
    environment: string;
    lfCharacter: string;
    listConcatinationString: string;
}

export const permissions: Permission[] = [
    {
        functionID: "ORG",
        functionName: "Organization",
        view: true,
        update: true,
        create: true,
        delete: true,
        action: true,
    },
    {
        functionID: "LANG",
        functionName: "Language",
        view: true,
        update: true,
        create: true,
        delete: true,
        action: true,
    },
    {
        functionID: "SYSPR",
        functionName: "System Prefix",
        view: true,
        update: true,
        create: true,
        delete: true,
        action: true,
    },
    {
        functionID: "FTMS",
        functionName: "Focused Target Market Segment",
        view: true,
        update: true,
        create: true,
        delete: true,
        action: true,
    },
    {
        functionID: "VSTL",
        functionName: "Visual Style",
        view: true,
        update: true,
        create: true,
        delete: true,
        action: true,
    },
    {
        functionID: "JBTS",
        functionName: "Job Ticket Status",
        view: true,
        update: true,
        create: true,
        delete: true,
        action: true,
    },
    {
        functionID: "POLCA",
        functionName: "Digital Polca",
        view: true,
        update: true,
        create: true,
        delete: true,
        action: true,
    },
];

export function setPermissions(role: string): Permission[] {
    switch (role) {
        case "ADMIN": {
            permissions.forEach((p) => {
                p.view = true;
            });
            return permissions;
        }
        case "PLANNING": {
            permissions.find((_) => _.functionID === "ORG").view = false;
            permissions.find((_) => _.functionID === "LANG").view = false;
            permissions.find((_) => _.functionID === "SYSPR").view = false;

            return permissions;
        }
        case "PRODUCTION": {
            permissions.find((_) => _.functionID === "ORG").view = false;
            permissions.find((_) => _.functionID === "LANG").view = false;
            permissions.find((_) => _.functionID === "SYSPR").view = false;
            permissions.find((_) => _.functionID === "FTMS").view = false;
            permissions.find((_) => _.functionID === "VSTL").view = false;

            return permissions;
        }
        case "BASIC": {
            permissions.find((_) => _.functionID === "ORG").view = false;
            permissions.find((_) => _.functionID === "LANG").view = false;
            permissions.find((_) => _.functionID === "SYSPR").view = false;
            permissions.find((_) => _.functionID === "FTMS").view = false;
            permissions.find((_) => _.functionID === "VSTL").view = false;

            return permissions;
        }
        case "POLCA": {
            permissions.find((_) => _.functionID === "POLCA").view = true;

            return permissions;
        }
        default: {
            //statements;
            break;
        }
    }
}
