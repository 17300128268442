import { TracingBase } from "app/core/models";
import { Name } from "app/core/decorators";
import { IBaseEntity } from "app/core/interfaces/IBaseEntity";

/** Units of Measure : Conversions */
@Name("UnitOfMeasureConversion")
export class UnitOfMeasureConversion
    extends TracingBase
    implements IBaseEntity
{
    name = "UnitOfMeasureConversion";
    baseEditRouteSegment = "unit-of-measure-conversion";
    get id(): string {
        if (!this.primaryUnitCE || !this.secondaryUnitCE) return null;
        return `${this.primaryUnitCE}-${this.secondaryUnitCE}`;
    }
    errorMessage: string;
    /** Primary Unit Code                                  PK */
    public primaryUnitCE: string;
    /** Secondary Unit Code                                PK */
    public secondaryUnitCE: string;
    /** Are Primary and Secondary Unit Alternatives? */
    public alternativeUnitFL: string;
    /** Unit Conversion Factor */
    public unitConversionFC: number;
    /** Inverted Unit Conversion Factor                    Virtual attribute */
    public invertedUnitConversionFC: number;

    get alternativeUnitFLBool(): boolean {
        return this.alternativeUnitFL === "Y" ? true : false;
    }

    set alternativeUnitFLBool(value: boolean) {
        this.alternativeUnitFL = value ? "Y" : "N";
    }

    public listItemLayout(): object {
        return {
            label: {
                property: "tracingDT",
            },
            inBrackets: {
                property: "tracingDT",
            },
            key: {
                property: "primaryUnitCE",
            },
            title: {
                property: "primaryUnitCE",
            },
            description: {
                property: "tracingDT",
            },
            leftBottomList: [],
            rightBottomList: [
                {
                    property: "alternativeUnitFL",
                },
                {
                    toSlice: 200,
                    property: "unitConversionFC",
                },
            ],
        };
    }
}
