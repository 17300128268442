import { TransTracingBase } from "app/core/models";
import { Name } from "app/core/decorators";

/** Project Report Type - Translations */
@Name("ProjectReportTypeTrans")
export class ProjectReportTypeTrans extends TransTracingBase {
    name = "ProjectReportTypeTrans";
    /** Project Number                 PK */
    public projectReportTypeCE: string;
    /** Project Report Type Label */
    public projectReportTypeLB: string;
    /** Project Report Type  Description */
    public projectReportTypeDS: string;
    /** Project Report Type Info (Hyperlink) */
    public projectReportTypeUR: string;
    /** Project Report Type Info (Hypertext) */
    public projectReportTypeHT: string;
}
