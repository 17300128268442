import { TransTracingBase } from "app/core/models";
import { Name } from "app/core/decorators";

/** Project Parts - Translations */
@Name("ProjectPartTrans")
export class ProjectPartTrans extends TransTracingBase {
    name = "ProjectPartTrans";
    /** Project Number                 PK */
    public projectNO: string;
    /** Project Part Code              PK */
    public projectPartCE: string;
    /** Project Label */
    public projectPartLB: string;
    /** Project Description */
    public projectPartDS: string;
    /** Project Info (Hyperlink) */
    public projectPartUR: string;
    /** Project Info (Hypertext) */
    public projectPartHT: string;
}
