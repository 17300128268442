import { TransTracingBase } from "app/core/models";
import { Name } from "app/core/decorators";

/** Work Shifts - Translations */
@Name("WorkShiftTrans")
export class WorkShiftTrans extends TransTracingBase {
    name = "WorkShiftTrans";
    /** Work Shift Code                                 PK */
    public workShiftCE: string;
    /** Work Shift Label */
    public workShiftLB: string;
    /** Work Shift Description */
    public workShiftDS: string;
    /** Work Shift Info (Hyperlink) */
    public workShiftUR: string;
    /** Work Shift Info (Hypertext) */
    public workShiftHT: string;
    /** Work Shift Info (Preview of the Hypertext)      Virtual attribute */
    public workShiftPH: string;
}
