import { Name } from "app/core/decorators";
import { IBaseEntity } from "app/core/interfaces/IBaseEntity";
import { ProjectReportTypeTrans } from "app/core/models/entity-models/projectReportTypeTrans";

/** Project Report Types */
@Name("ProjectReportType")
export class ProjectReportType
    extends ProjectReportTypeTrans
    implements IBaseEntity
{
    name = "ProjectReportType";
    public get id(): string {
        return this.projectReportTypeCE;
    }
    public errorMessage;
}
