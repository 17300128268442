import { Name } from "app/core/decorators";
import { BaseModel } from "app/core/models";

/** QRM Capacity Monitor */
@Name("QrmCapacityMonitor")
export class QrmCapacityMonitor extends BaseModel {
    name = "QrmCapacityMonitor";
    /** Capacity Monitor Line Sequence Number */
    public capacityMonitorLineSQ: number;
    /** Capacity Monitor Line Level Type */
    public capacityMonitorLineLevelTY: string;
    /** Focused Target Market Segment Code */
    public ftmsCE: string;
    /** QRM Cell Code */
    public qrmCellCE: string;
    /** Cell Capacity Cluster Code */
    public cellCapacityClusterCE: string;
    /** Qrm Cell Order Sequence Number */
    public qrmCellOrderSQ: number;
    /** Capacity KPI Type */
    public capacityKpiTY: string;
    /** QRM Product Family Code */
    public qrmProductFamilyCE: string;
    /** QRM Product Code */
    public qrmProductCE: string;
    /** QRM Job Ticket Number */
    public qrmJobTicketNO: string;
    /** QRM Job Ticket Number */
    public plannedQrmJobTicketNO: string;
    /** Master QRM Job Ticket Number */
    public masterQrmJobTicketNO: string;
    /** Authorization Date */
    public authorizationDT: Date;
    /** Actual Start Date */
    public actualStartDT: Date;
    /** Actual Completion Date */
    public actualCompletionDT: Date;
    /** QRM Job Ticket Calculated Start Date and Time */
    public calculatedStartDT: Date;
    /** QRM Job Ticket Calculated End Date and Time */
    public calculatedEndDT: Date;
    /** Overdue Capacity Quantity */
    public overdueCapacityQT: number;
    /** Period 01 KPI Value Quantity */
    public period01KpiValueQT: number;
    /** Period 02 KPI Value Quantity */
    public period02KpiValueQT: number;
    /** Period 03 KPI Value Quantity */
    public period03KpiValueQT: number;
    /** Period 04 KPI Value Quantity */
    public period04KpiValueQT: number;
    /** Period 05 KPI Value Quantity */
    public period05KpiValueQT: number;
    /** Period 06 KPI Value Quantity */
    public period06KpiValueQT: number;
    /** Period 07 KPI Value Quantity */
    public period07KpiValueQT: number;
    /** Period 08 KPI Value Quantity */
    public period08KpiValueQT: number;
    /** Period 09 KPI Value Quantity */
    public period09KpiValueQT: number;
    /** Period 10 KPI Value Quantity */
    public period10KpiValueQT: number;
    /** Period 11 KPI Value Quantity */
    public period11KpiValueQT: number;
    /** Period 12 KPI Value Quantity */
    public period12KpiValueQT: number;
    /** Period 13 KPI Value Quantity */
    public period13KpiValueQT: number;
    /** Period 14 KPI Value Quantity */
    public period14KpiValueQT: number;
    /** Period 15 KPI Value Quantity */
    public period15KpiValueQT: number;
    /** Period 16 KPI Value Quantity */
    public period16KpiValueQT: number;
    /** Period 17 KPI Value Quantity */
    public period17KpiValueQT: number;
    /** Period 18 KPI Value Quantity */
    public period18KpiValueQT: number;

    constructor() {
        super();

        this.period01KpiValueQT = null;
        this.period02KpiValueQT = null;
        this.period03KpiValueQT = null;
        this.period04KpiValueQT = null;
        this.period05KpiValueQT = null;
        this.period06KpiValueQT = null;
        this.period07KpiValueQT = null;
        this.period08KpiValueQT = null;
        this.period09KpiValueQT = null;
        this.period10KpiValueQT = null;
        this.period11KpiValueQT = null;
        this.period12KpiValueQT = null;
        this.period13KpiValueQT = null;
        this.period14KpiValueQT = null;
        this.period15KpiValueQT = null;
        this.period16KpiValueQT = null;
        this.period17KpiValueQT = null;
        this.period18KpiValueQT = null;
    }
}
