import { Name } from "app/core/decorators";
import { IStatusEntity } from "app/core/interfaces/IStatusEntity";
import { SalesOrderLineStatusTrans } from "./salesOrderLineStatusTrans";

/** SalesOrderLineStatus : Statuses */
@Name("SalesOrderLineStatus")
export class SalesOrderLineStatus
    extends SalesOrderLineStatusTrans
    implements IStatusEntity
{
    name = "SalesOrderLineStatus";
    baseEditRouteSegment = "ticket-status";
    fwStatusCE: string;
    revStatusCE: string;

    //** Status Sequence Number */
    public statusSQ: number;
    //** Is this Status to be used as Default Status? */
    public defaultStatusFL: string;
    //** Force the Default at Creation? */
    public forceDefaultAtCreationFL: string;
    //** SalesOrderLine Status Type */
    public salesOrderLineStatusTY: string;

    get id(): string {
        return this.salesOrderLineStatusCE;
    }

    set id(value: string) {
        this.salesOrderLineStatusCE = value;
    }

    errorMessage: string;

    get statusCE() {
        return this.salesOrderLineStatusCE;
    }
    set statusCE(value) {
        this.salesOrderLineStatusCE = value;
    }
    public get statusDS(): string {
        return this.salesOrderLineStatusDS;
    }
    public set statusDS(value) {
        this.salesOrderLineStatusDS = value;
    }
    public get statusLB(): string {
        return this.salesOrderLineStatusLB;
    }

    public get displayWithChips(): string {
        return `${this.salesOrderLineStatusCE} - ${this.salesOrderLineStatusLB}`;
    }

    public listItemLayout(): object {
        return {
            label: {
                property: "salesOrderLineStatusLB",
            },
            inBrackets: {
                property: "tracingDT",
            },
            key: {
                property: "salesOrderLineStatusCE",
            },
            title: {
                property: "salesOrderLineStatusDS",
            },
            description: {
                property: "tracingDT",
            },
            leftBottomList: [],
            rightBottomList: [
                {
                    property: "salesOrderLineStatusTY",
                },
            ],
        };
    }
}
