import { CalendarWorkDay } from "app/core/models/entity-models/calendarWorkDay";
import { Calendar } from "app/core/models/entity-models/calendar";
import { Name } from "app/core/decorators";

/** Calendars : Working Days Full */
@Name("CalendarWorkday")
export class CalendarWorkDayFull extends CalendarWorkDay {
    name = "CalendarWorkday";
    /** Calendar */
    public calendar: Calendar;
}
