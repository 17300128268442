import { IMetadataService } from "app/core/interfaces/iMetadataService";
import { BehaviorSubject, Observable, forkJoin, of } from "rxjs";
import {
    IEntityDefinition,
    IEntityAttribute,
    IProject,
    IProjectText,
    IPossibleValueSetValue,
    IPlSqlPackageProcParameter,
} from "app/core/models/base-entity/interfaces";
import { DataService } from "app/core/services/data-service/data.service";
import {
    Project,
    ProjectText,
    EntityDefinition,
    EntityAttribute,
    PossibleValueSetValue,
    PlSqlPackageProcParameter,
} from "app/core/models/base-entity";
import { ICodeKeyValue, IKeyValue } from "app/core/models/interfaces";
import { getName } from "app/core/decorators";
import { switchMap, map, finalize } from "rxjs/operators";
import { Injectable } from "@angular/core";

import { environment } from "environments/environment";
@Injectable()
export class EntityService extends DataService implements IMetadataService {
    public asyncEntityDefinition: BehaviorSubject<IEntityDefinition>;
    public asyncEntityAttributes: BehaviorSubject<Array<IEntityAttribute>>;
    public asyncAttributePossibleValueSets: BehaviorSubject<
        Array<IPossibleValueSetValue>
    >;
    public asyncMetadataReady: BehaviorSubject<boolean>;

    public projectLanguageAttribute: IEntityAttribute;
    public projectLanguageTransEntityKeyAttribute: IEntityAttribute;

    public languageOfMetadata: string;
    public isLoadingMetadata: boolean;
    metaData: any;
    entityAttributesDic: any = {};

    constructor() {
        super();

        this.asyncEntityDefinition = new BehaviorSubject<IEntityDefinition>(
            undefined
        );
        this.asyncEntityAttributes = new BehaviorSubject<
            Array<IEntityAttribute>
        >(undefined);
        this.asyncAttributePossibleValueSets = new BehaviorSubject<
            Array<IPossibleValueSetValue>
        >(undefined);
        this.asyncMetadataReady = new BehaviorSubject<boolean>(false);
    }
    entityAttributes: IEntityAttribute[];
    entityDefinition: IEntityDefinition;
    getEntityMetaData<T extends { new (...args: any[]): any; prototype: any }>(
        type: T,
        listOfAttributeVirtualAttributes?: string[],
        ignoreTracingAndCreation?: boolean
    ): Observable<{
        entityDefinition;
        getEntityAttributes: Observable<{ [key: string]: IEntityAttribute }>;
        getProcedureParameters;
    }> {
        return this.getOneEntity(type).pipe(
            map((entityDefinition) => {
                this.metaData = {
                    entityDefinition: entityDefinition,
                    getEntityAttributes: this.entityAttributes
                        ? of(this.entityAttributes)
                        : this.queryEntityAttribute(
                              entityDefinition.entityCE,
                              entityDefinition.translationEntityCE,
                              listOfAttributeVirtualAttributes,
                              ignoreTracingAndCreation
                          ).pipe(
                              map((entityAttributes) => {
                                  entityAttributes.forEach((ea) => {
                                      const params = new Array<IKeyValue>();

                                      params.push({
                                          key: "listOfPossibleValueSetCE",
                                          value: ea.possibleValueSetCE,
                                      });

                                      ea.getPossibleValueSetValues =
                                          ea.possibleValueSetValues
                                              ? of(ea.possibleValueSetValues)
                                              : this.query(
                                                    PossibleValueSetValue,
                                                    {
                                                        params: params,
                                                        listOfVirtualAttributes:
                                                            listOfAttributeVirtualAttributes,
                                                    }
                                                ).pipe(
                                                    map(
                                                        (
                                                            possibleValueSetValues: PossibleValueSetValue[]
                                                        ) => {
                                                            ea.possibleValueSetValues =
                                                                possibleValueSetValues.reduce(
                                                                    (
                                                                        posibleValueSetValues,
                                                                        posibleValueSetValue
                                                                    ) => {
                                                                        posibleValueSetValues[
                                                                            posibleValueSetValue.possibleVL
                                                                        ] = posibleValueSetValue;
                                                                        return posibleValueSetValues;
                                                                    },
                                                                    {}
                                                                );
                                                            return ea.possibleValueSetValues;
                                                        }
                                                    )
                                                );
                                  });
                                  this.entityAttributesDic[type] =
                                      entityAttributes.reduce(
                                          (entityAttributes, attribute) => {
                                              attribute.attributePromptTX =
                                                  attribute[
                                                      `attribute${environment.attributePrompt}PromptTX`
                                                  ];
                                              entityAttributes[
                                                  attribute.attributeID.toInitLowerCase()
                                              ] = attribute;
                                              return entityAttributes;
                                          },
                                          {}
                                      );
                                  return this.entityAttributesDic[type];
                              })
                          ),
                    getProcedureParameters: this.queryPlSqlPackageProcParameter(
                        entityDefinition.virtualEntityPackageCE,
                        entityDefinition.virtualEntityProcedureCE,
                        listOfAttributeVirtualAttributes
                    ).pipe(
                        map((procedureParameters) => {
                            procedureParameters.forEach((pp) => {
                                const params = new Array<IKeyValue>();

                                params.push({
                                    key: "listOfPossibleValueSetCE",
                                    value: pp.possibleValueSetCE,
                                });

                                pp.possibleValueSet = this.query(
                                    PossibleValueSetValue,
                                    {
                                        params: params,
                                        listOfVirtualAttributes:
                                            listOfAttributeVirtualAttributes,
                                    }
                                ).pipe(
                                    map((possibleValueSetValues) => {
                                        return possibleValueSetValues[0];
                                    })
                                );
                            });

                            return procedureParameters.reduce(
                                (parameters, parameter) => {
                                    parameters[
                                        parameter.parameterNM.toInitLowerCase()
                                    ] = parameter;
                                    return parameters;
                                },
                                {}
                            );
                        })
                    ),
                };
                this.metaDataReady.next(this.metaData);
                return this.metaData;
            })
        );
    }
    public getProject(
        listOfVirtualAttributes?: Array<string>
    ): Observable<Project> {
        return this.getOne(Project, {
            keys: [],
            listOfVirtualAttributes: listOfVirtualAttributes,
        });
    }

    public queryProjectText(
        textTY: string = "LABEL",
        ownerCE?: string
    ): Observable<Array<IProjectText>> {
        const params: Array<IKeyValue> = [{ key: "textTY", value: textTY }];
        if (ownerCE) {
            params.push({ key: "ownerCE", value: ownerCE });
        }

        return this.query(ProjectText, { params: params });
    }

    public queryMetadata<
        T extends { new (...args: any[]): any; prototype: any }
    >(
        type: T,
        requery?: boolean,
        language?: string,
        listOfEntityVirtualAttributes?: Array<string>,
        listOfAttributeVirtualAttributes?: Array<string>,
        listOfAttributePossibleValueVirtualAttributes?: Array<string>,
        ignoreTracingAndCreation?: boolean,
        setResultInOwnProperties: boolean = true
    ): Observable<{
        entityDefinition: IEntityDefinition;
        entityAttributes: Array<IEntityAttribute>;
        attributePossibleValueSets: Array<IPossibleValueSetValue>;
    }> {
        this.isLoadingMetadata = true;
        this.asyncMetadataReady.next(false);

        if (
            !requery &&
            this.asyncEntityDefinition.value &&
            this.asyncEntityAttributes.value &&
            this.asyncAttributePossibleValueSets.value
        ) {
            this.languageOfMetadata = language || this.languageOfMetadata;
            this.isLoadingMetadata = false;
            this.asyncMetadataReady.next(true);
            return of({
                entityDefinition: this.asyncEntityDefinition.value,
                entityAttributes: this.asyncEntityAttributes.value,
                attributePossibleValueSets:
                    this.asyncAttributePossibleValueSets.value,
            });
        } else {
            return this.getOneEntity(type, listOfEntityVirtualAttributes).pipe(
                finalize(() => (this.isLoadingMetadata = false)),
                switchMap((entityDefinition) => {
                    if (setResultInOwnProperties) {
                        this.asyncEntityDefinition.next(entityDefinition);
                    }

                    return this.queryEntityAttribute(
                        entityDefinition.entityCE,
                        entityDefinition.translationEntityCE,
                        listOfAttributeVirtualAttributes,
                        ignoreTracingAndCreation
                    ).pipe(
                        switchMap((entityAttributes) => {
                            if (setResultInOwnProperties) {
                                this.asyncEntityAttributes.next(
                                    entityAttributes
                                );
                            }

                            return this.queryPossibleValueSetValue(
                                undefined,
                                entityAttributes,
                                listOfAttributePossibleValueVirtualAttributes
                            ).pipe(
                                map((possibleValueSetValue) => {
                                    if (setResultInOwnProperties) {
                                        this.asyncAttributePossibleValueSets.next(
                                            possibleValueSetValue
                                        );
                                    }

                                    this.languageOfMetadata =
                                        language || this.languageOfMetadata;
                                    this.asyncMetadataReady.next(true);

                                    return {
                                        entityDefinition: entityDefinition,
                                        entityAttributes: entityAttributes,
                                        attributePossibleValueSets:
                                            possibleValueSetValue,
                                    };
                                })
                            );
                        })
                    );
                })
            );
        }
    }

    public clearMetadata() {
        this.asyncMetadataReady.next(false);

        if (this.asyncEntityDefinition.value) {
            this.asyncEntityDefinition.next(undefined);
        }
        if (this.asyncEntityAttributes.value) {
            this.asyncEntityAttributes.next(undefined);
        }
        if (this.asyncAttributePossibleValueSets.value) {
            this.asyncAttributePossibleValueSets.next(undefined);
        }
    }

    public getOneEntity<
        T extends { new (...args: any[]): any; prototype: any }
    >(
        type: T,
        listOfVirtualAttributes: Array<string> = ["TranslationEntityCE"]
    ): Observable<IEntityDefinition> {
        const entityID = getName(type);
        const keys: Array<IKeyValue> = [{ key: "entityID", value: entityID }];

        return this.getOne(EntityDefinition, {
            keys: keys,
            listOfVirtualAttributes: listOfVirtualAttributes,
        });
    }

    public queryAttribute<
        T extends { new (...args: any[]): any; prototype: any }
    >(
        type: T,
        listOfVirtualAttributes?: Array<string>,
        ignoreTracingAndCreation?: boolean
    ): Observable<Array<IEntityAttribute>> {
        return this.getOneEntity(type).pipe(
            switchMap((entityDefinition) => {
                return this.queryEntityAttribute(
                    entityDefinition.entityCE,
                    entityDefinition.translationEntityCE,
                    listOfVirtualAttributes,
                    ignoreTracingAndCreation
                );
            })
        );
    }

    public queryAttributePossibleValueSet<
        T extends { new (...args: any[]): any; prototype: any }
    >(
        type: T,
        listOfAttributeID?: Array<string>,
        listOfAttributeCE?: Array<string>,
        listOfPossibleValueSetCE?: Array<string>,
        listOfVirtualAttributes?: Array<string>
    ): Observable<Array<IPossibleValueSetValue>> {
        return this.getOneEntity(type).pipe(
            switchMap((entityDefinition) => {
                if (
                    listOfPossibleValueSetCE &&
                    !listOfAttributeCE &&
                    !listOfAttributeID
                ) {
                    return this.queryPossibleValueSetValue(
                        listOfPossibleValueSetCE,
                        undefined,
                        listOfVirtualAttributes
                    );
                } else {
                    return this.queryEntityAttribute(
                        entityDefinition.entityCE,
                        entityDefinition.translationEntityCE
                    ).pipe(
                        switchMap((entityAttributes) => {
                            const listOfAttributes =
                                new Array<IEntityAttribute>();
                            if (listOfAttributeCE) {
                                listOfAttributes.push(
                                    ...entityAttributes.filter((attr) =>
                                        listOfAttributeCE.includes(
                                            attr.attributeCE
                                        )
                                    )
                                );
                            } else if (listOfAttributeID) {
                                listOfAttributes.push(
                                    ...entityAttributes.filter((attr) =>
                                        listOfAttributeID.includes(
                                            attr.attributeID
                                        )
                                    )
                                );
                            } else {
                                listOfAttributes.push(...entityAttributes);
                            }

                            return this.queryPossibleValueSetValue(
                                undefined,
                                listOfAttributes,
                                listOfVirtualAttributes
                            );
                        })
                    );
                }
            })
        );
    }

    public queryProcedureParametersMetadata(
        packageCE: string,
        procedureCE: string,
        listOfParameterVirtualAttributes?: Array<string>,
        listOfValueSetVirtualAttributes?: Array<string>
    ): Observable<{
        procedureParameters: Array<IPlSqlPackageProcParameter>;
        parameterPossibleValueSets: Array<IPossibleValueSetValue>;
    }> {
        return this.queryPlSqlPackageProcParameter(
            packageCE,
            procedureCE,
            listOfParameterVirtualAttributes
        ).pipe(
            switchMap((procedureParameters) => {
                return this.queryPossibleValueSetValue(
                    procedureParameters.map(
                        (param) => param.possibleValueSetCE
                    ),
                    undefined,
                    listOfValueSetVirtualAttributes
                ).pipe(
                    map((valueSets) => {
                        return {
                            procedureParameters: procedureParameters,
                            parameterPossibleValueSets: valueSets,
                        };
                    })
                );
            })
        );
    }

    public queryPlSqlPackageProcParameter(
        packageCE: string,
        procedureCE: string,
        listOfVirtualAttributes?: Array<string>
    ): Observable<Array<IPlSqlPackageProcParameter>> {
        const keys: Array<IKeyValue> = [
            { key: "packageCE", value: packageCE },
            { key: "procedureCE", value: procedureCE },
        ];

        return this.query(PlSqlPackageProcParameter, {
            params: keys,
            listOfVirtualAttributes: listOfVirtualAttributes,
        });
    }

    public queryParameterPossibleValueSet(
        packageCE?: string,
        procedureCE?: string,
        listOfParameterCE?: Array<string>,
        listOfPossibleValueSetCE?: Array<string>,
        listOfVirtualAttributes?: Array<string>
    ): Observable<Array<IPossibleValueSetValue>> {
        if (
            listOfPossibleValueSetCE &&
            !packageCE &&
            !procedureCE &&
            !listOfParameterCE
        ) {
            return this.queryPossibleValueSetValue(
                listOfPossibleValueSetCE,
                undefined,
                listOfVirtualAttributes
            );
        } else {
            return this.queryPlSqlPackageProcParameter(
                packageCE,
                procedureCE,
                listOfVirtualAttributes
            ).pipe(
                switchMap((procedureParameters) => {
                    const listOfParameters =
                        new Array<IPlSqlPackageProcParameter>();

                    if (listOfParameterCE) {
                        listOfParameters.push(
                            ...procedureParameters.filter((param) =>
                                listOfParameterCE.includes(param.parameterCE)
                            )
                        );
                    } else {
                        listOfParameters.push(
                            ...procedureParameters.filter(
                                (param) => param.possibleValueSetCE
                            )
                        );
                    }

                    return this.queryPossibleValueSetValue(
                        listOfParameters.map(
                            (param) => param.possibleValueSetCE
                        ),
                        undefined,
                        listOfVirtualAttributes
                    );
                })
            );
        }
    }

    public queryEntityAttribute(
        entityCE: string,
        translationEntityCE?: string,
        listOfVirtualAttributes = [
            "PrimaryKeyAttributeFL,AttributeDefinitionString",
        ],
        ignoreTracingAndCreation: boolean = true
    ): Observable<Array<IEntityAttribute>> {
        const params: Array<IKeyValue> = [{ key: "entityCE", value: entityCE }];
        if (translationEntityCE) {
            params.push({
                key: "TranslationEntityCE",
                value: translationEntityCE,
            });
        }

        const projectLanguageAttributeCall = this.getProject().pipe(
            switchMap((project) => {
                const langParams: Array<IKeyValue> = [
                    { key: "entityCE", value: project.languageEntityCE },
                ];

                return this.query(EntityAttribute, {
                    params: langParams,
                    listOfVirtualAttributes: ["PrimaryKeyAttributeFL"],
                });
            })
        );

        const attributeCall = this.query(EntityAttribute, {
            params: params,
            listOfVirtualAttributes: listOfVirtualAttributes,
        });

        const attributeCalls = [attributeCall];
        if (!this.projectLanguageAttribute) {
            attributeCalls.push(projectLanguageAttributeCall);
        }

        return forkJoin(attributeCalls).pipe(
            switchMap((result) => {
                const entityAttributes = result[0] as Array<IEntityAttribute>;

                if (result && result.length === 2) {
                    const languageAttributes =
                        result[1] as Array<IEntityAttribute>;
                    this.projectLanguageAttribute = languageAttributes.find(
                        (attr) => attr.primaryKeyAttributeFL === "Y"
                    );
                }

                if (
                    entityCE === this.projectLanguageAttribute.entityCE &&
                    translationEntityCE
                ) {
                    this.projectLanguageTransEntityKeyAttribute =
                        entityAttributes.find(
                            (attr) =>
                                attr.primaryKeyAttributeFL === "Y" &&
                                attr.entityCE === translationEntityCE &&
                                attr.entityCE !==
                                    this.projectLanguageAttribute.entityCE &&
                                attr.attributeCE !==
                                    this.projectLanguageAttribute.attributeCE
                        );
                }

                const attributes = entityAttributes.filter(
                    (attr) =>
                        (!ignoreTracingAndCreation ||
                            (ignoreTracingAndCreation &&
                                !attr.attributeTY.startsWith("TRACING") &&
                                !attr.attributeTY.startsWith("CREATION"))) &&
                        (attr.primaryKeyAttributeFL !== "Y" ||
                            (attr.primaryKeyAttributeFL === "Y" &&
                                attr.entityCE !== translationEntityCE)) &&
                        (attr.attributeCE !==
                            this.projectLanguageAttribute.attributeCE ||
                            (attr.attributeCE ===
                                this.projectLanguageAttribute.attributeCE &&
                                attr.entityCE !== translationEntityCE))
                );

                return of(attributes);
            })
        );
    }

    private queryPossibleValueSetValue(
        listOfPossibleValueSetCE?: Array<string>,
        listOfAttributes?: Array<IEntityAttribute>,
        listOfVirtualAttributes?: Array<string>
    ): Observable<Array<IPossibleValueSetValue>> {
        const params = new Array<IKeyValue>();

        if (listOfAttributes) {
            listOfPossibleValueSetCE =
                listOfPossibleValueSetCE || new Array<string>();

            const valuesAttributes = listOfAttributes.filter(
                (attr) => attr.attributeTY === "VALUES"
            );
            if (valuesAttributes) {
                listOfPossibleValueSetCE.push(
                    ...valuesAttributes.map((attr) => attr.possibleValueSetCE)
                );
            }
        }

        if (listOfPossibleValueSetCE && listOfPossibleValueSetCE.length > 0) {
            params.push({
                key: "listOfPossibleValueSetCE",
                value: listOfPossibleValueSetCE.distinct().join(","),
            });
        }

        return this.query(PossibleValueSetValue, {
            params: params,
            listOfVirtualAttributes: listOfVirtualAttributes,
        });
    }

    public setSolStatus(statusRequest): Observable<any> {
        const codeArray: ICodeKeyValue[] = [
            { key: "salesOrderNO", value: statusRequest.entity.salesOrderNO },
            {
                key: "salesOrderLineSQ",
                value: statusRequest.entity.salesOrderLineSQ,
            },
            { key: "newSalesOrderLineStatusCE", value: statusRequest.statusCE },
            {
                key: "autoInBetweenStatusFL",
                value: statusRequest.autoInBetweenStatusFL ? "Y" : "Y",
            },
        ];

        return this.executeProcedure(String, "SetSalesLineStatus", {
            keys: codeArray,
        });
    }

    public setJTStatus(statusRequest): Observable<any> {
        const codeArray: ICodeKeyValue[] = [
            {
                key: "qrmJobTicketNO",
                value: statusRequest.entity.qrmJobTicketNO,
            },
            { key: "newQrmJobTicketStatusCE", value: statusRequest.statusCE },
            {
                key: "includeUpstreamFL",
                value: statusRequest.includeUpstreamStatusFL ? "Y" : "N",
            },
        ];

        return this.executeProcedure(String, "SetQrmJobTicketStatus", {
            keys: codeArray,
        });
    }
}
