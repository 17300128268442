<div class="nav"
     [ngClass]="{'horizontal':layout === 'horizontal', 'vertical':layout === 'vertical'}">

    <!-- Vertical Navigation Layout -->
    <ng-container *ngIf="layout === 'vertical'">

        <ng-container *ngFor="let item of navigation">

            <nav-vertical-group *ngIf="item.type === 'group'"
                                [item]="item"></nav-vertical-group>
            <nav-vertical-collapsable *ngIf="item.type === 'collapsable'"
                                      [item]="item"></nav-vertical-collapsable>
            <nav-vertical-item *ngIf="item.type === 'item'"
                               [item]="item"></nav-vertical-item>


        </ng-container>

    </ng-container>
    <!-- / Vertical Navigation Layout -->
    <!-- Horizontal Navigation Layout -->
    <ng-container *ngIf="layout === 'horizontal'">

        <ng-container *ngFor="let item of navigation">

            <nav-horizontal-collapsable *ngIf="item.type === 'group'"
                                        [item]="item"></nav-horizontal-collapsable>
            <nav-horizontal-collapsable *ngIf="item.type === 'collapsable'"
                                        [item]="item"></nav-horizontal-collapsable>
            <nav-horizontal-item *ngIf="item.type === 'item'"
                                 [item]="item"></nav-horizontal-item>
        </ng-container>

    </ng-container>
    <!-- / Horizontal Navigation Layout -->

</div>