import { Name } from "app/core/decorators";
import { ProductTypeTrans } from "app/core/models/entity-models/productTypeTrans";
import { IBaseEntity } from "app/core/interfaces/IBaseEntity";

/** Products */
@Name("ProductType")
export class ProductType extends ProductTypeTrans implements IBaseEntity {
    name = "ProductType";
    public get id(): string {
        return this.productTypeCE;
    }

    public errorMessage: string;

    /** Product Type */
    public productTypeTY: string;
}
