import { QrmJobTicketActivity } from "app/core/models/entity-models/qrmJobTicketActivity";
import { Name } from "app/core/decorators";
import { QrmJobTicket } from "app/core/models/entity-models/qrmJobTicket";
import { UnitOfMeasure } from "app/core/models/entity-models/unitOfMeasure";

/** QRM Job Tickets : Activities Full */
@Name("QrmJobTicketActivity")
export class QrmJobTicketActivityFull extends QrmJobTicketActivity {
    name = "QrmJobTicketActivity";
    /** QRM Job Ticket */
    public qrmJobTicket: QrmJobTicket;
    /** Unit of Measure */
    public dtqUnitOfMeasure: UnitOfMeasure;
    /** Flow Rate Stock Unit */
    public frsUnitOfMeasure: UnitOfMeasure;
    /** Unit of Measure */
    public frtUnitOfMeasure: UnitOfMeasure;
    /** Stock Quantity Unit */
    public stqUnitOfMeasure: UnitOfMeasure;
}
