
interface String {
    toInitLowerCase(): string;
    toInitUpperCase(): string;
    toHTML(): string;
    toJSONLike(): string;
    lastJSONSubstring(): string;
    highlightTerm(term: string): string;
    representsNumberType(): boolean;
    htmlToRtf(): string;
}

/**
 * Build new string with case type of the first char to lower
 * @returns {} 
 */
String.prototype.toInitLowerCase = function () {
    return `${this.slice(0, 1).toLowerCase()}${this.slice(1)}`;
}

/**
 * Build new string with case type of the first char to upper
 * @returns {} 
 */
String.prototype.toInitUpperCase = function () {
    return `${this.slice(0, 1).toUpperCase()}${this.slice(1)}`;
}

/**
 * Add HTML <mark> tag for the searchTerm in input string.
 * Builds new string
 * @param term Term to be highlighted
 * @returns {} 
 */
String.prototype.highlightTerm = function (term: string) {
    if ((term && term.length <= 0)) return this;

    if (this.includes(` target='_blank'>`)) {
        const linkText = this.slice(this.indexOf(" target='_blank'>") + 17, this.indexOf("</a>"));
        const markedLinkText = linkText.replace(new RegExp(`(${term})`, "gi"), (match: string) => {
            return `<mark>${match}</mark>`;
        });
        return `${this.slice(0, this.indexOf(" target='_blank'>") + 17)}${markedLinkText}${this.slice(this.indexOf("</a>"))}`;
    }
    return this.replace(new RegExp(`(${term})`, "gi"), match => {
        return `<mark>${match}</mark>`;
    });
}

/**
 * Adds HTML markup to the input string (<br>, <a>)
 * @returns {} 
 */
String.prototype.toHTML = function () {
    let newLineText = this.replace(/(?:\r\n|\r|\n)/g, " <br>");
    let htmlText = "";
    //hez to match urls
    //const urlMatch = new RegExp("([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[a-z]{2,4})(:[0-9]+)?([^ ])+");
    const urlMatch = new RegExp("(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$])", "igm");
    const emailMatch = new RegExp("([a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?)", "g");

    //If the string matches the expression,
    //it will return an Array containing the entire matched string as the first element,
    //followed by any results captured in parentheses.If there were no matches, null is returned.
    const urlList = newLineText.match(urlMatch);
    const emailList = newLineText.includes("@") ? newLineText.match(emailMatch) : null;

    let list = emailList || urlList;

    while (list) {

        const url = list[0];
        const emailMatchResult = url.includes("@") ? url.match(emailMatch) : null;
        const isEmail = emailMatchResult !== null && emailMatchResult !== undefined;

        //we format the link 
        let clickUrl = url;
        if (!clickUrl.startsWith("www.")) {
            clickUrl = `http://www.${clickUrl}`;
        }
        if (!clickUrl.startsWith("http://")) {
            clickUrl = `http://${clickUrl}`;
        }

        //fix for content issue when '.'is present after the link without space between
        if (clickUrl.endsWith("."))
            clickUrl = clickUrl.slice(0, -1);

        //create the link
        const htmlLink = `<a href=${isEmail ? "mailto:" : ""}${clickUrl} target='_blank'>${url}</a>`;

        const urlIndex = newLineText.indexOf(url);
        htmlText += newLineText.substr(0, urlIndex) + htmlLink;

        newLineText = newLineText.substring(urlIndex + url.length);

        list = newLineText.match(emailMatch) || newLineText.match(urlMatch);
    }

    htmlText += newLineText;

    return htmlText;
}

/**
 * Returns the last JSON string from the input. Returned string is not formatted.
 * @returns {} 
 */
String.prototype.lastJSONSubstring = function () {
    const startIdx1 = this.indexOf("[");
    const endIdx1 = this.lastIndexOf("]");
    const startIdx2 = this.indexOf("{");
    const endIdx2 = this.lastIndexOf("}");
    const startIdx = Math.min(...[startIdx1, startIdx2].filter(_ => _ !== -1));
    const endIdx = startIdx1 < startIdx2 ? endIdx1 : endIdx2;
    if (startIdx < 0 || endIdx < 0) return undefined;

    const jsonString = this.substring(startIdx, endIdx + 1);
    return jsonString;
}

/**
 * Returns true if the string represents the type or nullable type for a number (int, int?, decimal, decimal?). 
 */
String.prototype.representsNumberType = function (): boolean {
    if (this.valueOf() === 'int' || this.valueOf() === 'INTEGER' || this.valueOf() === 'NUMBER'
        || this.valueOf() === 'NUMERIC' || this.valueOf() === 'FLOAT' || this.valueOF === 'DECIMAL'
        || this.valueOf() === 'INT' || this.valueOf === 'SMALLINT' || this.valueOF === 'REAL' || this.valueOf === 'DEC')
        return true;
    else
        return false;
}
/**
 * Return formatted JSON string
 * @returns {} 
 */
String.prototype.toJSONLike = function () {
    let formattedText: string;

    formattedText = this.split("[").join("[<br>");
    formattedText = formattedText.split("{").join("{<br>");
    formattedText = formattedText.split("]").join("<br>]");
    formattedText = formattedText.split("}").join("<br>}");
    formattedText = formattedText.split(',"').join(',<br>"');
    formattedText = formattedText.split('":').join('": ');

    let indentNO: number = 0;
    const lines = formattedText.split("<br>");
    lines.forEach((value, i) => {
        if (lines[i - 1] && (lines[i - 1].slice(lines[i - 1].length - 1) === "[" ||
            lines[i - 1].slice(lines[i - 1].length - 1) === "{"))
            indentNO++;
        if (value && (value.slice(value.length - 1) === "]" ||
            value.slice(value.length - 1) === "}" ||
            value.slice(value.length - 2) === "},"))
            indentNO--;

        let indentedText: string = "";
        for (let j = 0; j < indentNO; j++)
            indentedText = `&emsp;${indentedText}`;

        lines[i] = `${indentedText}${value}`;
    });

    return lines.join("<br>");
}

/**
 *HTML to RTF conversion
 * @returns string
 */
String.prototype.htmlToRtf = function (): string {
    const s = String(this);
    let text = s.replace(/(?:<p><br><\/p>)/g, "\r\n");
    text = text.replace(/(?:<br>)/g, "\r\n");
    text = text.replace(/(?:<\/p>)/g, "\r\n");
    text = text.replace(/(?:<div>|<\/div>)/g, "");
    text = text.replace(/(?:<p>)/g, "");
    if (text.endsWith("\r\n")) {

        text = text.replace(new RegExp("\r\n" + '$'), "");
    }
    return text;
}

if (!String.prototype.includes) {
    String.prototype.includes = function (search, start) {
        "use strict";
        if (typeof start !== "number") {
            start = 0;
        }

        if (start + search.length > this.length) {
            return false;
        } else {
            return this.indexOf(search, start) !== -1;
        }
    };
}


interface StringConstructor {
    isNullEmptyWhitespaceOrUndefined(value: string): boolean;
}

String.isNullEmptyWhitespaceOrUndefined = function (value: string): boolean {
    if (value === null || value === undefined || value.trim() === "")
        return true;
    return false;
}

