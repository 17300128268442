import { Component } from "@angular/core";

import { FuseNavVerticalGroupComponent } from "app/core/@fuse/components/navigation/vertical/group/group.component";

@Component({
    selector: "nav-vertical-group",
    templateUrl: "./group.component.html",
    styleUrls: ["./group.component.scss"]
})
export class NavVerticalGroupComponent extends FuseNavVerticalGroupComponent {

}
