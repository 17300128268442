import { Name } from "app/core/decorators";
import { QrmJbtSalesOrderLine } from "app/core/models/entity-models/qrmJbtSalesOrderLine";
import { QrmJobTicket } from "app/core/models/entity-models/qrmJobTicket";
import { SalesOrderLine } from "app/core/models/entity-models/salesOrderLine";
import { SalesOrder } from "app/core/models/entity-models/salesOrder";

/** QRM Job Ticket : Sales Order Lines Full */
@Name("QrmJbtSalesOrderLine")
export class QrmJbtSalesOrderLineFull extends QrmJbtSalesOrderLine {
    name = "QrmJbtSalesOrderLine";
    /** QRM Job Ticket */
    public qrmJobTicket: QrmJobTicket;
    /** Sales Order */
    public salesOrderLine: SalesOrderLine;

    public salesOrder: SalesOrder;
}
