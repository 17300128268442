import { Injectable, EventEmitter } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class PopupService {
    public static instance?: PopupService;
    public okForSave = new BehaviorSubject<boolean>(undefined);
    public selectedEntities = new BehaviorSubject<{ for: string, entities: Array<any> }>(undefined);
    public onSaveChanges = new BehaviorSubject<boolean>(false);
    public onSaveAndClose = new EventEmitter<boolean>(false);
    public onCancel = new EventEmitter<boolean>();
    public onClose = new EventEmitter<boolean>(false);
    public onSaved = new EventEmitter<boolean>(false);

    constructor() {
        if (PopupService.instance !== undefined) {
            throw new Error("Import module only once.");
        }

        PopupService.instance = this;
    }

    public setSelectedEntities(data: {for: string, entities: Array<any>}) {
        this.selectedEntities.next(data);
    }
}
