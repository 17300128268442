import { WorkShiftTrans } from "app/core/models/entity-models/workShiftTrans";
import { Name } from "app/core/decorators";
import { IBaseEntity } from "app/core/interfaces/IBaseEntity";

/** Work Shifts */
@Name("WorkShift")
export class WorkShift extends WorkShiftTrans implements IBaseEntity {
    name = "WorkShift";
    baseEditRouteSegment = "work-shift";
    get id(): string {
        if (!this.workShiftCE) return null;
        return this.workShiftCE;
    }
    errorMessage: string;
    organizationCE: string;

    public listItemLayout(): object {
        return {
            label: {
                property: "workShiftLB",
            },
            inBrackets: {
                property: "tracingDT",
            },
            key: {
                property: "workShiftCE",
            },
            title: {
                property: "workShiftDS",
            },
            description: {
                property: "tracingDT",
            },
            leftBottomList: [],
            rightBottomList: [],
        };
    }
}
