import { TracingBase } from "app/core/models";
import { Name } from "app/core/decorators";
import { IBaseEntity } from "app/core/interfaces/IBaseEntity";

/** QRM Job Tickets : Activities */
@Name("jobTicketResourceItem")
export class jobTicketResourceItem extends TracingBase implements IBaseEntity {
    name = "jobTicketResourceItem";
    /** QRM Job Ticket Number                                           PK */
    public qrmJobTicketNO: string;
    /** QRM Job Ticket Activity Sequence Number                         PK */
    public resourceID: string;
    /** QRM Job Ticket Activity Label */
    public resourceItemID: string;
    /** QRM Job Ticket Activity Description */

}
