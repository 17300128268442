import { IPossibleValueSetValue } from "./interfaces";
import { Name } from "app/core/decorators";
import { TracingBase } from "..";

@Name("DP3PossibleValueSetValue")
export class PossibleValueSetValue
    extends TracingBase
    implements IPossibleValueSetValue
{
    name = "DP3PossibleValueSetValue";
    public projectCE: string;
    public possibleValueSetCE: string;
    public possibleVL: string;
    public sequenceNO: number;
    public valueLB: string;
    public possibleValueHT: string;
    public defaultValueFL: string;
    public activeFL: string;
    public possibleValuePH: string;
    public selected: boolean;
}
