import { Injectable } from "@angular/core";
import {
    Router,
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
} from "@angular/router";
import { AuthService } from "@auth0/auth0-angular";
import { map } from "rxjs/operators";
import { AuthenticationService } from "./authentication.service";

@Injectable()
export class EntityAuthenticationGuard implements CanActivate {
    constructor(
        private readonly _router: Router,
        private readonly authenticationService: AuthenticationService,
        public auth: AuthService
    ) {}

    public canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ) {
        return this.authenticationService.isAuthenticated().pipe(
            map((isAuthenticated) => {
                if (
                    this.authenticationService.currentUser &&
                    isAuthenticated &&
                    this.authenticationService.permissions &&
                    this.authenticationService.permissions.findIndex(
                        (_) => _.functionID === route.data.expectedId
                    ) !== -1
                ) {
                    if (
                        this.authenticationService.permissions.find(
                            (_) => _.functionID === route.data.expectedId
                        ).view
                    )
                        return true;
                    else {
                        this.auth.loginWithRedirect();
                        return false;
                    }
                }
            })
        );
    }
}
