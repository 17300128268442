import { LanguageTrans } from "app/core/models/entity-models/languageTrans";
import { Name } from "app/core/decorators";
import { IBaseEntity } from "app/core/interfaces/IBaseEntity";

/** Languages */
@Name("Language")
export class Language extends LanguageTrans implements IBaseEntity {
    name = "Language";
    baseEditRouteSegment = "language";
    errorMessage: string;
    get id(): string {
        return this.languageCE;
    }

    /** <languageCE> <languageLB> */
    public get caption(): string {
        return `${this.languageCE} ${this.languageLB}`;
    }

    public listItemLayout(): object {
        return {
            label: {
                property: "languageLB",
            },
            inBrackets: {
                property: "tracingDT",
            },
            key: {
                property: "languageCE",
            },
            title: {
                property: "languageDS",
            },
            description: {
                property: "tracingDT",
            },
            leftBottomList: [],
            rightBottomList: [],
        };
    }
}
