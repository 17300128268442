import { QrmRoutingCapacityCard } from "app/core/models/entity-models/qrmRoutingCapacityCard";
import { QrmCell } from "app/core/models/entity-models/qrmCell";
import { QrmJobTicket } from "app/core/models/entity-models/qrmJobTicket";
import { Name } from "app/core/decorators";

/** QRM Routing Capacity (Polca) Cards Full */
@Name("QrmRoutingCapacityCard")
export class QrmRoutingCapacityCardFull extends QrmRoutingCapacityCard {
    name = "QrmRoutingCapacityCard";
    /** QRM Cell */
    public qcelQrmCell: QrmCell;
    /** Paired QRM Cell */
    public prdQrmCell: QrmCell;
    /** Assigned QRM Job Ticket */
    public qrmJobTicket: QrmJobTicket;

    public get fromToLabel(): string {
        return `${
            this.qcelQrmCell ? this.qcelQrmCell.qrmCellLB : this.qrmCellCE
        } - ${
            this.prdQrmCell ? this.prdQrmCell.qrmCellLB : this.pairedQrmCellCE
        }`;
    }
}
