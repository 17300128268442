import { IBaseEntity } from "app/core/interfaces/IBaseEntity";
import { Name } from "app/core/decorators";
import { TracingBase } from "app/core/models/tracingBase";

/** Customers */
@Name("Customer")
export class Customer extends TracingBase implements IBaseEntity {
    baseEditRouteSegment = "customer";
    name = "Customer";
    public get id(): string {
        return this.customerNO;
    }

    public errorMessage: string;

    /** Customer Number                 PK */
    public customerNO: string;
    /** Customer Name */
    public customerNM: string;
    /** Address Label Line 1 */
    public addressLine1LB: string;
    /** Address Label Line 2 */
    public addressLine2LB: string;
    /** Address Label Line 3 */
    public addressLine3LB: string;
    /** Address Label Line 4 */
    public addressLine4LB: string;
    /** Address Label Line 5 */
    public addressLine5LB: string;
    /** Zip Code Reference */
    public zipCodeRF: string;
    /** City Name */
    public cityNM: string;
    /** General Phone Number */
    public generalPhoneNO: string;
    /** General E-Mail Reference */
    public generalEmailRF: string;
    /** General Website Url */
    public generalWebsiteUR: string;
    /** VAT Number Reference */
    public vatNumberRF: string;
    /** Default Language Code */
    public defaultLanguageCE: string;

    public listItemLayout(): object {
        return {
            label: { property: "customerNM" },
            inBrackets: { property: "tracingDT" },
            key: { property: "customerNO" },
            title: { property: "tracingDT" },
            description: { property: "tracingDT" },
            leftBottomList: [],
            rightBottomList: [
                { toSlice: 200, property: "defaultLanguageCE" },
                { toSlice: 200, property: "cityNM" },
                { toSlice: 200, property: "generalPhoneNO" },
            ],
        };
    }

    /** <customerNO> <customerNM> */
    public get caption(): string {
        return `${this.customerNO} ${this.customerNM}`;
    }
}
