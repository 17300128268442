import { TransTracingBase } from "app/core/models";
import { Name } from "app/core/decorators";

/** Calendars - Translations */
@Name("CalendarTrans")
export class CalendarTrans extends TransTracingBase {
    name = "CalendarTrans";
    /** Calendar Code                                 PK */
    public calendarCE: string;
    /** Calendar Label */
    public calendarLB: string;
    /** Calendar Description */
    public calendarDS: string;
    /** Calendar Info (Hyperlink) */
    public calendarUR: string;
    /** Calendar Info (Hypertext) */
    public calendarHT: string;
    /** Calendar Info (Preview of the Hypertext)      Virtual attribute */
    public calendarPH: string;
}
