export enum TicketLevelType {
    "Family",
    "Product",
    "Qrm_Ticket",
}
export class TicketLevelTypeModel {
    level: number;
    name: string;
    column: string;
    selected: boolean;
}
export const TicketLevelTypeEnum: TicketLevelTypeModel[] = [
    { level: 0, name: "Family", column: "qrmProductFamilyCE", selected: false },
    { level: 1, name: "Product", column: "qrmProductCE", selected: false },
];
