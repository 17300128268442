import {
    ChangeDetectorRef,
    ViewChild,
    Input,
    AfterViewChecked,
    Inject,
    Directive,
} from "@angular/core";
import { Subject, of, BehaviorSubject } from "rxjs";
import { distinctUntilChanged, switchMap, debounceTime } from "rxjs/operators";
import { NgForm } from "@angular/forms";
import { BaseEntity } from "../models";
import { IAutoSave } from "app/core/interfaces/IAutoSave";
import {
    EDIT_SERVICE,
    IBaseEditService,
} from "app/core/interfaces/IBaseEditService";
import { PopupService } from "app/core/services/pop-up-service/popup.service";
import { AppInjector } from "app/app.module";
import { BaseModel } from "app/core/models";
@Directive()
export abstract class AutoSave<T extends BaseModel>
    extends BaseEntity<T>
    implements IAutoSave, AfterViewChecked
{
    public autoSave$ = new Subject<any>();
    public entitySaved = new BehaviorSubject<boolean>(false);
    public autoSaveInterval = 1000000;
    public disableSave = true;
    public readOnly = true;
    public isViewMode = false;
    public errorMessage: string;
    public isLoading = false;
    @ViewChild("form", { static: false })
    public editForm = new NgForm(null, null);
    @Input()
    public disableAutoSave = false;

    public popupService: PopupService;

    protected constructor(
        entityType: new () => T,
        @Inject(EDIT_SERVICE) protected detailsService: IBaseEditService<T>,
        protected changeDetect?: ChangeDetectorRef
    ) {
        super(detailsService, entityType);
    }

    public ngOnInit() {
        super.ngOnInit();

        this.popupService = AppInjector.get(PopupService);

        if (this.disableAutoSave) return;

        this.entitySaved.subscribe((saved) => {
            if (saved && this.popupService) {
                this.popupService.onSaved.next(true);
            }
        });

        this.autoSave$
            .pipe(
                debounceTime(this.autoSaveInterval),
                distinctUntilChanged(),
                switchMap((term) => {
                    /*do something*/
                    return of(term);
                })
            )
            .subscribe(() => {
                if (this.formIsValid()) {
                    this.saveChanges(true);
                }
            });
    }

    public ngAfterViewChecked(): void {
        this.subscribeToStatusChanges();
    }

    public saveChanges(auto?: boolean) {
        this.disableSave = true;
        if (this.changeDetect) {
            this.changeDetect.detectChanges();
        }

        this.submitForm(auto);
        this.editForm.form.markAsPristine();
    }

    public abstract submitForm(auto?: boolean);

    public subscribeToStatusChanges() {
        if (this.editForm) {
            this.editForm.statusChanges.subscribe(() => {
                this.disableSave = !this.formIsValid();
                //this.detailsService.onCurrentFromValidationChanged.next(this.editForm.valid)
            });
        }
    }

    public forceDirty() {
        if (this.editForm) {
            this.editForm.form.markAsDirty();
        }
    }

    public formIsValid(): boolean {
        return this.editForm && this.editForm.valid && this.editForm.dirty;
    }

    public delete(item?: any) {}
}
