import { Name } from "app/core/decorators";
import { QrmProduct } from "app/core/models/entity-models/qrmProduct";
import { QrmProductBomExplosion } from "app/core/models/entity-models/qrmProductBomExplosion";
import { UnitOfMeasure } from "app/core/models/entity-models/unitOfMeasure";
import { QrmProductBom } from "app/core/models/entity-models/qrmProductBom";
import { QrmProductBomPart } from "app/core/models/entity-models/qrmProductBomPart";
import { QrmCell } from "app/core/models/entity-models/qrmCell";
import { CapacityCluster } from "app/core/models/entity-models/capacityCluster";

/** QRM Products : Bills of Material : Explosion */
@Name("QrmProductBomExplosion")
export class QrmProductBomExplosionFull extends QrmProductBomExplosion {
    name = "QrmProductBomExplosion";
    /** Explosion QRM Product */
    public xplQrmProduct: QrmProduct;

    /** Stock Quantity Unit of Measure */
    public sqtUnitOfMeasure: UnitOfMeasure;

    /** QRM Product Bill of Material */
    public qpbmQrmProductBom: QrmProductBom;

    /** Part Quantity Unit of Measure */
    public pqtUnitOfMeasure: UnitOfMeasure;

    /** QRM Product Bill of Material Part.*/
    public qrmProductBomPart: QrmProductBomPart;

    /**  Capacity Cluster  */
    public capacityCluster: CapacityCluster;

    /** Expected Amount of Processing Time Unit */
    public xptUnitOfMeasure: UnitOfMeasure;

    /** QRM Cell */
    public qrmCell: QrmCell;

    /** Bill of Material QRM Product Bill of Material of Part QRM Product*/
    public prtQrmProductBom: QrmProductBom;

    /** Bill of Material QRM Product of Part QRM Product*/
    public prtQrmProduct: QrmProduct;

    /** Exploded QRM Product Bill of Material */
    public xplQrmProductBom: QrmProductBom;
    parentExplosionLineSQ: number;

    public get treeLB(): string {
        const qty = this.qrmProductBomPart ? this.qrmProductBomPart.partQT : 1;
        const bomLB = this.isBOM ? this.xplQrmProductBom.qrmProductBomLB : "";
        return `${bomLB} ${this.xplQrmProduct.qrmProductLB} ${this.xplQrmProduct.qrmProductDS} ${qty} ${this.pqtUnitOfMeasure.unitLB}`;
    }

    public get routerLink(): string {
        //no link for root
        if (this.explosionLevelSQ === 0) return undefined;

        return `/qrm-product-bom-part/edit/${this.qrmProductBomPart.qrmProductCE},${this.qrmProductBomPart.qrmProductBomSQ},${this.qrmProductBomPart.qrmProductBomPartSQ}`;
    }

    public get routerPopUpLink(): string {
        if (this.explosionLevelSQ === 0)
            return `qrm-product-bom/edit/${this.xplQrmProduct.qrmProductCE},${this.xplQrmProductBom.qrmProductBomSQ}`;

        return `qrm-bom-part/edit/${this.qrmProductBomPart.qrmProductCE},${this.qrmProductBomPart.qrmProductBomSQ},${this.qrmProductBomPart.qrmProductBomPartSQ}`;
    }
    public get bomPopUpLink(): string {
        return `qrm-product-bom/edit/${this.xplQrmProduct.qrmProductCE},${this.xplQrmProductBom.qrmProductBomSQ}`;
    }
    public get partPopUpLink(): string {
        return `qrm-bom-part/edit/${this.qrmProductBomPart.qrmProductCE},${this.qrmProductBomPart.qrmProductBomSQ},${this.qrmProductBomPart.qrmProductBomPartSQ}`;
    }

    public get isRoot(): boolean {
        return this.explosionLevelSQ === 0;
    }

    public children: Array<QrmProductBomExplosionFull>;
}
