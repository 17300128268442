import { Name } from "app/core/decorators";
import { Language } from "app/core/models/entity-models/language";
import { Customer } from "app/core/models/entity-models/customer";

/** Customers Full */
@Name("Customer")
export class CustomerFull extends Customer {
   
    /** Default Language */
    public language: Language;
}
