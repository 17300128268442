import { EntityDefinitionAttribute } from "./entityDefinitionResponse";

export class ProcedureDefinitionResponse {
    key: string;
    theRowidInCharFormat: string;
    theStatusOfTheRow: string;
    isTheRowLocked: string;
    theLanguageOfTheTranslation: string;
    theStatusOfTheTranslationRow: string;
    isTheTranslationRowLocked: string;
    projectCE: string;
    packageCE: string;
    procedureCE: string;
    tracingDT: Date;
    procedureDS: string;
    procedureTY: string;
    procedureNM: string;
    publicProcedureFL: string;
    generateApiFL: string;
    packageId: string;
    procedureId: string;
    definitionURL: string;
    executeURL: string;
    parameters: ProcedureDefinitionParameter[];
    parameterGroups: any[];
    actionEntityId: any;
    actionIcon: string;
    successAction: string;
    isListCall: boolean
    static setParameters(definition) {
        if (definition.attributesAreSet) return;

        definition.parameters.forEach((parameter) => {
            parameter = parameter as ProcedureDefinitionParameter;
        });
    }
}

export class ProcedureDefinitionParameter extends EntityDefinitionAttribute {
    parameterID: string;
    parameterTY: string;
    uiGroupId: string;
    parameters: any[];
    private _parameterDS: string;
    public get parameterDS(): string {
        return this._parameterDS;
    }
    public set parameterDS(v: string) {
        this._parameterDS = v;
        this.attributeMediumPromptTX = this.attributeLongPromptTX = v;
    }
}
