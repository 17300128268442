import { IBaseEntity } from "app/core/interfaces/IBaseEntity";
import { Name } from "app/core/decorators";
import { OrderPolicyTrans } from "app/core/models/entity-models/orderPolicyTrans";

/** Order Policies */
@Name("OrderPolicy")
export class OrderPolicy extends OrderPolicyTrans implements IBaseEntity {
    name = "OrderPolicy";
    public get id(): string {
        return this.orderPolicyCE;
    }

    public errorMessage: string;

    /** Order Policy Type */
    public orderPolicyTY: string;
}
