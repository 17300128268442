import { TracingBase } from "app/core/models";
import { Name } from "app/core/decorators";
import { IBaseEntity } from "app/core/interfaces/IBaseEntity";

/** System Prefixes */
@Name("SystemPrefix")
export class SystemPrefix extends TracingBase implements IBaseEntity {
    name = "SystemPrefix";
    baseEditRouteSegment = "system-prefix";
    errorMessage: string;
    get id(): string {
        return this.columnNM;
    }
    /** Column Name     PK */
    public columnNM: string;
    /** Key Number Prefix */
    public keyNumberPF: string;
    /** Variable Prefix Mask Type */
    public variablePrefixMaskTY: string;
    /** Sequence Type */
    public sequenceTY: string;
    /** Frequency Type of the Sequence Reset */
    public sequenceResetFrequencyTY: string;
    /** Last Sequence Reset Date */
    public lastSequenceResetDT: Date;
    /** Maximum Key Length, Prefix included */
    public maximumKeyLengthNB: number;
    /** Is the Length of the Key always Fixed? */
    public fixedLengthFL: string;
    /** Should the Key always be Generated? */
    public alwaysGenerateFL: string;
    /** Length of the Key if Generated (can be different from maximum key length) */
    public generationKeyLengthNB: number;
    /** Sequence Correction Value */
    public sequenceCorrectionVL: number;

    public get fixedLengthFLBoolean(): boolean {
        return this.fixedLengthFL === "Y";
    }
    public set fixedLengthFLBoolean(value: boolean) {
        this.fixedLengthFL = value ? "Y" : "N";
    }
    public get alwaysGenerateFLBoolean(): boolean {
        return this.alwaysGenerateFL === "Y";
    }
    public set alwaysGenerateFLBoolean(value: boolean) {
        this.alwaysGenerateFL = value ? "Y" : "N";
    }

    public listItemLayout(): object {
        return {
            label: {
                property: "tracingDT",
            },
            inBrackets: {
                property: "tracingDT",
            },
            key: {
                property: "columnNM",
            },
            title: {
                property: "tracingDT",
            },
            description: {
                property: "tracingDT",
            },
            leftBottomList: [
                {
                    toSlice: 200,
                    property: "keyNumberPF",
                },
                {
                    toSlice: 200,
                    property: "variablePrefixMaskTY",
                },
            ],
            rightBottomList: [
                {
                    property: "sequenceTY",
                },
                {
                    toSlice: 200,
                    property: "sequenceResetFrequencyTY",
                },
            ],
        };
    }
}
