import { Name } from "app/core/decorators";
import { ProjectPart } from "app/core/models/entity-models/projectPart";
import { ProjectFull } from "app/core/models/entity-models/projectFull";

/** Project Parts */
@Name("ProjectPart")
export class ProjectPartFull extends ProjectPart {
    name = "ProjectPart";
    public project: ProjectFull;
}
