import { TransTracingBase } from "app/core/models";
import { Name } from "app/core/decorators";

/** Sales Order Line : Statuses - Translations */
@Name("SalesOrderLineStatusTrans")
export class SalesOrderLineStatusTrans extends TransTracingBase {
    name = "SalesOrderLineStatusTrans";
    /** Sales Order Line Status Code                                PK */
    public salesOrderLineStatusCE: string;
    /** Sales Order Line Status Label */
    public salesOrderLineStatusLB: string;
    /** Sales Order Line Status Description */
    public salesOrderLineStatusDS: string;
    /** Sales Order Line Status Info (Hyperlink) */
    public salesOrderLineStatusUR: string;
    /** Sales Order Line Status Info (Hypertext) */
    public salesOrderLineStatusHT: string;
}
