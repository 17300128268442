import { TracingBase } from "app/core/models";
import { Name } from "app/core/decorators";
import { IBaseEntity } from "app/core/interfaces/IBaseEntity";

/** QRM Cells : Temporary Capacity Deviations Full */
@Name("QrmCellTempCapacDeviation")
export class QrmCellTempCapacDeviation
    extends TracingBase
    implements IBaseEntity
{
    name = "QrmCellTempCapacDeviation";
    baseEditRouteSegment = "qrm-temp-capacity-deviation";
    get id(): string {
        if (!this.qrmCellCE || !this.capacityDeviationStartDT) return null;
        return `${this.qrmCellCE},${this.capacityDeviationStartDT}`;
    }
    errorMessage: string;

    /** QRM Cell Code                                       PK */
    public qrmCellCE: string;
    /** Capacity Deviation Start Date                       PK */
    public capacityDeviationStartDT: Date;
    /** Capacity Deviation End Date */
    public capacityDeviationEndDT: Date;
    /** Cell Capacity Cluster Quantity per Time Period */
    public cellCapacityClusterQT: number;
    /** Cell Capacity Cluster Code                          Virtual attribute */
    public cellCapacityClusterCE: string;
    /** Capacity Cluster Quantity per Polca Card */
    public polcaCardCapacityClusterQT: number;
    /** Polca Card Capacity Cluster Code                    Virtual attribute */
    public polcaCardCapacityClusterCE: string;
    /** QRM Cell Preference Sequence Number */
    public qrmCellPreferenceSQ: number;

    public listItemLayout(): object {
        return {
            label: { property: "tracingDT" },
            inBrackets: { property: "tracingDT" },
            key: { property: "qrmCellCE" },
            title: { property: "capacityDeviationStartDT" },
            description: { property: "tracingDT" },
            leftBottomList: [],
            rightBottomList: [
                { property: "cellCapacityClusterQT" },
                { toSlice: 200, property: "polcaCardCapacityClusterQT" },
                { toSlice: 200, property: "qrmCellCE" },
            ],
        };
    }
}
