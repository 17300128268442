export enum QueryTypeEnum {
    'Definition',
    'DefaultValues',
    'ValidateKey',
    'GetOne',
    'GetOneFull',
    'QueryFull',
    'Query',
    'QueryAll',
    'InsertOrUpdate',
    'Insert',
    'Update',
    'Delete',
    'LogicalDelete',
    'LogicalDeleteByKey',
    'DeleteByKey'
}
