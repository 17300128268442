import { Component, OnInit, Output, EventEmitter } from "@angular/core";

import { Router, ActivatedRoute } from "@angular/router";
import { PopupService } from "app/core/services/pop-up-service/popup.service";
import { takeUntil } from "rxjs/operators";
import { Unsubscriber } from "app/core/component-base/models";

@Component({
    templateUrl: "./pop-up.component.html",
    styleUrls: ["./pop-up.component.scss"],
})
export class PopUpComponent extends Unsubscriber implements OnInit {
    public fuseConfig: any;
    public navigation: any;
    public showOkForSave: boolean;
    public showSave = true;

    @Output()
    public modalClose = new EventEmitter<any>();

    constructor(
        private readonly router: Router,
        private readonly popUpService: PopupService,
        private readonly activatedRoute: ActivatedRoute
    ) {
        super();

        const routeSegments = this.activatedRoute.snapshot[
            "_urlSegment"
        ].segments.map((_) => _.path);
        if (
            routeSegments &&
            routeSegments.length > 1 &&
            routeSegments.includes("edit")
        ) {
            this.showSave = false;
        }
    }

    public closeModal($event: MouseEvent, cancel?: boolean) {
        this.router.navigate([{ outlets: { modal: null } }], {
            skipLocationChange: false,
            queryParamsHandling: "merge",
        });
        this.modalClose.next($event);
        this.popUpService.onCancel.next(cancel);
        this.popUpService.okForSave.next(undefined);
    }

    public onSave($event: MouseEvent) {
        this.popUpService.onSaveChanges.next(true);
        this.closeModal($event);
    }

    public ngOnInit(): void {
        this.popUpService.okForSave
            .pipe(takeUntil(this.isUnsubscribing))
            .subscribe((ok) => {
                this.showOkForSave = ok;
            });

        this.popUpService.onSaveAndClose
            .pipe(takeUntil(this.isUnsubscribing))
            .subscribe((data: boolean) => {
                if (data) {
                    this.router.navigate([{ outlets: { modal: null } }], {
                        skipLocationChange: false,
                        queryParamsHandling: "merge",
                    });
                    this.modalClose.next(null);
                    this.popUpService.okForSave.next(undefined);
                }
            });

        this.popUpService.onClose
            .pipe(takeUntil(this.isUnsubscribing))
            .subscribe((_) => {
                if (_) {
                    this.closeModal(null);
                }
            });
    }
}
