import { TracingBase } from "app/core/models";
import { Name } from "app/core/decorators";

/** QRM Job Tickets : Connections */
@Name("QrmJobTicketConnection")
export class QrmJobTicketConnection extends TracingBase {
    name = "QrmJobTicketConnection";
    /** Upstream QRM Job Ticket Number                              PK */
    public upstreamQrmJobTicketNO: string;
    /** Downstream QRM Job Ticket Number                            PK  */
    public downstreamQrmJobTicketNO: string;
    /** Upstream QRM Job Ticket Connection Sequence Number */
    public upstreamQrmJobTicketSQ: number;
    /** Downstream QRM Job Ticket Connection Sequence Number */
    public downstreamQrmJobTicketSQ: number;
}
