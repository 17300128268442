
import { BehaviorSubject, Observable } from "rxjs";
import { InjectionToken } from "@angular/core";
import { IBaseEntity } from "./IBaseEntity";
import { ICodeKeyValue } from "../models/interfaces";
import { IMetadataService } from "./iMetadataService";

export let EDIT_SERVICE = new InjectionToken<IBaseEditService<any>>("edit-service");

export interface IBaseEditService<T extends IBaseEntity> extends IMetadataService{
    onCurrentEntityChanged: BehaviorSubject<T>;
    setEntity: BehaviorSubject<string>;
    onCurrentEntitySave: BehaviorSubject<T>;
    onLogicalDelete: BehaviorSubject<boolean>;
    currentEntity: T;
    getCurrentEntity(code: string, listOfVirtualAttributes: boolean, translationLanguageCE?: string): Observable<T>;
    getCurrentEntityDefault(): Observable<T>;
    setCurrentEntity(entity: T): void;
    showTranslations: boolean;
    isPopup: boolean;
    isInRelatedTab: boolean;
}

export interface IBaseService extends IMetadataService {

    onCurrentFromValidationChanged: BehaviorSubject<any>;

    defaultValues<T extends { new(...args: any[]): any, prototype: any }>(type: T): Observable<any>;

    getOneProjectEntityAttributes<T extends { new(...args: any[]): any, prototype: any }>(type: T,
        entityCE: string, entityTransCE: string): Observable<any>;

    getAttributePossibleValues<T extends { new(...args: any[]): any, prototype: any }>(type: T,
        entityCE: string, listOfAttributeCE: string): Observable<any>;

    getOne<T extends { new(...args: any[]): any, prototype: any }>(type: T,
        options: {
            keys: Array<ICodeKeyValue>,
            listOfVirtualAttributes?: Array<string>,
            raiseErrorIfLogicalDeleted?: string }): Observable<any>;

     getOneFull<T extends { new(...args: any[]): any, prototype: any }>(type: T,
        options: {
            keys: Array<ICodeKeyValue>,
            listOfVirtualAttributes?: Array<string>,
            raiseErrorIfLogicalDeleted?: string }): Observable<any>;

    query<T extends { new(...args: any[]): any, prototype: any }>(type: T,
        options?: {
            pagination?: boolean, skipCalculate?: boolean, pageSize?: number,
            pageIndex?: number, whereClause?: string, orderByClause?: string,
            listOfVirtualAttributes?: Array<string> }): Observable<any>;

    queryFull<T extends { new(...args: any[]): any, prototype: any }>(type: T,
        options?: {
            pagination?: boolean, pageSize?: number, pageIndex?: number, whereClause?: string,
            orderByClause?: string, listOfVirtualAttributes?: Array<string> }): Observable<any>;

    insertOrUpdate<T extends { new(...args: any[]): any, prototype: any }>(type: T, entity: Object): Observable<any>;

    insert<T extends { new(...args: any[]): any, prototype: any }>(type: T, entity: Object): Observable<any>;

    update<T extends { new(...args: any[]): any, prototype: any }>(type: T, entity: Object, fetchedEntity?: Object): Observable<any>;

    logicalDelete<T extends { new(...args: any[]): any, prototype: any }>(type: T, entity: Object, fetchedEntity?: Object): Observable<any>;

    logicalDeleteByKey<T extends { new(...args: any[]): any, prototype: any }>(type: T, keys: Array<ICodeKeyValue>, fetchedEntity?: Object): Observable<any>;

    delete<T extends { new(...args: any[]): any, prototype: any }>(type: T, entity: Object): Observable<any>;

    deleteByKey<T extends { new(...args: any[]): any, prototype: any }>(type: T, keys: Array<ICodeKeyValue>, fetchedEntity?: Object): Observable<any>;


}
