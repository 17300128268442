import { Name } from "app/core/decorators";
import { ProjectStatusTrans } from "app/core/models/entity-models/projectStatusTrans";
import { IBaseEntity } from "app/core/interfaces/IBaseEntity";

/** Project Statuses */
@Name("ProjectStatus")
export class ProjectStatus extends ProjectStatusTrans implements IBaseEntity {
    name = "ProjectStatus";
    public get id(): string {
        return this.projectStatusCE;
    }

    public errorMessage: string;

    /** Status Sequence Number */
    public statusSQ: string;
    /** Is this Status to be used as Default Status ? */
    public defaultStatusFL: string;
    /** Force the Default at Creation ? */
    public forceDefaultAtCreationFL: string;
    /** Project Status Type */
    public projectStatusTY: string;
}
