import { TransTracingBase } from "app/core/models";
import { Name } from "app/core/decorators";

/** Products : Types - Translations */
@Name("ProductTypeTrans")
export class ProductTypeTrans extends TransTracingBase {
    name = "ProductTypeTrans";
    /** Product Type Code                 PK */
    public productTypeCE: string;
    /** Product Type Label */
    public productTypeLB: string;
    /** Product Type Description */
    public productTypeDS: string;
    /** Product Type Info (Hyperlink) */
    public productTypeUR: string;
    /** Product Type Info (Hypertext) */
    public productTypeHT: string;
}
