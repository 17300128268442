import { Name } from "app/core/decorators";

/** QRM Simulated Job Tickets */
@Name("QrmSimulatedJobTicket")
export class QrmSimulatedJobTicket {
    name = "QrmSimulatedJobTicket";
    /** Identification of Qrm Job Ticket Simulation                             PK */
    public qrmJobTicketSimulationID: string;
    /** QRM Simulated Job Ticket Line Sequence Number                           PK */
    public qrmSimJobTicketLineSQ: number;
    /** Identification of Parent Qrm Job Ticket Simulation */
    public parentQrmJobTicketSimID: number;
    /** QRM Simulated Job Ticket Line Sequence Number */
    public parentQrmSmJobTicketLnSQ: number;
    /** QRM Product Code */
    public qrmProductCE: string;
    /** QRM Job Ticket Quantity */
    public qrmJobTicketQT: number;
    /** Unit of Measure Code */
    public qrmJobTicketQtUnitCE: string;
    /** QRM Cell Code */
    public qrmCellCE: string;
    /** Needed Capacity Cluster Quantity for the Simulated QRM Job Ticket */
    public capacityClusterQT: number;
    /** Capacity Cluster Code */
    public capacityClusterCE: string;
    /** Expected Amount of Processing Time */
    public processTimeQT: number;
    /** Expected Amount of Processing Time Unit Code */
    public processTimeUnitCE: string;
    /** Expected Processing Time Type */
    public processTimeTY: string;
    /** Authorization Date */
    public authorizationDT: Date;
    /** Expected Completion Date */
    public scheduledCompletionDT: Date;
    /** Expected Touch Time Percentage */
    public scheduledTouchTimePC: number;
    /** Earliest Start Date */
    public earliestStartDT: Date;
    /** Latest Start Date */
    public latestStartDT: Date;

    public crtitcalPathFL: string;
}
