import { TransTracingBase } from "app/core/models";
import { Name } from "app/core/decorators";

/** QRM Product Families - Translations */
@Name("QrmProductFamilyTrans")
export class QrmProductFamilyTrans extends TransTracingBase {
    name = "QrmProductFamilyTrans";
    /** QRM Product Family Code                                  PK */
    public qrmProductFamilyCE: string;
    /** QRM Product Family Label */
    public qrmProductFamilyLB: string;
    /** QRM Product Family Description */
    public qrmProductFamilyDS: string;
    /** QRM Product Family Info (Hyperlink) */
    public qrmProductFamilyUR: string;
    /** QRM Product Family Info (Hypertext) */
    public qrmProductFamilyHT: string;
}
