import { TracingBase } from "app/core/models";
import { Name } from "app/core/decorators";
import { IBaseEntity } from "app/core/interfaces/IBaseEntity";

/** Calendars : Work Shifts : Exceptions */
@Name("CalendarWorkShiftException")
export class CalendarWorkShiftException
    extends TracingBase
    implements IBaseEntity
{
    name = "CalendarWorkShiftException";
    public get id(): string {
        if (!this.calendarCE || !this.workShiftCE || !this.workShiftExceptionDT)
            return null;
        return `${this.calendarCE},${this.workShiftCE},${this.workShiftExceptionDT}`;
    }

    public set parentCalendarWorkShiftId(value: string) {
        if (String.isNullEmptyWhitespaceOrUndefined(value)) {
            this.calendarCE = null;
            this.workShiftCE = null;
        } else {
            const codes = value.split(",");
            this.calendarCE = codes[0];
            this.workShiftCE = codes[1];
        }
    }

    public errorMessage: string;

    /** Calendar Code                   PK */
    public calendarCE: string;
    /** Work Shift Code                 PK */
    public workShiftCE: string;
    /** Work Shift Exception Date       PK */
    public workShiftExceptionDT: Date;
    /** Shift Start Time */
    public shiftStartTM: number;
    /** Shift End Time */
    public shiftEndTM: number;
    /** Quantity of Available Time */
    public availableTimeQT: number;
    /** Unit of Measure Code */
    public availableTimeUnitCE: string;
}
