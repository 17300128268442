import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EntityEditLinkDirective } from './entity-edit-link.directive';

@NgModule({
    declarations: [EntityEditLinkDirective],
  imports: [
    CommonModule
    ],
    exports: [EntityEditLinkDirective]
})
export class EntityEditLinkModule { }
