import { CalendarNonWorkDay } from "app/core/models/entity-models/calendarNonWorkDay";
import { Calendar } from "app/core/models/entity-models/calendar";
import { Name } from "app/core/decorators";

/** Calendars : Non-Working Days Full */
@Name("CalendarNonWorkday")
export class CalendarNonWorkDayFull extends CalendarNonWorkDay {
    name = "CalendarNonWorkday";
    /** Calendar */
    public calendar: Calendar;
}
