import { TracingBase } from "app/core/models";
import { Name } from "app/core/decorators";
import { IBaseEntity } from "app/core/interfaces/IBaseEntity";

/** QRM Job Tickets : Activities */
@Name("QrmJobTicketPArt")
export class QrmJobTicketPart extends TracingBase implements IBaseEntity {
    name = "QrmJobTicketPart";
    /** QRM Job Ticket Number                                           PK */
    public qrmJobTicketNO: string;
    /** QRM Job Ticket Activity Sequence Number                         PK */
    public qrmJobTicketPartSQ: number;
    /** QRM Job Ticket Activity Label */

    public get id(): string {
        return this.qrmJobTicketNO && this.qrmJobTicketPartSQ
            ? `${this.qrmJobTicketNO},${this.qrmJobTicketPartSQ}`
            : null;
    }

    public errorMessage: string;

}
