import { HttpClient } from "@angular/common/http";
import { EventEmitter, Injectable, OnDestroy } from "@angular/core";
import { environment } from "environments/environment";

import { hubConnection } from "signalr-no-jquery";
@Injectable()
export class SignalRService {
    apiUrl: string;
    private connection: any;
    private proxy: any;
    entityNotification = new EventEmitter<any>();
    conectionNotification = new EventEmitter<any>();
    constructor() {
        this.apiUrl = environment.apiBaseUrl.replace("/api", "");
    }
    public initializeSignalRConnection(): void {
        let signalRServerEndPoint = this.apiUrl;
        this.connection = hubConnection(signalRServerEndPoint);
        this.proxy = this.connection.createHubProxy("dbupdateshub");

        this.proxy.on("addMessage", (serverMessage, json, jsontx, a, b) => {
            this.onMessageReceived({
                serverMessage,
                json,
                jsontx,
                a,
                b,
            });
        });
        this.connectToSignalR();
    }
    connectToSignalR() {
        this.connection
            .start({ pingInterval: 1000 })
            .done((data: any) => {
                console.log("Connected to Notification Hub");
                this.onConnectionStatusChange(true);
            })
            .fail((error: any) => {
                console.log("Notification Hub error -> " + error);
                this.onConnectionStatusChange(false);
            });

        this.connection.disconnected((data: any) => {
            console.log("Disconnected Hub error");
            this.onConnectionStatusChange(false);
            setTimeout((data: any) => {
                //
                this.connection.start().done((data: any) => {
                    console.log("Reconnected to Notification Hub");
                    this.onConnectionStatusChange(true);
                });
            }, 5000);
        });
        this.connection.reconnecting((data: any) => {
            console.log("Reconecting to signalR hub");
            this.onConnectionStatusChange(false);
        });
        this.connection.reconnected((data: any) => {
            console.log("SignalR hub reconnected");
            this.onConnectionStatusChange(true);
        });
    }
    private onMessageReceived(jsontx: any) {
        this.entityNotification.next(jsontx);
    }
    private onConnectionStatusChange(connection: boolean) {
        this.conectionNotification.next(connection);
    }
}
