import { FocusedTrgMarketSgmTrans } from "app/core/models/entity-models/focusedTrgMarketSgmTrans";
import { Name } from "app/core/decorators";
import { IBaseEntity } from "app/core/interfaces/IBaseEntity";

/** Focused Target Market Segment */
@Name("FocusedTrgMarketSgm")
export class FocusedTrgMarketSgm
    extends FocusedTrgMarketSgmTrans
    implements IBaseEntity
{
    name = "FocusedTrgMarketSgm";
    baseEditRouteSegment = "focused-trg-market-sgm";
    errorMessage: string;
    get id(): string {
        return this.ftmsCE;
    }
    public polcaJS: string;
    /** <ftmsCE> <ftmsLB> */
    public get caption(): string {
        return `${this.ftmsCE} ${this.ftmsLB}`;
    }

    private _polcaDefinition: any;
    public get polcaDefinition(): any {
        if (!this._polcaDefinition) this.initPolca();
        return JSON.parse(JSON.stringify(this._polcaDefinition));
    }

    public initPolca() {
        if (this.polcaJS) {
            this._polcaDefinition = JSON.parse(this.polcaJS);
        }
        else {
            this._polcaDefinition = this.stdPolcaDefiniton;
            this.polcaJS = JSON.stringify(this.polcaDefinition);
        }

    }

    public get stdPolcaDefiniton(): any {
        var l0: any = this.stdPolcaListDefinition;
        l0.title = "Release";
        l0.whereTX = "exists (select 1 from QRM_JOB_TICKET_STATUS qjts where qjts.QRM_JOB_TICKET_STATUS_CE = QRM_JOB_TICKET.QRM_JOB_TICKET_STATUS_CE and qjts.QRM_JOB_TICKET_STATUS_TY = '25')";
        l0.orderByTX = "AuthorizationDT";
        l0.ticketLayout.showTouch = false;
        var l1: any = this.stdPolcaListDefinition;
        l1.title = "Preparation";
        l1.whereTX = "exists (select 1 from QRM_JOB_TICKET_STATUS qjts where qjts.QRM_JOB_TICKET_STATUS_CE = QRM_JOB_TICKET.QRM_JOB_TICKET_STATUS_CE and qjts.QRM_JOB_TICKET_STATUS_TY = '50')";
        l1.orderByTX = "AuthorizationDT";
        l1.ticketLayout.showTouch = false;
        var l2: any = this.stdPolcaListDefinition;
        l2.title = "Queue";
        l2.whereTX = "exists (select 1 from QRM_JOB_TICKET_STATUS qjts where qjts.QRM_JOB_TICKET_STATUS_CE = QRM_JOB_TICKET.QRM_JOB_TICKET_STATUS_CE and qjts.QRM_JOB_TICKET_STATUS_TY = '50')";
        l2.orderByTX = "AuthorizationDT";
        l2.ticketLayout.showTouch = false;
        var l3: any = this.stdPolcaListDefinition;
        l3.title = "In Operation";
        l3.whereTX = "exists (select 1 from QRM_JOB_TICKET_STATUS qjts where qjts.QRM_JOB_TICKET_STATUS_CE = QRM_JOB_TICKET.QRM_JOB_TICKET_STATUS_CE and qjts.QRM_JOB_TICKET_STATUS_TY = '60'";
        l3.orderByTX = "AuthorizationDT";
        l3.ticketLayout.showSales = false;
        var l4: any = this.stdPolcaListDefinition;
        l4.title = "Finished";
        l4.whereTX = "exists (select 1 from QRM_JOB_TICKET_STATUS qjts where qjts.QRM_JOB_TICKET_STATUS_CE = QRM_JOB_TICKET.QRM_JOB_TICKET_STATUS_CE and qjts.QRM_JOB_TICKET_STATUS_TY = '65'";
        l4.orderByTX = "AuthorizationDT";
        l4.ticketLayout.showResources = false;
        l4.ticketLayout.showTexts = false;
        l4.ticketLayout.showProduct = false;
        l4.ticketLayout.showFeedback = false;
        var l5: any = this.stdPolcaListDefinition;
        l5.title = "Closed";
        l5.whereTX = "exists (select 1 from QRM_JOB_TICKET_STATUS qjts where qjts.QRM_JOB_TICKET_STATUS_CE = QRM_JOB_TICKET.QRM_JOB_TICKET_STATUS_CE and qjts.QRM_JOB_TICKET_STATUS_TY = '70'";
        l5.orderByTX = "AuthorizationDT";
        l5.ticketLayout.showResources = false;
        l5.ticketLayout.showTexts = false;
        l5.ticketLayout.showProduct = false;
        l5.ticketLayout.showFeedback = false;
        return {
            nrLists: 2,
            lists: [l0, l1, l2, l3, l4, l5]
        };
    }

    public get stdPolcaListDefinition(): any {
        return {
            whereTX: "",
            orderByTX: "",
            title: "",
            maxItems: 20,
            ticketLayout: this.stdPolcaTicketLayout,
        };
    }

    public get stdPolcaTicketLayout(): any {
        return {
            iconPolcCards: "check",
            iconInOperation: "settings_suggest",
            iconDone: "sports_score",
            headerHeight: 35,
            showResources: true,
            showNotes: true,
            showTouch: true,
            showProduct: true,
            showSales: true,
            showTexts: true,
            showFeedback: true,
            product: 12,
            dates: 21,
            feedback: 31,
            resources: 41,
            texts: 51,
            touch: 61,
            notes: 71,
            sales: 81,
        };
    }

    public listItemLayout(): object {
        return {
            label: {
                property: "ftmsLB",
            },
            inBrackets: {
                property: "tracingDT",
            },
            key: {
                property: "ftmsCE",
            },
            title: {
                property: "ftmsDS",
            },
            description: {
                property: "tracingDT",
            },
            leftBottomList: [],
            rightBottomList: [],
        };
    }
}
