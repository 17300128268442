import { Name } from "app/core/decorators";
import { IBaseEntity } from "app/core/interfaces/IBaseEntity";
import { TracingBase } from "..";

/** Calendars : Working Days */
@Name("CalendarWorkday")
export class CalendarWorkDay extends TracingBase implements IBaseEntity {
    name = "CalendarWorkday";

    public get id(): string {
        if (!this.calendarCE || !this.workingDayDT) return null;
        return `${this.calendarCE},${this.workingDayDT}`;
    }
    public errorMessage;
    /** Calendar Code                       PK */
    public calendarCE: string;
    /** Working Day Date                    PK */
    public workingDayDT: Date;
    /** Working Day Sequence Number */
    public workingDaySQ: number;
}
