import { CalendarTrans } from "app/core/models/entity-models/calendarTrans";
import { Name } from "app/core/decorators";
import { IBaseEntity } from "app/core/interfaces/IBaseEntity";
import { CalendarNonWorkDay } from "app/core/models/entity-models/calendarNonWorkDay";
import { CalendarWeekendWorkDay } from "app/core/models/entity-models/calendarWeekendWorkDay";
import { CalendarWorkDay } from "app/core/models/entity-models/calendarWorkDay";
import { CalendarWorkShiftFull } from "app/core/models/entity-models/calendarWorkShiftFull";
import { CalendarWorkShiftWorkDay } from ".";

/** Calendars */
@Name("Calendar")
export class Calendar extends CalendarTrans implements IBaseEntity {
    name = "Calendar";
    baseEditRouteSegment = "calendar";
    workShiftWorkDays: CalendarWorkShiftWorkDay[];
    public get id(): string {
        return this.calendarCE;
    }

    /** <calendarCE> <calendarLB> */
    public get caption(): string {
        return `${this.calendarCE} ${this.calendarLB}`;
    }

    public errorMessage: string;

    public nonWorkDays: Array<CalendarNonWorkDay>;
    public weekendWorkDays: Array<CalendarWeekendWorkDay>;
    public workDays: Array<CalendarWorkDay>;
    public workShifts: Array<CalendarWorkShiftFull>;
    subject: any;
    workingDayDT: any;

    public listItemLayout(): object {
        return {
            label: {
                property: "tracingDT",
            },
            inBrackets: {
                property: "tracingDT",
            },
            key: {
                property: "calendarCE",
            },
            title: {
                property: "calendarLB",
            },
            description: {
                property: "tracingDT",
            },
            leftBottomList: [],
            rightBottomList: [],
        };
    }
}
