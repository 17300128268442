import { CalendarWorkShiftException } from "app/core/models/entity-models/calendarWorkShiftException";
import { CalendarWorkShift } from "app/core/models/entity-models/calendarWorkShift";
import { Name } from "app/core/decorators";

/** Calendars : Work Shifts : Exceptions Full */
@Name("CalendarWorkShiftException")
export class CalendarWorkShiftExceptionFull extends CalendarWorkShiftException {
    name = "CalendarWorkShiftException";
    /** Calendar Work Shift */
    public calendarWorkShift: CalendarWorkShift;
}
