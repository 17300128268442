import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RemoveAriaOwnsDirective } from './mat-select-fix.directive';

@NgModule({
    declarations: [RemoveAriaOwnsDirective],
  imports: [
    
    ],
    exports: [RemoveAriaOwnsDirective]
})
export class RemoveAriaOwnsModule { }
