import "reflect-metadata";

const CURRENT_COMPONENT_TARGET = new Object("component_target");

/**
 * Set the name of the model or of the attribute.
 * @param name
 */
export function Name(name: string) { return Reflect.metadata("design:name", name); }

/**
 * Set the type of the entity.
 * @param entityType
 */
export function EntityType<T extends { new(...args: any[]): any; prototype: any; }>(entityType: T) {
    Reflect.defineMetadata("design:entityType", entityType, CURRENT_COMPONENT_TARGET);
    return (CURRENT_COMPONENT_TARGET: any) => Reflect.defineMetadata("design:entityType", entityType, CURRENT_COMPONENT_TARGET);
}

/**
 * Get the local name of the target model or target model attribute.
 * @param target
 * @param attribute
 */
export function getName<T extends { new(...args: any[]): any, prototype: any }>(target: T, attribute?: string): string {
    return attribute
        ? Reflect.getMetadata("design:name", target, attribute)
        : Reflect.getMetadata("design:name", target);
}

/**
 * Get the entity type of the object.
 * @param target
 * @param attribute
 */
export function getEntityType<T extends { new(...args: any[]): any, prototype: any }>(target: any): T {
    return Reflect.getMetadata("design:entityType", CURRENT_COMPONENT_TARGET);
}