import { TransTracingBase } from "app/core/models";
import { Name } from "app/core/decorators";

/** Organizations - Translations */
@Name("OrganizationTrans")
export class OrganizationTrans extends TransTracingBase {
    name = "OrganizationTrans";
    /** Organization Code                               PK */
    public organizationCE: string;
    /** Organization Label */
    public organizationLB: string;
    /** Organization Description */
    public organizationDS: string;
    /** Organization Info (Hyperlink) */
    public organizationUR: string;
    /** Organization Info (Hypertext) */
    public organizationHT: string;
}
