import { TracingBase } from "app/core/models";
import { Name } from "app/core/decorators";

/** QRM Cells - Translations */
@Name("QrmCellJobTicketCluster")
export class QrmCellJobTicketCluster
    extends TracingBase {
    name = "QrmCellJobTicketCluster";
    /** QRM Cell Code                                PK */
    public qrmCellCE: string;
    public isCluster: boolean = true;
    /** QRM Cell Label */
    public qrmCellJobTicketClusterID: string;
    /** QRM Cell Description */
    public qrmCellJobTicketClusterLB: string;
    /** QRM Cell Info (Hyperlink) */
    public qrmJobTickets: any[] = [];
    selected: boolean = false;
    public get startable(): boolean {
        let rt = true;
        this.qrmJobTickets.filter(_ => _.qrmJobTicketQueueTY != 'READY' || _.isInStatusChange).forEach(_ => {
            rt = false;
        });
        return rt;
    }

    public get stopable(): boolean {
        let rt = true;
        this.qrmJobTickets.filter(_ => _.qrmJobTicketQueueTY != 'BUSY' || _.isInStatusChange).forEach(_ => {
            rt = false;
        });
        return rt;
    }
    public get finishable(): boolean {
        let rt = true;
        this.qrmJobTickets.filter(_ => _.qrmJobTicketStatusCE != '70' || _.isInStatusChange).forEach(_ => {
            rt = false;
        });
        return rt;
    }
}
