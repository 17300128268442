import { Name } from "app/core/decorators";
import { QrmProductFamily } from "app/core/models/entity-models/qrmProductFamily";
import { QrmProduct } from "app/core/models/entity-models/qrmProduct";
import { QrmProductBomFull } from "app/core/models/entity-models/qrmProductBomFull";
import { UnitOfMeasure } from "app/core/models/entity-models/unitOfMeasure";
import { QrmJobTicket } from "app/core/models/entity-models/qrmJobTicket";

/** QRM Products */
@Name("QrmProduct")
export class QrmProductFull extends QrmProduct {
    name = "QrmProduct";
    /** QRM Product Family */
    public qrmProductFamily: QrmProductFamily;

    /** Stocking Unit */
    public stkUnitOfMeasure: UnitOfMeasure;

    /** Lead Time Unit */
    public ltmUnitOfMeasure: UnitOfMeasure;

    /** QRM Job Ticket for As-Is MCT-Map */
    public aisQrmJobTicket: QrmJobTicket;

    /** QRM Job Ticket for To-Be MCT-Map */
    public tbeQrmJobTicket: QrmJobTicket;

    /** Unit of Packing */
    public pccUnitOfMeasure: UnitOfMeasure;

    /** Unit of Length */
    public lgcUnitOfMeasure: UnitOfMeasure;

    /** Unit of Area */
    public arcUnitOfMeasure: UnitOfMeasure;

    /** Unit of Volume */
    public vlcUnitOfMeasure: UnitOfMeasure;

    /** Unit of Weight */
    public wgcUnitOfMeasure: UnitOfMeasure;

    /** Bill of Materials List */
    public boms: Array<QrmProductBomFull>;
}
