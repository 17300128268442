import { Injectable } from "@angular/core";
import { Observable, of, throwError } from "rxjs";
import { TranslateService } from "app/core/lib/translate/public_api";
import PerfectScrollbar from "perfect-scrollbar";
import { takeUntil, filter, switchMap } from "rxjs/operators";
import { environment } from "environments/environment";
import { Unsubscriber } from "app/core/component-base/models";
import { AuthenticationService } from "app/core/authentication/authentication.service";
import { MatLegacySnackBarConfig as MatSnackBarConfig, MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { LazyLoaderService } from "app/layout/lazy-loading.service";

@Injectable({
    providedIn: "root",
})
export class ErrorHandlerService extends Unsubscriber {
    //#region private properties

    public missingTranslations: boolean;

    private action: string;
    private errorMessage: string;
    private errorMessageDetails: string;
    private lastTranslationLanguage: string;
    private snackBarContentContainer: any;

    private readonly snackBarConfig: MatSnackBarConfig;
    private readonly errorIdentifier = environment.errorIdentifier;
    private readonly fatalErrorIdentifier = environment.fatalErrorIdentifier;

    //#endregion

    constructor(
        private readonly matSnackBar: MatSnackBar,

        public translateService: TranslateService,
        private readonly authService: AuthenticationService,
        public dialog: MatDialog,
        public lazyLoader: LazyLoaderService
    ) {
        super();

        this.snackBarConfig = new MatSnackBarConfig();
        this.snackBarConfig.panelClass = "error-message-box";

        //this.translateService.onLangChange
        //    .pipe(takeUntil(this.isUnsubscribing))
        //    .subscribe(() => {
        //        if (!this.errorMessage) return;
        //        if (!this.matSnackBar) return;
        //        //this.closeShackBar();
        //        this.openSnackBar();
        //    });

        this.translateService.onTranslationChange
            .pipe(
                takeUntil(this.isUnsubscribing),
                filter((trans) => {
                    const prevLang = this.lastTranslationLanguage;
                    this.lastTranslationLanguage = trans.lang;
                    return trans.lang !== prevLang;
                })
            )
            .subscribe(() => {
                if (!this.errorMessage) return;
                if (!this.matSnackBar) return;
                if (!this.snackBarContentContainer) return;
                //this.closeShackBar();
                //this.openSnackBar();
            });
    }

    public error(error: Response | any) {
        this.missingTranslations = false;

        // ReSharper disable once UnusedLocals
        const readBlobError = async (blob: Blob) => {
            const temporaryFileReader = new FileReader();

            return await new Promise((resolve, reject) => {
                temporaryFileReader.onerror = () => {
                    temporaryFileReader.abort();
                    reject();
                };

                temporaryFileReader.onload = () => {
                    resolve(new Error(temporaryFileReader.result.toString()));
                };
                temporaryFileReader.readAsText(blob);
            });
        };

        let errMsg: string;
        let errMsgDetails = "";
        const contentType = error.headers
            ? error.headers.get("Content-Type")
            : undefined;

        if (error.status === 0) {
            errMsg = "DIALOG.API_ERR";
            errMsgDetails = error.error.target.__zone_symbol__xhrURL;
        } else if (error.name === "TimeoutError") {
            errMsg = "DIALOG.API_ERR";
            errMsgDetails = `${environment.httpRequestTimeout.toString()} ms`;
        } else if (error instanceof Response) {
            const body = (error.json() as any) || "";
            const err = body.error || JSON.stringify(body);
            errMsg = `${error.status} - ${error.statusText || ""} ${err}`;
        } else if (
            contentType &&
            contentType.indexOf("application/json") !== -1 &&
            error.error instanceof Blob
        ) {
            errMsg = "Error message";
        } else {
            if (!error) {
                errMsg = "";
            } else if (
                error.error &&
                (error.error.errMsg || error.error.ErrMsg)
            ) {
                errMsg = error.error.errMsg || error.error.ErrMsg;
            } else if (error.error && error.error.exceptionMessage) {
                errMsg = error.error.exceptionMessage;
            } else if (error.error && error.error.message) {
                errMsg = error.error.message;
            } else if (error.statusText) {
                errMsg = error.statusText;
            } else if (error.message) {
                errMsg = error.message;
            } else {
                errMsg = error.toString();
            }

            if (error.status === 401 && this.authService.currentUser) {
                this.authService.logout(true);
            }
        }

        return this.openSnackBar(errMsg, errMsgDetails, error).pipe(
            switchMap((r) => {
                return throwError(() => new Error(errMsg));
            })
        );
    }

    public closeShackBar() {
        if (this.matSnackBar) {
            this.matSnackBar.dismiss();
            this.errorMessage = "";
            this.errorMessageDetails = "";
        }
    }

    //#region Private methods

    private render(message: string): string {
        message = message.toHTML();

        const jsonText = message.lastJSONSubstring();
        const renderedJsonText = jsonText ? jsonText.toJSONLike() : "";
        message = message.replace(jsonText, renderedJsonText);

        const renderedText = message.endsWith("[")
            ? message.slice(0, -1)
            : message;

        return renderedText;
    }

    private openSnackBar(
        title?: string,
        titleDetails?: string,
        error?: any
    ): Observable<any> {
        this.errorMessage = title ? title : this.errorMessage;
        this.errorMessageDetails = titleDetails
            ? titleDetails
            : this.errorMessageDetails;

        const dialogRef = this.lazyLoader.openPopup({
            title: "ERROR",
            text: title,
            showConfirmBtn: false,
            error: error
        }, { width: "auto", height: "auto", panelClass: "min-height-unset" })

        //dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        //    data: {
        //        titleCE: "ERROR",
        //        textCE: title,
        //        showCloseButton: true,
        //        hideConfirmBtn: true,
        //        error: error,
        //    },
        //});

        return dialogRef;
    }

    //#endregion
}
