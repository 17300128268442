import { Name } from "app/core/decorators";
import { TransTracingBase } from "..";
import { IProjectText } from "./interfaces";

/** Project Texts */
@Name("DP3ProjectText")
export class ProjectText extends TransTracingBase implements IProjectText {
    name = "DP3ProjectText";
    /** Project Code */
    public projectCE: string;
    /** Code of the Owner of the Text */
    public ownerCE: string;
    /** Text Code */
    public textCE: string;
    /** Text Type */
    public textTY: string;
    /** Text */
    public textTX: string;
}
