import { IProject } from "./interfaces";
import { Name } from "app/core/decorators";

@Name("DP3Project")
export class Project implements IProject {
    name = "DP3Project";
    public projectCE: string;
    public projectID: string;
    public languageEntityCE: string;
}
