import { QrmCapacityMonitor } from "app/core/models/entity-models/qrmCapacityMonitor";
import { FocusedTrgMarketSgm } from "app/core/models/entity-models/focusedTrgMarketSgm";
import { QrmCell } from "app/core/models/entity-models/qrmCell";
import { CapacityCluster } from "app/core/models/entity-models/capacityCluster";
import { QrmProductFamily } from "app/core/models/entity-models/qrmProductFamily";
import { QrmProduct } from "app/core/models/entity-models/qrmProduct";
import { QrmJobTicket } from "app/core/models/entity-models/qrmJobTicket";
import { Name } from "app/core/decorators";
import { BaseModel } from "..";

/** QRM Capacity Monitor Full */
@Name("QrmCapacityMonitor")
export class QrmCapacityMonitorFull extends QrmCapacityMonitor {
    name = "QrmCapacityMonitor";
    /** Focused Target Market Segment */
    public focusedTrgMarketSgm: FocusedTrgMarketSgm;
    /** QRM Cell */
    public qrmCell: QrmCell;
    /** Cell Capacity Cluster */
    public capacityCluster: CapacityCluster;
    /** QRM Product Family */
    public qrmProductFamily: QrmProductFamily;
    /** QRM Product */
    public qrmProduct: QrmProduct;
    /** QRM Job Ticket */
    public plQrmJobTicket: QrmJobTicket;
    /** Master QRM Job Ticket */
    public mstQrmJobTicket: QrmJobTicket;
    qrmJobTicketStatusCE: boolean;
    children: any[];

    public mapRelatedTypes() {
        if (this.plQrmJobTicket) {
            this.plQrmJobTicket = BaseModel.changeType(
                this.plQrmJobTicket,
                QrmJobTicket
            ) as any;
        }
        if (this.qrmProduct) {
            this.qrmProduct = BaseModel.changeType(
                this.qrmProduct,
                QrmProduct
            ) as any;
        }
        if (this.qrmProductFamily) {
            this.qrmProductFamily = BaseModel.changeType(
                this.qrmProductFamily,
                QrmProductFamily
            ) as any;
        }
        if (this.capacityCluster) {
            this.capacityCluster = BaseModel.changeType(
                this.capacityCluster,
                CapacityCluster
            ) as any;
        }
        if (this.qrmCell) {
            this.qrmCell = BaseModel.changeType(this.qrmCell, QrmCell) as any;
        }
        if (this.focusedTrgMarketSgm) {
            this.focusedTrgMarketSgm = BaseModel.changeType(
                this.focusedTrgMarketSgm,
                FocusedTrgMarketSgm
            ) as any;
        }
    }

    expanded: boolean;
}
