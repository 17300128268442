import { TracingBase } from "app/core/models";
import { Name } from "app/core/decorators";
import { IBaseEntity } from "app/core/interfaces/IBaseEntity";

/** QRM Job Tickets : Activities */
@Name("jobTicketResourceGroup")
export class jobTicketResourceGroup extends TracingBase implements IBaseEntity {
    name = "jobTicketResourceGroup";
    /** QRM Job Ticket Number                                           PK */
    public qrmJobTicketNO: string;
    public ResourceGroupCE: string;
    public resourceID: string;
    /** QRM Job Ticket Activity Label */
    public resourceItemID: string;
    /** QRM Job Ticket Activity Description */

}
