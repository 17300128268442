import { Name } from "app/core/decorators";
import { IBaseEntity } from "app/core/interfaces/IBaseEntity";
import { BaseModel } from "app/core/models";

/** QRM Cells */
@Name("QrmCellRoutingCapacity")
export class QrmCellRoutingCapacity extends BaseModel implements IBaseEntity {
    name = "QrmCellRoutingCapacity";
    get id(): string {
        if (!this.qrmCellCE || !this.pairedQrmCellCE) return null;
        return `${this.qrmCellCE},${this.pairedQrmCellCE}`;
    }
    errorMessage: string;

    /** QRM Cell Code                                 PK */
    public qrmCellCE: string;
    /** QRM Cell Code                                 PK */
    public pairedQrmCellCE: string;
    /**Total Number of Routing Capacity (Polca) Cards */
    public totalRoutCapacityCardNB: number;
    /** Number of Routing Capacity (Polca) Cards currently in Use */
    public inUseRoutCapacityCardNB: number;
    /** Number of Routing Capacity (Polca) Cards currently Free to Use */
    public freeRoutCapacityCardNB: number;

    public listItemLayout(): object {
        return {
            label: { property: "tracingDT" },
            inBrackets: { property: "tracingDT" },
            key: { property: "qrmCellCE" },
            title: { property: "qrmCellCE" },
            description: { property: "tracingDT" },
            leftBottomList: [],
            rightBottomList: [
                { property: "inUseRoutCapacityCardNB" },
                { toSlice: 200, property: "freeRoutCapacityCardNB" },
            ],
        };
    }
}
