import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { FusePipesModule } from "app/core/@fuse/pipes/pipes.module";

import { FuseMaterialColorPickerComponent } from "app/core/@fuse/components/material-color-picker/material-color-picker.component";
import { MatLegacyButtonModule as MatButtonModule } from "@angular/material/legacy-button";
import { MatIconModule } from "@angular/material/icon";
import { MatLegacyMenuModule as MatMenuModule } from "@angular/material/legacy-menu";
import { MatLegacyTooltipModule as MatTooltipModule } from "@angular/material/legacy-tooltip";

@NgModule({
    declarations: [FuseMaterialColorPickerComponent],
    imports: [
        CommonModule,

        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        MatTooltipModule,

        FusePipesModule,
    ],
    exports: [FuseMaterialColorPickerComponent],
})
export class FuseMaterialColorPickerModule {}
