import { TransTracingBase } from "app/core/models";
import { Name } from "app/core/decorators";

/** QRM Products - Translations */
@Name("QrmProductTrans")
export class QrmProductTrans extends TransTracingBase {
    name = "QrmProductTrans";
    /** QRM Product Code                                   PK */
    public qrmProductCE: string;
    /** QRM Product Label */
    public qrmProductLB: string;
    /** QRM Product Description */
    public qrmProductDS: string;
    /** QRM Product Info (Hyperlink) */
    public qrmProductUR: string;
    /** QRM Product Info (Hypertext) */
    public qrmProductHT: string;
    /** QRM Product Info ((Preview of the Hypertext)       Virtual attribute */
    public qrmProductPH: string;
}
