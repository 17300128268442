import { TransTracingBase } from "app/core/models";
import { Name } from "app/core/decorators";

/** Projects - Translations */
@Name("ProjectTrans")
export class ProjectTrans extends TransTracingBase {
    name = "ProjectTrans";
    /** Project Number                 PK */
    public projectNO: string;
    /** Project Label */
    public projectLB: string;
    /** Project Description */
    public projectDS: string;
    /** Project Info (Hyperlink) */
    public projectUR: string;
    /** Project Info (Hypertext) */
    public projectHT: string;
}
