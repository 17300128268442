import { Name } from "app/core/decorators";
import { TracingBase } from "app/core/models/tracingBase";
import { IStatusEntity } from "app/core/interfaces/IStatusEntity";

/** Sales Orders */
@Name("SalesOrder")
export class SalesOrder extends TracingBase implements IStatusEntity {
    name = "SalesOrder";
    salesOrderStatusCE: string;
    fwdSalesOrderStatusCE: string;
    revSalesOrderStatusCE: string;
    salesOrderStatusDS: string;

    public salesOrderStatusLB: string;
    public get statusCE(): string {
        return this.salesOrderStatusCE;
    }
    public set statusCE(value) {
        this.salesOrderStatusCE = value;
    }

    public get fwStatusCE(): string {
        return this.fwdSalesOrderStatusCE;
    }
    public set fwStatusCE(value) {
        this.fwdSalesOrderStatusCE = value;
    }
    public get revStatusCE(): string {
        return this.revSalesOrderStatusCE;
    }
    public set revStatusCE(value) {
        this.revSalesOrderStatusCE = value;
    }
    public get statusDS(): string {
        return this.salesOrderStatusDS;
    }
    public set statusDS(value) {
        this.salesOrderStatusDS = value;
    }
    public get statusLB(): string {
        return this.salesOrderStatusLB;
    }
    public set statusLB(value) {
        this.salesOrderStatusLB = value;
    }
    public get id(): string {
        return this.salesOrderNO;
    }
    baseEditRouteSegment = "sales-order";
    public errorMessage: string;
    /** Sales Order Number */
    public salesOrderNO: string;
    /** Sales Order  Date */
    public salesOrderDT: Date;
    /** Customer Number */
    public customerNO: string;
    /** Customer Name */
    public customerNM: string;
    /** Address Label Line 1 */
    public addressLine1LB: string;
    /** Address Label Line 2 */
    public addressLine2LB: string;
    /** Address Label Line 3 */
    public addressLine3LB: string;
    /** Address Label Line 4 */
    public addressLine4LB: string;
    /** Address Label Line 5 */
    public addressLine5LB: string;
    /** Zip Code Reference */
    public zipCodeRF: string;
    /** City Name */
    public cityNM: string;
    /** General Phone Number */
    public generalPhoneNO: string;
    /** General E-Mail Reference */
    public generalEmailRF: string;
    /** Default Language */
    public languageCE: string;

    public listItemLayout(): object {
        return {
            label: { property: "tracingDT" },
            inBrackets: { property: "tracingDT" },
            key: { property: "salesOrderNO" },
            title: { property: "tracingDT" },
            description: { property: "tracingDT" },
            leftBottomList: [],
            rightBottomList: [
                { property: "customerNM" },
                { toSlice: 200, property: "salesOrderDT" },
            ],
        };
    }
}
