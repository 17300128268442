import { TracingBase } from "..";
import { Name } from "app/core/decorators";
import { IEntityAttribute } from "./interfaces";
import { Observable } from "rxjs";
import { PossibleValueSetValue } from ".";

@Name("DP3Attribute")
export class EntityAttribute extends TracingBase implements IEntityAttribute {
    name = "DP3Attribute";
    min: number;
    max: number;
    possibleValueSetValues: {};
    getPossibleValueSetValues: Observable<{}>;
    public projectCE: string;
    public entityCE: string;
    public attributeCE: string;
    public attributeDS: string;
    public attributeNM: string;
    public attributeLongPromptTX: string;
    public attributeID: string;
    public attributeMediumPromptTX: string;
    public attributeTY: string;
    public activeFL: string;
    public attributeShortPromptTX: string;
    public attributeHT: string;
    public virtualAttributeFL: string;
    public attributePH: string;
    public sequenceNO: number;
    public nullFL: string;
    public labelAttributeFL: string;
    public primaryKeyAttributeFL: string;
    public attributeEntityDfltValueFL: string;
    public attributeForceDfltValueFL: string;
    public dataTypeCE: string;
    public caseSensitiveSearchFL: string;
    public lengthOrPrecision: number;
    public scale: string;
    public attributeDefinitionString: string;
    public caseTY: string;
    public defaultVL: string;
    public initialVL: string;
    public lowVL: number;
    public highVL: number;
    public rangeCheckExpressionTX: string;
    public systemDefaultsAttributeCE: string;
    public groupAttributeCE: string;
    public previewHypertextAttributeCE: string;
    public standardAttributeCE: string;
    public sequenceGeneratorCE: string;
    public virtualAttributeRuleCE: string;
    public entityPackageAttributeID: string;
    public possibleValueSetCE: string;

    public attributePromptTX: string;

    public get inputFieldType(): string {
        switch (this.dataType) {
            case "number":
                return "number";
            default:
                return "text";
        }
    }

    public get required(): boolean {
        return this.nullFL.localeCompare("N") === 0;
    }

    public get dataType(): string {
        if (this.attributeDefinitionString) {
            const defString = this.attributeDefinitionString.startsWith(
                "number"
            )
                ? "number"
                : this.attributeDefinitionString;

            switch (defString) {
                case "date":
                    return "Date";
                case "integer":
                case "number":
                case "decimal":
                    return "number";
                default:
                    return "string";
            }
        } else {
            switch (this.dataTypeCE) {
                case "DATE":
                    return "Date";
                case "NUMBER":
                case "INTEGER":
                    return "number";
                default:
                    return "string";
            }
        }
    }
}
