import { QrmJobTicketConnection } from "app/core/models/entity-models/qrmJobTicketConnection";
import { QrmJobTicketFull } from "app/core/models/entity-models/qrmJobTicketFull";
import { Name } from "app/core/decorators";

/** QRM Job Tickets : Connections Full */
@Name("QrmJobTicketConnection")
export class QrmJobTicketConnectionFull extends QrmJobTicketConnection {
    name = "QrmJobTicketConnection";
    /** Downstream QRM Job Ticket */
    public dosQrmJobTicket: QrmJobTicketFull;
    /** Upstream QRM Job Ticket */
    public upsQrmJobTicket: QrmJobTicketFull;
}
