import { UnitOfMeasureConversion } from "app/core/models/entity-models/unitOfMeasureConversion";
import { UnitOfMeasure } from "app/core/models/entity-models/unitOfMeasure";
import { Name } from "app/core/decorators";

/** Units of Measure : Conversions Full */
@Name("UnitOfMeasureConversion")
export class UnitOfMeasureConversionFull extends UnitOfMeasureConversion {
    name = "UnitOfMeasureConversion";
    /** Primary Unit */
    public priUnitOfMeasure: UnitOfMeasure;
    /** Secondary Unit */
    public secUnitOfMeasure: UnitOfMeasure;
}
