import { TransTracingBase } from "app/core/models";
import { Name } from "app/core/decorators";

/** Languages - Translations */
@Name("LanguageTrans")
export class LanguageTrans extends TransTracingBase {
    name = "LanguageTrans";
    /** To Language Code   Trans PK */
    public toLanguageCE: string;
    /** Language Label */
    public languageLB: string;
    /** Language Description */
    public languageDS: string;
    /** Language Info */
    public languageTX: string;
}
