<ng-container *ngIf="!item.hidden">

    <!-- item.url -->
    <a class="nav-link" [ngClass]="item.classes" *ngIf="item.url && !item.externalUrl && !item.function && !item.background && !item.foreground"
       [routerLink]="[item.url]" [routerLinkActive]="['active', 'accent']"
       [queryParams]="item.queryParams"
       [routerLinkActiveOptions]="{exact: item.exactMatch || false}"
       [target]="item.openInNewTab ? '_blank' : '_self'">
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>

    <a class="nav-link" [ngClass]="item.classes" *ngIf="item.url && !item.externalUrl && !item.function && item.background && item.foreground"
       [routerLink]="[item.url]"
       [queryParams]="item.queryParams"
       [routerLinkActiveOptions]="{exact: item.exactMatch || false}"
       [target]="item.openInNewTab ? '_blank' : '_self'"
       routerLinkActive
       #rla="routerLinkActive"
       [ngStyle]="{'background-color': rla.isActive || item.id === selectedId ? item.background : '',
                   'color': rla.isActive || item.id === selectedId ? item.foreground : ''}"
       (mouseenter)="onMouseEnter($event, item)" (mouseleave)="onMouseLeave($event, item)">
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>

    <!-- item.externalUrl -->
    <a class="nav-link" [ngClass]="item.classes" *ngIf="item.url && item.externalUrl && !item.function"
       [href]="item.url" [target]="item.openInNewTab ? '_blank' : '_self'">
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>

    <!-- item.function -->
    <span class="nav-link" [ngClass]="item.classes" *ngIf="!item.url && item.function"
          (click)="item.function()">
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </span>

    <!-- item.url && item.function -->
    <a class="nav-link" [ngClass]="item.classes" *ngIf="item.url && !item.externalUrl && item.function"
       (click)="item.function()"
       [routerLink]="[item.url]" [routerLinkActive]="['active', 'accent']"
       [routerLinkActiveOptions]="{exact: item.exactMatch || false}"
       [target]="item.openInNewTab ? '_blank' : '_self'">
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>

    <!-- item.externalUrl && item.function -->
    <a class="nav-link" [ngClass]="item.classes" *ngIf="item.url && item.externalUrl && item.function"
       (click)="item.function()"
       [href]="item.url" [target]="item.openInNewTab ? '_blank' : '_self'">
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>

    <ng-template #itemContent>
        <mat-icon class="nav-link-icon" *ngIf="item.icon"><span class="nav-link-icon mat-tooltip-trigger material-icons-outlined">{{item.icon}}</span></mat-icon>
        <!--<mat-icon *ngIf="item.icon"
                  class="nav-link-icon"
                  [ngStyle]="{'color': item.id !== hoveredId ? '' : (item.id === selectedId ? item.foreground : item.background)}"
                  matTooltip="{{item.title | translate}}">
            {{item.icon}}
        </mat-icon>-->
        <span class="nav-link-title">
            {{item.title | translate}}
        </span>
        <span *ngIf="item.badge"
              class="nav-link-badge"
              [translate]="item.badge.translate"
              [ngStyle]="{'background-color': item.badge.bg,'color': item.badge.fg}">
            {{item.badge.title}}
        </span>
    </ng-template>

</ng-container>