import { Name } from "app/core/decorators";
import { TracingBase } from "app/core/models";
import { IBaseEntity } from "app/core/interfaces/IBaseEntity";
import { SalesOrderLine } from ".";

/** QRM Job Ticket : Sales Order Lines */
@Name("QrmJbtSalesOrderLine")
export class QrmJbtSalesOrderLine extends TracingBase implements IBaseEntity {
    name = "QrmJbtSalesOrderLine";
    public get id(): string {
        if (
            !this.qrmJobTicketNO ||
            !this.salesOrderNO ||
            !this.salesOrderLineSQ
        )
            return null;
        return `${this.qrmJobTicketNO},${this.salesOrderNO},${this.salesOrderLineSQ}`;
    }
    public errorMessage: string;

    /** QRM Job Ticket Number               PK */
    public qrmJobTicketNO: string;
    /** Sales Order Number                  PK */
    public salesOrderNO: string;
    /** Sales Order Line Sequence Number    PK */
    public salesOrderLineSQ: string;

    public salesOrderLine: SalesOrderLine;
}
