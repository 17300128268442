import { Name } from "app/core/decorators";
import { ProjectPartTrans } from "app/core/models/entity-models/projectPartTrans";
import { IBaseEntity } from "app/core/interfaces/IBaseEntity";

/** Project Parts */
@Name("ProjectPart")
export class ProjectPart extends ProjectPartTrans implements IBaseEntity {
    name = "ProjectPart";
    public get id(): string {
        if (!this.projectNO || !this.projectPartCE) return null;
        return `${this.projectNO},${this.projectPartCE}`;
    }

    public errorMessage: string;
    qrmProductDS: string;
    qrmProductLB: string;
}
