import { TracingBase } from "app/core/models";
import { Name } from "app/core/decorators";
import { IBaseEntity } from "app/core/interfaces/IBaseEntity";

/** QRM Products : Bills of Material : Parts */
@Name("QrmProductBomPart")
export class QrmProductBomPart extends TracingBase implements IBaseEntity {
    name = "QrmProductBomPart";
    baseEditRouteSegment = "qrm-product-bom-part";
    public get id(): string {
        if (
            !this.qrmProductCE ||
            !this.qrmProductBomSQ ||
            !this.qrmProductBomPartSQ
        )
            return null;
        return `${this.qrmProductCE},${this.qrmProductBomSQ},${this.qrmProductBomPartSQ}`;
    }
    public errorMessage: string;

    /** QRM Product Code                                                PK */
    public qrmProductCE: string;
    /** QRM Product Bill of Material Sequence Number                    PK */
    public qrmProductBomSQ: number;
    /** QRM Product Bill of Material Part Sequence Number               PK */
    public qrmProductBomPartSQ: number;
    /** Is this a phantom part? */
    public phantomPartFL: string;
    /** Must the Part be Backflushed at Completion of the Work Order? */
    public backflushFL: string;
    /** Must the Part be Included as Required Material in a Work Order? */
    public requiredMaterialIncludeFL: string;
    /** Part QRM Product Code */
    public partQrmProductCE: string;
    /** Part QRM Product Bill of Material Sequence Number */
    public partQrmProductBomSQ: number;
    /** Part Quantity per Parent (Batch) */
    public partQT: number;
    /** Unit of Measure Code */
    public partQuantityUnitCE: string;
    /** Multiply With Quantity Type */
    public multiplyWithQuantityTY: string;
    /** Lead Time Offset */
    public leadTimeOffsetNB: number;
    /** Lead Time Offset Unit Code */
    public leadTimeOffsetUnitCE: string;
    /** Expected Scrap Percentage */
    public expectedScrapPC: number;
    /** Expected Yield Percentage */
    public expectedYieldPC: number;

    /** <qrmProductCE> <qrmProductBomSQ> <qrmProductBomPartSQ> */
    public get caption(): string {
        return `${this.qrmProductCE} ${this.qrmProductBomSQ} ${this.qrmProductBomPartSQ}`;
    }

    public get phantomPartFLBoolean(): boolean {
        if (this.phantomPartFL === "Y") {
            return true;
        }
        return false;
    }

    public set phantomPartFLBoolean(value: boolean) {
        if (value) {
            this.phantomPartFL = "Y";
        } else {
            this.phantomPartFL = "N";
        }
    }

    public get backflushFLBoolean(): boolean {
        if (this.backflushFL === "Y") {
            return true;
        }
        return false;
    }

    public set backflushFLBoolean(value: boolean) {
        if (value) {
            this.backflushFL = "Y";
        } else {
            this.backflushFL = "N";
        }
    }

    public get requiredMaterialIncludeFLBoolean(): boolean {
        if (this.requiredMaterialIncludeFL === "Y") {
            return true;
        }
        return false;
    }

    public set requiredMaterialIncludeFLBoolean(value: boolean) {
        if (value) {
            this.requiredMaterialIncludeFL = "Y";
        } else {
            this.requiredMaterialIncludeFL = "N";
        }
    }

    //I use these for the picker bindingProp when setting the filterProp to id due to having composite PKs
    public get partQrmBomId(): string {
        if (!this.partQrmProductBomSQ && !this.partQrmProductCE) return null;
        return `${this.partQrmProductCE},${this.partQrmProductBomSQ}`;
    }

    public set partQrmBomId(value: string) {
        if (String.isNullEmptyWhitespaceOrUndefined(value)) {
            this.partQrmProductCE = null;
            this.partQrmProductBomSQ = null;
        } else {
            const keys = value.split(",");
            this.partQrmProductCE = keys[0];
            this.partQrmProductBomSQ = Number(keys[1]);
        }
    }

    public get parentQrmBomId(): string {
        if (!this.qrmProductCE && !this.qrmProductBomSQ) return null;
        return `${this.qrmProductCE},${this.qrmProductBomSQ}`;
    }

    public set parentQrmBomId(value: string) {
        if (String.isNullEmptyWhitespaceOrUndefined(value)) {
            this.qrmProductCE = null;
            this.qrmProductBomSQ = null;
        } else {
            const keys = value.split(",");
            this.qrmProductCE = keys[0];
            this.qrmProductBomSQ = Number(keys[1]);
        }
    }

    public get partType(): string {
        if (!this.partQrmProductBomSQ) return "Component";
        return "Process";
    }

    public listItemLayout(): object {
        return {
            label: { property: "qrmProductBomLB" },
            inBrackets: { property: "partType" },
            key: { property: "qrmProductBomPartSQ" },
            title: { property: "caption" },
            description: { property: "partQrmBomId" },
            leftBottomList: [
                { property: "partQT" },
                { toSlice: 200, property: "partQuantityUnitCE" },
                { toSlice: 200, property: "multiplyWithQuantityTY" },
            ],
            rightBottomList: [
                { property: "phantomPartFL" },
                { toSlice: 200, property: "backflushFL" },
                { toSlice: 200, property: "requiredMaterialIncludeFL" },
            ],
        };
    }
}
