import { TransTracingBase } from "app/core/models";
import { Name } from "app/core/decorators";

/** Order Policies - Translations */
@Name("OrderPolicyTrans")
export class OrderPolicyTrans extends TransTracingBase {
    name = "OrderPolicyTrans";
    /** Order Policy Code                 PK */
    public orderPolicyCE: string;
    /** Order Policy Label */
    public orderPolicyLB: string;
    /** Order Policy Description */
    public orderPolicyDS: string;
    /** Order Policy Info (Hyperlink) */
    public orderPolicyUR: string;
    /** Order Policy Info (Hypertext) */
    public orderPolicyHT: string;
}
