import { Name } from "app/core/decorators";
import { Product } from "app/core/models/entity-models/product";
import { UnitOfMeasure } from "app/core/models/entity-models/unitOfMeasure";

/** Products */
@Name("Product")
export class ProductFull extends Product {
    name = "Product";

    /** Stocking Unit */
    public stkUnitOfMeasure: UnitOfMeasure;
    /** Lead Time Unit */
    public ltmUnitOfMeasure: UnitOfMeasure;
    /** Unit of Packing */
    public pccUnitOfMeasure: UnitOfMeasure;
    /** Unit of Length */
    public lgcUnitOfMeasure: UnitOfMeasure;
    /** Unit of Area */
    public arcUnitOfMeasure: UnitOfMeasure;
    /** Unit of Volume */
    public vlcUnitOfMeasure: UnitOfMeasure;
    /** Unit of Weight */
    public wgcUnitOfMeasure: UnitOfMeasure;
}
