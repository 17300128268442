import { Injectable } from "@angular/core";
import { MatLegacyPaginatorIntl as MatPaginatorIntl } from "@angular/material/legacy-paginator";
import { TranslateService } from "app/core/lib/translate/public_api";

@Injectable({
    providedIn: "root",
})
export class PaginatorIntlService extends MatPaginatorIntl {
    public translate: TranslateService;

    public getRangeLabel = function (
        page: number,
        pageSize: number,
        length: number
    ) {
        const ofLabel = this.translate.instant("GENERAL.OF");
        if (length === 0 || pageSize === 0) {
            return `0 ${ofLabel} ${length}`;
        }

        length = Math.max(length, 0);
        const startIndex = page * pageSize;
        // If the start index exceeds the list length, do not try and fix the end index to the end.
        const endIndex =
            startIndex < length
                ? Math.min(startIndex + pageSize, length)
                : startIndex + pageSize;
        return startIndex + 1 + " - " + endIndex + " " + ofLabel + " " + length;
    };

    /**
     * Inject a TranslateService instance.
     * @param translate
     */
    public injectTranslateService(translate: TranslateService) {
        this.translate = translate;

        this.translate.onLangChange.subscribe(() => {
            this.translateLabels();
            this.changes.next();
        });

        this.translate.onTranslationChange.subscribe(() => {
            this.translateLabels();
        });

        this.translateLabels();
    }

    /** Translate labels using the injected TranslateService instance. */
    public translateLabels() {
        this.itemsPerPageLabel = this.translate.instant("PAGINATOR.ITEMS");
        this.nextPageLabel = this.translate.instant("PAGINATOR.NEXT");
        this.previousPageLabel = this.translate.instant("PAGINATOR.PREVIOUS");
        this.firstPageLabel = this.translate.instant("PAGINATOR.FIRST");
        this.lastPageLabel = this.translate.instant("PAGINATOR.LAST");
    }
}
