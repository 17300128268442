import { HttpParams, HttpClient } from "@angular/common/http";
import {
    IPaginationPageInfo,
    IPaginationService,
} from "app/core/component-base/interfaces";
import { ErrorHandlerService } from "app/core/services";
import { environment } from "environments/environment";
import { QueryTypeEnum } from "./queryTypeEnum";
import { map, share, switchMap } from "rxjs/operators";
import { IKeyValue } from "app/core/models/interfaces";
import { AppInjector } from "app/app.module";
import {
    EntityDefinition,
    EntityDefinitionResponse,
} from "app/core/models/base-entity";
import { CamelPascalMapper } from "app/core/3rdWaveMappers";
import { getName } from "app/core/decorators";
import { Injectable } from "@angular/core";
import { Organization } from "app/core/models/entity-models";
import { BehaviorSubject, catchError, Observable, of, throwError } from "rxjs";
import {
    ProcedureDefinitionParameter,
    ProcedureDefinitionResponse,
} from "app/core/models/base-entity/procedureDefinitionResponse";

const defaultFilters = environment.capacityMonitorFilter;
@Injectable()
export class DataService implements IPaginationService {
    public onDefinitonChanged: BehaviorSubject<EntityDefinition>;

    public refreshInterval = environment.dataRefreshRate;

    public pageSizeOptions = environment.pageSizeOptions;
    public entitiesLoaded: BehaviorSubject<IPaginationPageInfo>;

    public totalRowCount: number;

    public isLoading = new BehaviorSubject<boolean>(false);
    public isSaving = new BehaviorSubject<boolean>(false);
    public isInserting = new BehaviorSubject<boolean>(false);
    public isUpdating = new BehaviorSubject<boolean>(false);
    public isLogicallyDeleting = new BehaviorSubject<boolean>(false);
    public isLogicallyDeletingByKey = new BehaviorSubject<boolean>(false);
    public isDeleting = new BehaviorSubject<boolean>(false);
    public isDeletingByKey = new BehaviorSubject<boolean>(false);
    public isExecutingProcedure = new BehaviorSubject<boolean>(false);

    filterReady: BehaviorSubject<any>;
    public onCurrentFromValidationChanged: BehaviorSubject<any>;

    private readonly httpClient: HttpClient;
    private readonly errorHandlerService: ErrorHandlerService;
    private readonly camelPascalMapper: CamelPascalMapper;
    public _filter: any;
    userSettings: any = {};

    public getDefaultFilters(): any {
        var filter: any = {};
        for (var attribut in defaultFilters) {
            filter[attribut] = defaultFilters[attribut];
        }
        return filter;
    }
    constructor() {
        // Inject dependencies
        this.httpClient = AppInjector.get(HttpClient);
        this.errorHandlerService = AppInjector.get(ErrorHandlerService);
        this.camelPascalMapper = AppInjector.get(CamelPascalMapper);

        this.entitiesLoaded = new BehaviorSubject(undefined);
        this.isLoading = new BehaviorSubject<boolean>(false);
        this.isSaving = new BehaviorSubject<boolean>(false);
        this.isLogicallyDeleting = new BehaviorSubject<boolean>(false);
        this.isDeleting = new BehaviorSubject<boolean>(false);
        this.onDefinitonChanged = new BehaviorSubject(undefined);
        this.filterReady = new BehaviorSubject<any>(undefined);
        this.metaDataReady = new BehaviorSubject<any>(undefined);
    }
    metaDataReady: BehaviorSubject<any>;
    public getFilters(queryParams?): Observable<any> {
        if (this._filter) {
            if (queryParams) {
                this._filter = this.syncFilterWithQueryParam(
                    this._filter,
                    queryParams
                );
            }
            return of(this._filter);
        }
        return this.queryV2(Organization, {}).pipe(
            switchMap((organizations: Organization[]) => {
                this._filter = this.getDefaultFilters();
                if (
                    organizations.length > 0 &&
                    organizations[0].primaryFtmsCE
                ) {
                    this._filter.defaultHorizon = {};
                    this._filter.ftmsCE = organizations[0].primaryFtmsCE;
                    this._filter.defaultHorizon.day =
                        organizations[0].scheduleHorizonDayNB;
                    this._filter.defaultHorizon.shift =
                        organizations[0].scheduleHorizonShiftNB;
                    this._filter.defaultHorizon.hour =
                        organizations[0].scheduleHorizonHourNB;
                    this._filter.defaultHorizon.week =
                        organizations[0].scheduleHorizonWeekNB;
                    this._filter.defaultHorizon.month =
                        organizations[0].scheduleHorizonMonthNB;
                    this._filter.defaultHorizon.quarter =
                        organizations[0].scheduleHorizonQuarterNB;
                    this._filter.defaultHorizon.processTimeMethodTY =
                        organizations[0].processTimeMethodTY;
                    this._filter.defaultHorizon.processTimeDetailTY =
                        organizations[0].processTimeDetailTY;
                    this._filter.defaultHorizon.capacityCheckTY =
                        organizations[0].capacityCheckTY;
                    this._filter.defaultHorizon.capacityThresholdPC =
                        organizations[0].capacityThresholdPC;
                    this._filter.defaultHorizon.startOffsetTimeQT =
                        organizations[0].startOffsetTimeQT;
                    this._filter.defaultHorizon.startOffsetTimeUnitCE =
                        organizations[0].startOffsetTimeUnitCE;
                }
                if (queryParams) {
                    this._filter = this.syncFilterWithQueryParam(
                        this._filter,
                        queryParams
                    );
                }
                this._filter.horizon = this._filter.defaultHorizon;
                if (
                    ![undefined, null, {}].includes(
                        localStorage.getItem("horizonModel")
                    )
                ) {
                    this._filter.horizon = JSON.parse(
                        localStorage.getItem("horizonModel")
                    );
                }
                this.filterReady.next(this._filter);
                return of(this._filter);
            })
        );
    }
    public setFilters(filter: any) {
        for (var attribut in filter) {
            this._filter[attribut] = filter[attribut];
        }
    }
    private syncFilterWithQueryParam(filter, queryParams) {
        try {
            for (var attribut in queryParams) {
                if (filter[attribut]) {
                    filter[attribut] = queryParams[attribut];
                } else {
                    for (var childFilterAttr in filter) {
                        var fil = filter[childFilterAttr];
                        if (
                            Object.keys(fil).length &&
                            !(fil instanceof Array) &&
                            fil[attribut] != undefined
                        ) {
                            fil[attribut] = queryParams[attribut];
                        }
                    }
                }
            }
            return filter;
        } catch (er) {
            return er;
        }
    }
    /**
     * Make a DEFAULT VALUES request for the given type.
     * @param type
     */
    public defaultValues<
        T extends { new (...args: any[]): any; prototype: any }
    >(type: T): Observable<any> {
        this.isLoading.next(true);
        return this.httpClient
            .get(this.mapModelUrl(type, QueryTypeEnum.DefaultValues))
            .pipe(
                map((_) => {
                    this.isLoading.next(false);
                    return this.camelPascalMapper.map3rdWaveResponse(_, type);
                }),
                share(),

                catchError((error: Response | any) => {
                    this.isLoading.next(false);
                    return this.errorHandlerService.error(error);
                })
            );
    }

    /**
     * Make a DEFINITION OF ENTITY request for the given type.
     * @param type
     */
    public definition<T extends { new (...args: any[]): any; prototype: any }>(
        type: T
    ): Observable<any> {
        this.isLoading.next(true);
        return this.httpClient
            .get(this.mapModelUrl(type, QueryTypeEnum.Definition))
            .pipe(
                map((_) => {
                    this.isLoading.next(false);
                    return this.camelPascalMapper.map3rdWaveResponse(
                        _,
                        EntityDefinition
                    );
                }),
                share(),

                catchError((error: Response | any) => {
                    this.isLoading.next(false);
                    return this.errorHandlerService.error(error);
                })
            );
    }

    /**
     * Make a DEFINITION OF ENTITY request for the given type.
     * @param type
     */
    public definitionForEntity(
        entityId: string
    ): Observable<EntityDefinitionResponse> {
        if (entityId.endsWith("Full")) {
            entityId = entityId.slice(0, -4);
        }
        this.isLoading.next(true);
        return this.httpClient
            .get(`${environment.apiBaseUrl}/${entityId}/V2/Definition`)
            .pipe(
                map((_) => {
                    this.isLoading.next(false);
                    let definition = this.camelPascalMapper.map3rdWaveResponse(
                        _,
                        EntityDefinitionResponse
                    );
                    EntityDefinitionResponse.setAttributes(definition);
                    return definition;
                }),
                share(),

                catchError((error: Response | any) => {
                    this.isLoading.next(false);
                    return this.errorHandlerService.error(error);
                })
            );
    }
    /**
     * Make a DEFINITION OF PROCEDURE request for the given type.
     * @param type
     */
    public definitionForProcedure(
        packageId: string,
        procedureId: string
    ): Observable<ProcedureDefinitionResponse> {
        this.isLoading.next(true);
        return this.httpClient
            .get(
                `${environment.apiBaseUrl}/${packageId}/${procedureId}/Definition`
            )
            .pipe(
                map((_) => {
                    this.isLoading.next(false);
                    let definition = this.camelPascalMapper.map3rdWaveResponse(
                        _,
                        ProcedureDefinitionResponse
                    );
                    if (definition.parameters) {
                        let params = definition.parameters.map((param) => {
                            return this.camelPascalMapper.map3rdWaveResponse(
                                param,
                                ProcedureDefinitionParameter
                            );
                        });
                        definition.parameters = params;
                    }
                    return definition;
                }),
                share(),

                catchError((error: Response | any) => {
                    this.isLoading.next(false);
                    return this.errorHandlerService.error(error);
                })
            );
    }
    /**
     * Make a GET ONE request for the given type and primary key values.
     * @param url
     */
    public getDataByUrl(url: string, options?: any): Observable<any> {
        let params = new HttpParams();

        if (options && options.raiseErrorIfLogicalDeleted) {
            params = params.append(
                "raiseErrorIfLogicalDeleted",
                options.raiseErrorIfLogicalDeleted
                    ? options.raiseErrorIfLogicalDeleted
                    : environment.raiseErrorIfLogicalDeleted
            );
        }

        params = this.addVirtualAttributesParameters(options, params, true);
        if (options && options.searchTX) {
            params = params.append("searchTX", options.searchTX);
        }
        if (options && options.whereClause) {
            params = params.append("whereClause", options.whereClause);
        }
        if (options && options.keys) {
            options.keys.forEach(
                (_) => (params = params.append(_.key, _.value))
            );
        }

        if (options && options.pagination) {
            params = params
                .append(
                    "numberOfRows",
                    this.calculateNumberOfRows({
                        pageSize: options.pageSize,
                        pageIndex: options.pageIndex,
                    })
                )
                .append(
                    "fromRowNo",
                    this.calculateFromRowNo({
                        pageSize: options.pageSize,
                        pageIndex: options.pageIndex,
                    })
                );
        }

        if (options && options.skipCalculate) {
            params = params
                .append("numberOfRows", options.pageSize.toString())
                .append("fromRowNo", options.pageIndex.toString());
        }
        if (options && options.orderByClause) {
            params = params.append(
                "orderByClause",
                options.orderByClause.toString()
            );
        }
        return this.httpClient
            .get(`${environment.apiBaseUrl}/${url}`, { params })
            .pipe(
                map((_) => {
                    this.isLoading.next(false);
                    return _;
                }),
                share(),

                catchError((error: Response | any) => {
                    this.isLoading.next(false);
                    return this.errorHandlerService.error(error);
                })
            );
    }

    /**
     * Make a GET ONE request for the given type and primary key values.
     * @param url
     */
    public addNotesToEntity(message: string, entity: any): Observable<any> {
        const theNote = {
            messageTX: message,
        };
        return this.httpClient
            .post(
                `${environment.apiBaseUrl}/${entity.name}/AddNote?key=${entity.key}`,
                theNote
            )
            .pipe(
                map((_) => {
                    this.isLoading.next(false);
                    return _;
                }),
                share(),

                catchError((error: Response | any) => {
                    this.isLoading.next(false);
                    return this.errorHandlerService.error(error);
                })
            );
    }

    /**
     * Make a GET ONE request for the given type and primary key values.
     * @param type The type.
     * @param options
     */
    public getOneRowByName(
        entityId: string,
        options: {
            keys: Array<IKeyValue>;
            listOfVirtualAttributes?: Array<string>;
            raiseErrorIfLogicalDeleted?: string;
        }
    ): Observable<any> {
        //#region Params
        if (entityId.endsWith("Full")) {
            entityId = entityId.slice(0, -4);
        }
        let params = new HttpParams();

        if (options.raiseErrorIfLogicalDeleted) {
            params = params.append(
                "raiseErrorIfLogicalDeleted",
                options.raiseErrorIfLogicalDeleted
                    ? options.raiseErrorIfLogicalDeleted
                    : environment.raiseErrorIfLogicalDeleted
            );
        }

        params = this.addVirtualAttributesParameters(options, params, true);

        options.keys.forEach((_) => (params = params.append(_.key, _.value)));

        //#endregion

        this.isLoading.next(true);
        return this.httpClient
            .get(`${environment.apiBaseUrl}/${entityId}/V2/GetOne`, {
                params: params,
            })
            .pipe(
                map((_) => {
                    this.isLoading.next(false);
                    return _;
                }),
                share(),

                catchError((error: Response | any) => {
                    this.isLoading.next(false);
                    return this.errorHandlerService.error(error);
                })
            );
    }

    /**
     * Make a GET ONE request for the given type and primary key values.
     * @param type The type.
     * @param options
     */
    public getOneRowByKey(
        entityId: string,
        options: {
            key: string;
            listOfVirtualAttributes?: Array<string>;
            raiseErrorIfLogicalDeleted?: string;
        }
    ): Observable<any> {
        //#region Params
        if (entityId.endsWith("Full")) {
            entityId = entityId.slice(0, -4);
        }
        let params = new HttpParams();

        if (options.raiseErrorIfLogicalDeleted) {
            params = params.append(
                "raiseErrorIfLogicalDeleted",
                options.raiseErrorIfLogicalDeleted
                    ? options.raiseErrorIfLogicalDeleted
                    : environment.raiseErrorIfLogicalDeleted
            );
        }

        params = this.addVirtualAttributesParameters(options, params, true);

        params = params.append("key", options.key);

        //#endregion

        this.isLoading.next(true);
        return this.httpClient
            .get(`${environment.apiBaseUrl}/${entityId}/V2/GetOne`, {
                params: params,
            })
            .pipe(
                map((_) => {
                    this.isLoading.next(false);
                    return _;
                }),
                share(),

                catchError((error: Response | any) => {
                    this.isLoading.next(false);
                    return this.errorHandlerService.error(error);
                })
            );
    }

    /**
     * Make a DELETE request for the given entity of the given type.
     * @param type The type.
     * @param entity The entity to delete.
     */
    public deleteGenericRow(
        row: any,
        logicalDelete: boolean = false
    ): Observable<any> {
        //#endregion
        const theRequest = { key: row.key, deletelogical: logicalDelete };
        this.isLoading.next(true);
        return this.httpClient
            .post(
                `${environment.apiBaseUrl}/${row.theRowEntityId}/V2/DeleteByKey`,
                { theRequest }
            )
            .pipe(
                map((_) => {
                    this.isLoading.next(false);
                    return _;
                }),
                share(),

                catchError((error: Response | any) => {
                    this.isLoading.next(false);
                    return this.errorHandlerService.error(error);
                })
            );
    }
    public getUploadTemplate(entityId: string): Observable<any> {
        return this.httpClient
            .get(
                `${environment.apiBaseUrl}/${entityId}/UploadTemplate`,
                {}
            )
            .pipe(
                map((_) => {
                    this.isLoading.next(false);
                    return _;
                }),
                share(),

                catchError((error: Response | any) => {
                    this.isLoading.next(false);
                    return this.errorHandlerService.error(error);
                })
            );
    }
    public uploadXLSX(
        entityId: string,
        uploadRequest: any,
    ): Observable<any> {
        //#region Params
        return this.httpClient
            .post(
                `${environment.apiBaseUrl}/${entityId}/UploadFile`,
                uploadRequest
            )
            .pipe(
                map((response: any) => {
                }),
                share(),

                catchError((error: Response | any) => {
                    this.isLoading.next(false);
                    return this.errorHandlerService.error(error);
                }));
            }
    /**
     * Make a GET ONE request for the given type and primary key values.
     * @param type The type.
     * @param options
     */
    public getNewEntityByName(entityId: string): Observable<any> {
        //#region Params
        if (entityId.endsWith("Full")) {
            entityId = entityId.slice(0, -4);
        }

        //#endregion

        this.isLoading.next(true);
        return this.httpClient
            .get(
                `${environment.apiBaseUrl}/${entityId}/V2/InitRowWithDefaultValues`,
                {}
            )
            .pipe(
                map((_) => {
                    this.isLoading.next(false);
                    return _;
                }),
                share(),

                catchError((error: Response | any) => {
                    this.isLoading.next(false);
                    return this.errorHandlerService.error(error);
                })
            );
    }

    /**
     * Make a GET ONE request for the given type and primary key values.
     * @param type The type.
     * @param options
     */
    public saveOneEntityByName(
        entityId: string,
        entity: any,
        create = false
    ): Observable<any> {
        //#region Params
        const theRequest = { theRecord: entity };
        return this.httpClient
            .post(
                `${environment.apiBaseUrl}/${entityId}/V2/${
                    create ? "Insert" : "InsertOrUpdate"
                }`,
                theRequest
            )
            .pipe(
                map((response: any) => {
                    var savedEntity = response.theRecord;
                    //todo: should be removed avter create returns also the theRowEntityId
                    savedEntity["theRowEntityId"] = entityId;
                    Object.assign(entity, savedEntity);
                    this.isLoading.next(false);

                    return savedEntity;
                }),
                share(),

                catchError((error: Response | any) => {
                    this.isLoading.next(false);
                    return this.errorHandlerService.error(error);
                })
            );
    }
    public updateV2(entityId: string, row: any): Observable<any> {
        //#region Params
        //the Record only needs to contain key and updated fields, it will return a complete record
        const theRequest = { theRecord: row };
        return this.httpClient
            .post(`${environment.apiBaseUrl}/${entityId}/V2/Update`, theRequest)
            .pipe(
                map((response: any) => {
                    var savedEntity = response.theRecord;
                    //Object.assign(row, savedEntity);
                    this.isLoading.next(false);
                    return savedEntity;
                }),
                share(),

                catchError((error: Response | any) => {
                    this.isLoading.next(false);
                    return this.errorHandlerService.error(error);
                })
            );
    }
    public saveUserSetting(
        settingNM: string,
        settingVL: string
    ): Observable<any> {
        //#region Params
        //the Record only needs to contain key and updated fields, it will return a complete record
        return this.httpClient
            .post<string>(
                `${environment.apiBaseUrl}/CslPlatform3/SetUserSetting?SettingNM=${settingNM}`,
                { settingVL: settingVL }
            )
            .pipe(
                map((response: any) => {
                    var savedEntity = response;
                    this.userSettings[settingNM] = savedEntity;
                    //Object.assign(row, savedEntity);
                    this.isLoading.next(false);
                    return savedEntity;
                }),
                share(),

                catchError((error: Response | any) => {
                    this.isLoading.next(false);
                    return this.errorHandlerService.error(error);
                })
            );
    }
    public getUserSetting(settingNM: string): Observable<any> {
        //#region Params
        //the Record only needs to contain key and updated fields, it will return a complete record
        return this.userSettings[settingNM]
            ? of(this.userSettings[settingNM])
            : this.httpClient
                  .get(
                      `${environment.apiBaseUrl}/CslPlatform3/GetUserSetting?SettingNM=${settingNM}`
                  )
                  .pipe(
                      map((response: any) => {
                          var savedEntity = response;
                          //Object.assign(row, savedEntity);
                          this.userSettings[settingNM] = savedEntity;
                          this.isLoading.next(false);
                          return savedEntity;
                      }),
                      share(),

                      catchError((error: Response | any) => {
                          this.isLoading.next(false);
                          return this.errorHandlerService.error(error);
                      })
                  );
    }

    /**
     * Make a GET ONE request for the given type and primary key values.
     * @param type The type.
     * @param options
     */
    public getRowsByEntityId(
        entityId: string,
        options: {
            pagination?: boolean;
            pageSize?: number;
            pageIndex?: number;
            skipCalculate?: boolean;
            keys?: Array<IKeyValue>;
            searchTX?: string;
            whereClause?: string;
            listOfVirtualAttributes?: Array<string>;
            raiseErrorIfLogicalDeleted?: string;
            orderByClause?: string;
            filterTX?: string;
        }
    ): Observable<any> {
        //#region Params
        if (entityId.endsWith("Full")) {
            entityId = entityId.slice(0, -4);
        }
        let params = new HttpParams();

        if (options.raiseErrorIfLogicalDeleted) {
            params = params.append(
                "raiseErrorIfLogicalDeleted",
                options.raiseErrorIfLogicalDeleted
                    ? options.raiseErrorIfLogicalDeleted
                    : environment.raiseErrorIfLogicalDeleted
            );
        }

        params = this.addVirtualAttributesParameters(options, params, true);
        if (options.searchTX) {
            params = params.append("searchTX", options.searchTX);
        }
        if (options.filterTX) {
            params = params.append("filterTX", options.filterTX);
        }
        if (options.whereClause) {
            params = params.append("whereClause", options.whereClause);
        }
        if (options.keys) {
            options.keys.forEach(
                (_) => (params = params.append(_.key, _.value))
            );
        }

        if (options && options.pagination) {
            params = params
                .append(
                    "numberOfRows",
                    this.calculateNumberOfRows({
                        pageSize: options.pageSize,
                        pageIndex: options.pageIndex,
                    })
                )
                .append(
                    "fromRowNo",
                    this.calculateFromRowNo({
                        pageSize: options.pageSize,
                        pageIndex: options.pageIndex,
                    })
                );
        }

        if (options && options.skipCalculate) {
            params = params
                .append("numberOfRows", options.pageSize.toString())
                .append("fromRowNo", options.pageIndex.toString());
        }
        if (options && options.orderByClause) {
            params = params.append(
                "orderByClause",
                options.orderByClause.toString()
            );
        }

        //#endregion

        this.isLoading.next(true);
        return this.httpClient
            .get(`${environment.apiBaseUrl}/${entityId}/V2/Query`, {
                params: params,
            })
            .pipe(
                map((response) => {
                    this.isLoading.next(false);
                    this.totalRowCount = response["totalRowCount"];
                    if (options && options.pagination) {
                        this.entitiesLoaded.next({
                            length: parseInt(response["totalRowCount"]),
                            pageSize: parseInt(params.get("numberOfRows")),
                            pageIndex: Math.floor(
                                parseInt(params.get("fromRowNo")) /
                                    parseInt(params.get("numberOfRows"))
                            ),
                        });
                    }
                    return response;
                }),
                share(),

                catchError((error: Response | any) => {
                    this.isLoading.next(false);
                    this.totalRowCount = 0;
                    this.entitiesLoaded.next({
                        length: 0,
                        pageSize: 0,
                        pageIndex: 0,
                    });
                    return this.errorHandlerService.error(error);
                })
            );
    }

    /**
     * Make a GET ONE request for the given type and primary key values.
     * @param type The type.
     * @param options
     */
    public getEntitiesByUrl(
        possibleValueSetURL: string,
        options: {
            pagination?: boolean;
            pageSize?: number;
            pageIndex?: number;
            keys?: Array<IKeyValue>;
            whereClause?: string;
            listOfVirtualAttributes?: Array<string>;
            raiseErrorIfLogicalDeleted?: string;
            filterTX?: string;

            searchTX?: string;
        }
    ): Observable<any> {
        //#region Params
        if (possibleValueSetURL.split("?").length > 0) {
            possibleValueSetURL = possibleValueSetURL.split("?")[0];
        }
        let params = new HttpParams();

        if (options.raiseErrorIfLogicalDeleted) {
            params = params.append(
                "raiseErrorIfLogicalDeleted",
                options.raiseErrorIfLogicalDeleted
                    ? options.raiseErrorIfLogicalDeleted
                    : environment.raiseErrorIfLogicalDeleted
            );
        }

        params = this.addVirtualAttributesParameters(
            options,
            params,
            true,
            true
        );
        if (options.whereClause) {
            params = params.append("whereClause", options.whereClause);
        }
        if (options.filterTX) {
            params = params.append("filterTX", options.filterTX);
        }
        if (options.keys) {
            options.keys.forEach(
                (_) => (params = params.append(_.key, _.value))
            );
        }
        if (options && options.searchTX) {
            params = params.append("searchTX", options.searchTX);
        }
        if (options && options.pagination) {
            params = params
                .append(
                    "numberOfRows",
                    this.calculateNumberOfRows({
                        pageSize: options.pageSize,
                        pageIndex: options.pageIndex,
                    })
                )
                .append(
                    "fromRowNo",
                    this.calculateFromRowNo({
                        pageSize: options.pageSize,
                        pageIndex: options.pageIndex,
                    })
                );
        }

        //#endregion

        this.isLoading.next(true);
        return this.httpClient
            .get(`${environment.apiBaseUrl}/${possibleValueSetURL}`, {
                params: params,
            })
            .pipe(
                map((_) => {
                    this.isLoading.next(false);
                    return _;
                }),
                share(),

                catchError((error: Response | any) => {
                    this.isLoading.next(false);
                    return this.errorHandlerService.error(error);
                })
            );
    }

    /**
     * Make a GET ONE request for the given type and primary key values.
     * @param type The type.
     * @param options
     */
    public getOne<T extends { new (...args: any[]): any; prototype: any }>(
        type: T,
        options: {
            keys: Array<IKeyValue>;
            listOfVirtualAttributes?: Array<string>;
            raiseErrorIfLogicalDeleted?: string;
        }
    ): Observable<any> {
        //#region Params

        let params = new HttpParams();

        if (options.raiseErrorIfLogicalDeleted) {
            params = params.append(
                "raiseErrorIfLogicalDeleted",
                options.raiseErrorIfLogicalDeleted
                    ? options.raiseErrorIfLogicalDeleted
                    : environment.raiseErrorIfLogicalDeleted
            );
        }

        params = this.addVirtualAttributesParameters(options, params);

        options.keys.forEach((_) => (params = params.append(_.key, _.value)));

        //#endregion

        var cacheName;
        const typeName = getName(type);
        if (typeName.startsWith("DP3")) {
            cacheName = typeName;
            options.keys.forEach((_) => (cacheName += _.value));
            var tmp = sessionStorage.getItem(cacheName);
            if (tmp) {
                return of(JSON.parse(tmp));
            }
        }

        //Insert Caching for DP3 Queries

        this.isLoading.next(true);
        return this.httpClient
            .get(this.mapModelUrl(type, QueryTypeEnum.GetOne), {
                params: params,
            })
            .pipe(
                map((_) => {
                    this.isLoading.next(false);
                    var tmp = this.camelPascalMapper.map3rdWaveResponse(
                        _,
                        type
                    );
                    if (cacheName) {
                        sessionStorage.setItem(cacheName, JSON.stringify(tmp));
                    }
                    return tmp;
                }),
                share(),

                catchError((error: Response | any) => {
                    this.isLoading.next(false);
                    return this.errorHandlerService.error(error);
                })
            );
    }

    /**
     * Make a GET ONE FULL request for the given type and primary key values.
     * @param type The type.
     * @param options
     */
    public getOneFull<T extends { new (...args: any[]): any; prototype: any }>(
        type: T,
        options: {
            keys: Array<IKeyValue>;
            listOfVirtualAttributes?: Array<string>;
            raiseErrorIfLogicalDeleted?: string;
        }
    ): Observable<any> {
        //#region Params

        let params = new HttpParams();

        if (options.raiseErrorIfLogicalDeleted) {
            params = params.append(
                "raiseErrorIfLogicalDeleted",
                options.raiseErrorIfLogicalDeleted
                    ? options.raiseErrorIfLogicalDeleted
                    : environment.raiseErrorIfLogicalDeleted
            );
        }

        params = this.addVirtualAttributesParameters(options, params);

        options.keys.forEach((_) => (params = params.append(_.key, _.value)));

        //#endregion

        this.isLoading.next(true);
        return this.httpClient
            .get(this.mapModelUrl(type, QueryTypeEnum.GetOneFull), {
                params: params,
            })
            .pipe(
                map((_) => {
                    this.isLoading.next(false);
                    return this.camelPascalMapper.map3rdWaveResponse(_, type);
                }),
                share(),

                catchError((error: Response | any) => {
                    this.isLoading.next(false);
                    return this.errorHandlerService.error(error);
                })
            );
    }

    /**
     * Make a QUERY request for the given type.
     * @param type The type.
     * @param options
     */
    public query<T extends { new (...args: any[]): any; prototype: any }>(
        type: T,
        options?: {
            pagination?: boolean;
            skipCalculate?: boolean;
            pageSize?: number;
            pageIndex?: number;
            whereClause?: string;
            orderByClause?: string;
            listOfVirtualAttributes?: Array<string>;
            params?: Array<IKeyValue>;
            queryLogicalDeletedFL?: string;
        }
    ): Observable<any> {
        //#region Params

        let params = new HttpParams();

        if (options && options.queryLogicalDeletedFL) {
            params = params.append(
                "queryLogicalDeletedFL",
                options.queryLogicalDeletedFL
            );
        } else {
            params = params.append(
                "queryLogicalDeletedFL",
                environment.queryLogicalDeletedFL
            );
        }

        if (options && options.pagination) {
            params = params
                .append(
                    "numberOfRows",
                    this.calculateNumberOfRows({
                        pageSize: options.pageSize,
                        pageIndex: options.pageIndex,
                    })
                )
                .append(
                    "fromRowNo",
                    this.calculateFromRowNo({
                        pageSize: options.pageSize,
                        pageIndex: options.pageIndex,
                    })
                );
        }

        if (options && options.skipCalculate) {
            params = params
                .append("numberOfRows", options.pageSize.toString())
                .append("fromRowNo", options.pageIndex.toString());
        }

        if (options && options.whereClause) {
            params = params.append("whereClause", options.whereClause);
        }

        if (options && options.orderByClause) {
            params = params.append("orderByClause", options.orderByClause);
        }

        params = this.addVirtualAttributesParameters(options, params);

        if (options && options.params) {
            options.params.forEach(
                (_) => (params = params.append(_.key, _.value))
            );
        }
        var cacheName;
        const typeName = getName(type);
        if (typeName.startsWith("DP3")) {
            cacheName = typeName;
            cacheName += params.toString();
            var tmp = sessionStorage.getItem(cacheName);
            if (tmp) {
                return of(JSON.parse(tmp));
            }
        }
        //#endregion

        this.isLoading.next(true);
        return this.httpClient
            .get(this.mapModelUrl(type, QueryTypeEnum.Query), {
                params: params,
            })
            .pipe(
                map((queryResponse) => {
                    this.isLoading.next(false);
                    this.totalRowCount = queryResponse["totalRowCount"];
                    if (options && options.pagination) {
                        this.entitiesLoaded.next({
                            length: parseInt(queryResponse["totalRowCount"]),
                            pageSize: parseInt(params.get("numberOfRows")),
                            pageIndex: Math.floor(
                                parseInt(params.get("fromRowNo")) /
                                    parseInt(params.get("numberOfRows"))
                            ),
                        });
                    }

                    var tmp = this.camelPascalMapper.map3rdWaveResponse(
                        queryResponse,
                        type
                    );
                    if (cacheName) {
                        sessionStorage.setItem(cacheName, JSON.stringify(tmp));
                    }
                    return tmp;
                }),
                share(),

                catchError((error: Response | any) => {
                    this.isLoading.next(false);
                    return this.errorHandlerService.error(error);
                })
            );
    }
    public queryV2<T extends { new (...args: any[]): any; prototype: any }>(
        type: T,
        options?: {
            pagination?: boolean;
            skipCalculate?: boolean;
            pageSize?: number;
            pageIndex?: number;
            whereClause?: string;
            orderByClause?: string;
            searchTX?: string;
            listOfVirtualAttributes?: Array<string>;
            params?: Array<IKeyValue>;
            queryLogicalDeletedFL?: string;
        }
    ): Observable<any> {
        //#region Params

        let params = new HttpParams();

        if (options && options.queryLogicalDeletedFL) {
            params = params.append(
                "queryLogicalDeletedFL",
                options.queryLogicalDeletedFL
            );
        } else {
            params = params.append(
                "queryLogicalDeletedFL",
                environment.queryLogicalDeletedFL
            );
        }

        if (options && options.pagination) {
            params = params
                .append(
                    "numberOfRows",
                    this.calculateNumberOfRows({
                        pageSize: options.pageSize,
                        pageIndex: options.pageIndex,
                    })
                )
                .append(
                    "fromRowNo",
                    this.calculateFromRowNo({
                        pageSize: options.pageSize,
                        pageIndex: options.pageIndex,
                    })
                );
        }

        if (options && options.skipCalculate) {
            params = params
                .append("numberOfRows", options.pageSize.toString())
                .append("fromRowNo", options.pageIndex.toString());
        }

        if (options && options.whereClause) {
            params = params.append("whereClause", options.whereClause);
        }
        if (options && options.searchTX) {
            params = params.append("searchTX", options.searchTX);
        }

        if (options && options.orderByClause) {
            params = params.append("orderByClause", options.orderByClause);
        }
        if (options && options.listOfVirtualAttributes && options.listOfVirtualAttributes.includes("NOTALL"))
            params = this.addVirtualAttributesParameters(options, params, true, true);
        else
            params = this.addVirtualAttributesParameters(options, params, true);

        if (options && options.params) {
            options.params.forEach(
                (_) => (params = params.append(_.key, _.value))
            );
        }
        var cacheName;
        const typeName = getName(type);
        if (typeName.startsWith("DP3")) {
            cacheName = typeName;
            cacheName += params.toString();
            var tmp = sessionStorage.getItem(cacheName);
            if (tmp) {
                return of(JSON.parse(tmp));
            }
        }
        //#endregion

        this.isLoading.next(true);
        return this.httpClient
            .get(this.mapModelUrlV2(type, QueryTypeEnum.Query), {
                params: params,
            })
            .pipe(
                map((queryResponse) => {
                    this.isLoading.next(false);
                    this.totalRowCount = queryResponse["totalRowCount"];
                    if (options && options.pagination) {
                        this.entitiesLoaded.next({
                            length: parseInt(queryResponse["totalRowCount"]),
                            pageSize: parseInt(params.get("numberOfRows")),
                            pageIndex: Math.floor(
                                parseInt(params.get("fromRowNo")) /
                                    parseInt(params.get("numberOfRows"))
                            ),
                        });
                    }

                    var tmp = this.camelPascalMapper.map3rdWaveResponse(
                        queryResponse,
                        type
                    );
                    if (cacheName) {
                        sessionStorage.setItem(cacheName, JSON.stringify(tmp));
                    }
                    return tmp;
                }),
                share(),

                catchError((error: Response | any) => {
                    this.isLoading.next(false);
                    return this.errorHandlerService.error(error);
                })
            );
    }

    private addVirtualAttributesParameters(
        options: {
            pagination?: boolean;
            skipCalculate?: boolean;
            pageSize?: number;
            pageIndex?: number;
            whereClause?: string;
            orderByClause?: string;
            listOfVirtualAttributes?: Array<string>;
            params?: Array<IKeyValue>;
            queryLogicalDeletedFL?: string;
        },
        params: HttpParams,
        v2?: boolean,
        strict?: boolean
    ) {
        if (options && options.listOfVirtualAttributes) {
            params = params.append(
                this.getAttributesParamName(v2),
                options.listOfVirtualAttributes.join(",") +
                    (v2 && strict != true ? ",*" : "")
            );
        } else {
            if (v2 && strict != true) {
                params = params.append(this.getAttributesParamName(v2), "*");
            }
        }
        return params;
    }

    private getAttributesParamName(v2?: boolean): string {
        return v2 ? "listOfAttributes" : "listOfVirtualAttributes";
    }

    /**
     * Make a QUERY FULL request for the given type.
     * @param type The type.
     * @param options
     */
    public queryFull<T extends { new (...args: any[]): any; prototype: any }>(
        type: T,
        options?: {
            pagination?: boolean;
            pageSize?: number;
            pageIndex?: number;
            skipCalculate?: boolean;
            whereClause?: string;
            orderByClause?: string;
            listOfVirtualAttributes?: Array<string>;
            params?: Array<IKeyValue>;
            queryLogicalDeletedFL?: string;
        }
    ): Observable<any> {
        //#region Params

        let params = new HttpParams();

        if (options && options.queryLogicalDeletedFL) {
            params = params.append(
                "queryLogicalDeletedFL",
                options.queryLogicalDeletedFL
            );
        } else {
            params = params.append(
                "queryLogicalDeletedFL",
                environment.queryLogicalDeletedFL
            );
        }

        if (options && options.pagination) {
            params = params
                .append(
                    "numberOfRows",
                    this.calculateNumberOfRows({
                        pageSize: options.pageSize,
                        pageIndex: options.pageIndex,
                    })
                )
                .append(
                    "fromRowNo",
                    this.calculateFromRowNo({
                        pageSize: options.pageSize,
                        pageIndex: options.pageIndex,
                    })
                );
        }

        if (options && options.skipCalculate) {
            params = params
                .append("numberOfRows", options.pageSize.toString())
                .append("fromRowNo", options.pageIndex.toString());
        }

        if (options && options.whereClause) {
            params = params.append("whereClause", options.whereClause);
        }

        if (options && options.orderByClause) {
            params = params.append("orderByClause", options.orderByClause);
        }

        params = this.addVirtualAttributesParameters(options, params);

        if (options && options.params) {
            options.params.forEach(
                (_) => (params = params.append(_.key, _.value))
            );
        }

        //#endregion

        this.isLoading.next(true);
        return this.httpClient
            .get(this.mapModelUrl(type, QueryTypeEnum.QueryFull), {
                params: params,
            })
            .pipe(
                map((queryResponse) => {
                    this.isLoading.next(false);
                    this.totalRowCount = queryResponse["totalRowCount"];
                    if (options && options.pagination) {
                        this.entitiesLoaded.next({
                            length: parseInt(queryResponse["totalRowCount"]),
                            pageSize: parseInt(params.get("numberOfRows")),
                            pageIndex: Math.floor(
                                parseInt(params.get("fromRowNo")) /
                                    parseInt(params.get("numberOfRows"))
                            ),
                        });
                    }

                    return this.camelPascalMapper.map3rdWaveResponse(
                        queryResponse,
                        type
                    );
                }),
                share(),

                catchError((error: Response | any) => {
                    this.isLoading.next(false);
                    return this.errorHandlerService.error(error);
                })
            );
    }

    /**
     * Make an INSERT or UPDATE request for the given entity of the given type.
     * @param type The type.
     * @param entity The entity to insert or update.
     */
    public insertOrUpdate<
        T extends { new (...args: any[]): any; prototype: any }
    >(type: T, entity: Object, initialEntity?: Object): Observable<any> {
        const theRequest = { theRecord: entity };

        this.isSaving.next(true);
        return this.httpClient
            .put(
                this.mapModelUrl(type, QueryTypeEnum.InsertOrUpdate),
                theRequest
            )
            .pipe(
                map((_) => {
                    this.isSaving.next(false);
                    const obj = this.camelPascalMapper.map3rdWaveResponse(
                        _,
                        type
                    ) as T;
                    return obj;
                }),
                share(),

                catchError((error: Response | any) => {
                    this.isSaving.next(false);
                    return this.errorHandlerService.error(error);
                })
            );
    }

    /**
     * Make an INSERT request for the given entity of the given type.
     * @param type The type.
     * @param entity The entity to insert.
     */
    public insert<T extends { new (...args: any[]): any; prototype: any }>(
        type: T,
        entity: Object
    ): Observable<any> {
        const theRequest = { theRecord: entity };

        this.isInserting.next(true);
        return this.httpClient
            .put(this.mapModelUrl(type, QueryTypeEnum.Insert), theRequest)
            .pipe(
                map((_) => {
                    this.isInserting.next(false);
                    const obj = this.camelPascalMapper.map3rdWaveResponse(
                        _,
                        type
                    ) as T;
                    return obj;
                }),
                share(),

                catchError((error: Response | any) => {
                    this.isInserting.next(false);
                    return this.errorHandlerService.error(error);
                })
            );
    }

    /**
     * Make an UPDATE request for the given entity of the given type.
     * @param type The type.
     * @param entity The entity to update.
     * @param fetchedEntity  The fetched entity to compare.
     */
    public update<T extends { new (...args: any[]): any; prototype: any }>(
        type: T,
        entity: Object,
        fetchedEntity?: Object
    ): Observable<any> {
        const theRequest = {
            theRecord: entity,
            fetchedRecord: fetchedEntity,
        };

        this.isUpdating.next(true);
        return this.httpClient
            .put(this.mapModelUrl(type, QueryTypeEnum.Update), theRequest)
            .pipe(
                map((_) => {
                    this.isUpdating.next(false);
                    const obj = this.camelPascalMapper.map3rdWaveResponse(
                        _,
                        type
                    ) as T;
                    return obj;
                }),
                share(),

                catchError((error: Response | any) => {
                    this.isUpdating.next(false);
                    return this.errorHandlerService.error(error);
                })
            );
    }

    /**
     * Make a LOGICAL DELETE request for the given entity of the given type.
     * If fetched entity is passed, logical delete takes place only if last version of entity === fetched entity.
     * @param type The type.
     * @param entity The entity to delete.
     * @param fetchedEntity The fetched entity to compare.
     */
    public logicalDelete<
        T extends { new (...args: any[]): any; prototype: any }
    >(type: T, entity: Object, fetchedEntity?: Object): Observable<any> {
        const theRequest = {
            theRecord: entity,
            fetchedRecord: fetchedEntity,
        };

        this.isLogicallyDeleting.next(true);
        return this.httpClient
            .put(
                this.mapModelUrl(type, QueryTypeEnum.LogicalDelete),
                theRequest
            )
            .pipe(
                map((_) => {
                    this.isLogicallyDeleting.next(false);
                    const obj = this.camelPascalMapper.map3rdWaveResponse(
                        _,
                        type
                    ) as T;
                    return obj;
                }),
                share(),

                catchError((error: Response | any) => {
                    this.isLogicallyDeleting.next(false);
                    return this.errorHandlerService.error(error);
                })
            );
    }

    /**
     * Make a LOGICAL DELETE BY KEY request for the given entity of the given type.
     * @param type The type.
     * @param keys List of primary key name and value.
     * @param fetchedEntity The fetched entity to compare.
     */
    public logicalDeleteByKey<
        T extends { new (...args: any[]): any; prototype: any }
    >(
        type: T,
        keys: Array<IKeyValue>,
        fetchedEntity?: Object
    ): Observable<any> {
        const theRequest = fetchedEntity;

        let params = new HttpParams();
        keys.forEach((_) => (params = params.append(_.key, _.value)));

        this.isLogicallyDeletingByKey.next(true);
        return this.httpClient
            .put(
                this.mapModelUrl(type, QueryTypeEnum.LogicalDeleteByKey),
                theRequest,
                { params: params }
            )
            .pipe(
                map((_) => {
                    this.isLogicallyDeletingByKey.next(false);
                    const obj = this.camelPascalMapper.map3rdWaveResponse(
                        _,
                        type
                    ) as T;
                    return obj;
                }),
                share(),

                catchError((error: Response | any) => {
                    this.isLogicallyDeletingByKey.next(false);
                    return this.errorHandlerService.error(error);
                })
            );
    }

    /**
     * Make a DELETE request for the given entity of the given type.
     * @param type The type.
     * @param entity The entity to delete.
     */
    public delete<T extends { new (...args: any[]): any; prototype: any }>(
        type: T,
        entity: Object,
        fetchedEntity?: Object
    ): Observable<any> {
        const theDeleteRequest: any = {
            theRecord: entity,
            fetchedRecord: fetchedEntity,
        };
        this.isDeleting.next(true);
        return this.httpClient
            .put(this.mapModelUrl(type, QueryTypeEnum.Delete), theDeleteRequest)
            .pipe(
                map((_) => {
                    this.isDeleting.next(false);
                    return _;
                }),
                share(),

                catchError((error: Response | any) => {
                    this.isDeleting.next(false);
                    return this.errorHandlerService.error(error);
                })
            );
    }

    /**
     * Make a DELETE request for the given entity of the given type.
     * @param type The type.
     * @param entity The entity to delete.
     */
    public deleteRow(
        row: Object,
        logicalDelete: boolean = true
    ): Observable<any> {
        const theDeleteRequest: any = {
            theRecord: row,
            fetchedRecord: row,
        };
        this.isDeleting.next(true);
        return this.httpClient
            .put(
                this.mapRowByEntityId(
                    row,
                    logicalDelete
                        ? QueryTypeEnum.LogicalDelete
                        : QueryTypeEnum.Delete
                ),
                theDeleteRequest
            )
            .pipe(
                map((_) => {
                    this.isDeleting.next(false);
                    return _;
                }),
                share(),

                catchError((error: Response | any) => {
                    this.isDeleting.next(false);
                    return this.errorHandlerService.error(error);
                })
            );
    }

    /**
     * Make a DELETE BY KEY request for the given entity of the given type.
     * @param type The type.
     * @param keys List of primary key name and value.
     * @param fetchedEntity The fetched entity to compare.
     */
    public deleteByKey<T extends { new (...args: any[]): any; prototype: any }>(
        type: T,
        keys: Array<IKeyValue>,
        fetchedEntity?: Object
    ): Observable<any> {
        const theRequest = fetchedEntity;

        let params = new HttpParams();
        keys.forEach((_) => (params = params.append(_.key, _.value)));

        this.isDeletingByKey.next(true);
        return this.httpClient
            .put(
                this.mapModelUrl(type, QueryTypeEnum.DeleteByKey),
                theRequest,
                { params: params }
            )
            .pipe(
                map((_) => {
                    this.isDeletingByKey.next(false);
                    return _;
                }),
                share(),

                catchError((error: Response | any) => {
                    this.isDeletingByKey.next(false);
                    return this.errorHandlerService.error(error);
                })
            );
    }

    /**
     * EXECUTE PROCEDURE, expecting result of the given type.
     * @param type The type.
     * @param methodName The procedure name.
     * @param options List of primary key name and value.
     */
    public executeProcedure<
        T extends { new (...args: any[]): any; prototype: any }
    >(
        type: T,
        methodName: string,
        options?: { keys?: Array<IKeyValue> }
    ): Observable<any> {
        let params = new HttpParams();

        if (options) {
            if (options.keys && options.keys.length > 0) {
                options.keys.forEach(
                    (_) => (params = params.append(_.key, _.value))
                );
            }
        }

        this.isExecutingProcedure.next(true);
        return this.httpClient
            .get(`${environment.apiBaseUrl}/WavesBase/${methodName}`, {
                params: params,
            })
            .pipe(
                map((_) => {
                    this.isExecutingProcedure.next(false);
                    const result = this.camelPascalMapper.map3rdWaveResponse(
                        _,
                        type
                    ) as T;

                    return result;
                }),
                share(),

                catchError((error: Response | any) => {
                    this.isExecutingProcedure.next(false);
                    var tmp =
                        `${environment.apiBaseUrl}/WavesBase/${methodName}` +
                        params.toString();
                    if (error instanceof Response) {
                        const body = (error.json() as any) || "";
                        const err = body.error || JSON.stringify(body);
                        var errMsg = `${error.status} - ${
                            error.statusText || ""
                        } ${err}`;
                        tmp = err;
                    }
                    tmp =
                        (error.error.errMsg || error.error.ErrMsg) +
                        "<br>" +
                        tmp;
                    if (
                        window.location.href.indexOf("dev-") > 0 ||
                        tmp.indexOf("dev-") > 0
                    )
                        return this.errorHandlerService.error(tmp);
                    else return this.errorHandlerService.error(error);
                })
            );
    }

    /**
     * EXECUTE GENERIC PROCEDURE, expecting result of the given type.
     * @param type The type.
     * @param packageId The procedure package ID.
     * @param procedureId The procedure ID.
     * @param options List of primary key name and value.
     */
    public executeGenericProcedure(
        packageId: string,
        procedureId: string,
        options?: { keys?: Array<IKeyValue> }
    ): Observable<any> {
        let params = new HttpParams();

        if (options) {
            Object.keys(options).forEach(
                (_) => (params = params.append(_, options[_]))
            );
        }

        this.isExecutingProcedure.next(true);
        return this.httpClient
            .post(
                `${environment.apiBaseUrl}/${packageId}/${procedureId}/Execute`, options
            )
            .pipe(
                share(),
                catchError((error: Response | any) => {
                    this.isExecutingProcedure.next(false);
                    return this.errorHandlerService.error(error);
                })
            );
    }
    //#region Private Methods

    /**
     * Calculate page size from the param.
     * @param params
     */
    private calculateNumberOfRows(params: any): string {
        return `${
            params && params["pageSize"]
                ? params["pageSize"]
                : environment.pageSizeOptions[0]
        }`;
    }

    /**
     * Calculate page index from the params.
     * @param params
     */
    private calculateFromRowNo(params: any): string {
        return `${
            params && params["pageIndex"]
                ? parseInt(this.calculateNumberOfRows(params)) *
                      parseInt(params["pageIndex"]) +
                  1
                : 1
        }`;
    }

    /**
     * Build the API url using the given model type and method type.
     * @param objectType
     * @param methodType
     */
    private mapModelUrl<
        T extends { new (...args: any[]): any; prototype: any }
    >(objectType: T, methodType: QueryTypeEnum, v2?: boolean): string {
        const typeName = getName(objectType);

        if (typeName.startsWith("DP3")) {
            return `${environment.apiBaseUrl}/DP3Base/${
                typeName === "DP3Project" ? "Get" : QueryTypeEnum[methodType]
            }${typeName.slice(3, typeName.length)}?`;
        } else {
            if (v2) {
                return `${environment.apiBaseUrl}/${typeName}/V2/Query?`;
            }
            return `${environment.apiBaseUrl}/${typeName}/${QueryTypeEnum[methodType]}?`;

        }
    }
    private mapModelUrlV2<
        T extends { new (...args: any[]): any; prototype: any }
    >(objectType: T, methodType: QueryTypeEnum): string {
        const typeName = getName(objectType);

        if (typeName.startsWith("DP3")) {
            return `${environment.apiBaseUrl}/DP3Base/${
                typeName === "DP3Project" ? "Get" : QueryTypeEnum[methodType]
            }${typeName.slice(3, typeName.length)}?`;
        } else {
            return `${environment.apiBaseUrl}/${typeName}/V2/Query?`;
        }
    }

    private mapRowByEntityId(row: any, methodType: QueryTypeEnum): string {
        return `${environment.apiBaseUrl}/${row.theRowEntityId}/${QueryTypeEnum[methodType]}?`;
    }

    //#endregion
}
