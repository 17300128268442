import { NgModule } from '@angular/core';
import { MatTabHideDirective } from './directive/mat-tab-hide.directive';


@NgModule({
    imports: [
    ],
    declarations: [MatTabHideDirective],
    exports: [MatTabHideDirective]
})
export class MatTabHideModule { }