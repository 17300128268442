import { TracingBase } from "app/core/models";
import { Name } from "app/core/decorators";
import { IBaseEntity } from "app/core/interfaces/IBaseEntity";

/** QRM Job Tickets : Activities */
@Name("QrmJobTicketActivity")
export class QrmJobTicketActivity extends TracingBase implements IBaseEntity {
    name = "QrmJobTicketActivity";
    /** QRM Job Ticket Number                                           PK */
    public qrmJobTicketNO: string;
    /** QRM Job Ticket Activity Sequence Number                         PK */
    public qrmJobTicketActivitySQ: number;
    /** QRM Job Ticket Activity Label */
    public qrmJobTicketActivityLB: string;
    /** QRM Job Ticket Activity Description */
    public qrmJobTicketActivityDS: string;
    /** QRM Job Ticket Activity Info (Hyperlink) */
    public qmJobTicketActivityUR: string;
    /** QRM Job Ticket Activity Info (Hypertext) */
    public qrmJobTicketActivityHT: string;
    /** QRM Job Ticket Activity Info (Preview of Hypertext)             Virtual attribute */
    public qrmJobTicketActivityPH: string;
    /** Does the QRM Job Ticket Activity Represent Touch Time? */
    public touchTimeFL: string;
    /** Does the measurement concerns WIP-Inventory? */
    public wipInventoryFL: string;
    /** Measuring Method Type */
    public measuringMethodTY: string;
    /** Duration Time Quantity */
    public durationTimeQT: number;
    /** Unit of Measure Code */
    public durationTimeQTUnitCE: string;
    /** Activity Start Date and Time */
    public activityStartDT: Date;
    /** Activity End Date and Time */
    public activityEndDT: Date;
    /** Stock Quantity */
    public stockQT: number;
    /** Stock Quantity Unit Code */
    public stockQTUnitCE: string;
    /** Flow Rate Quantity */
    public flowRateQT: number;
    /** Flow Rate Stock Unit Code */
    public flowRateStockUnitCE: string;
    /** Unit of Measure Code */
    public flowRateTimeUnitCE: string;

    public get id(): string {
        return this.qrmJobTicketNO && this.qrmJobTicketActivitySQ
            ? `${this.qrmJobTicketNO},${this.qrmJobTicketActivitySQ}`
            : null;
    }

    public errorMessage: string;

    public isWIP = (): boolean => this.wipInventoryFL === "Y";
    public isTouchTime = (): boolean => this.touchTimeFL === "Y";

    public get touchTime(): boolean {
        return this.touchTimeFL === "Y";
    }
    public set touchTime(value: boolean) {
        this.touchTimeFL = value ? "Y" : "N";
    }
    public get wip(): boolean {
        return this.wipInventoryFL === "Y";
    }
    public set wip(value: boolean) {
        this.wipInventoryFL = value ? "Y" : "N";
    }
    public get duration(): number {
        if (this.measuringMethodTY == 'DURATION') {
            let rt = this.durationTimeQT;
            if (this.durationTimeQTUnitCE == 'min') rt = rt * 60 * 1000;
            if (this.durationTimeQTUnitCE == 'hour') rt = rt * 60 * 60 * 1000;
            return rt;
        }
        if (!this.activityEndDT || !this.activityStartDT) return 0;
        var eventStartTime = new Date(this.activityStartDT);
        var eventEndTime = new Date(this.activityEndDT);
        var duration = eventEndTime.valueOf() - eventStartTime.valueOf();
        return duration;
    }
    stqUnitOfMeasure: any;
    frsUnitOfMeasure: any;
    dtqUnitOfMeasure: any;
}
