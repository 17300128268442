import { Name } from "app/core/decorators";
import { QrmProductBomPart } from "app/core/models/entity-models/qrmProductBomPart";
import { QrmProduct } from "app/core/models/entity-models/qrmProduct";
import { QrmProductBom } from "app/core/models/entity-models/qrmProductBom";
import { UnitOfMeasure } from "app/core/models/entity-models/unitOfMeasure";
import { QrmProductBomExplosionFull } from ".";

/** QRM Products : Bills of Material : Parts */
@Name("QrmProductBomPart")
export class QrmProductBomPartFull extends QrmProductBomPart {
    name = "QrmProductBomPart";
    /** Parent Bom */
    public qpbmQrmProductBom: QrmProductBom;

    //one of these should always be null
    /** QRM Product */
    public qrmProduct: QrmProduct;

    /** BOM*/
    public prtQrmProductBom: QrmProductBom;

    /** Unit of Measure  */
    public pqtUnitOfMeasure: UnitOfMeasure;

    /** Lead Time Offset Unit */
    public ltoUnitOfMeasure: UnitOfMeasure;
    bomParts: QrmProductBomPart[];
    bomExplosions: QrmProductBomExplosionFull[];
}
