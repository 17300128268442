import { QrmProductBom } from "app/core/models/entity-models/qrmProductBom";
import { QrmProduct } from "app/core/models/entity-models/qrmProduct";
import { CapacityCluster } from "app/core/models/entity-models/capacityCluster";
import { UnitOfMeasure } from "app/core/models/entity-models/unitOfMeasure";
import { QrmProductBomExplosionFull } from "app/core/models/entity-models/qrmProductBomExplosionFull";
import { Name } from "app/core/decorators";
import { QrmProductBomPart } from "app/core/models/entity-models/qrmProductBomPart";
import { QrmProductBomExplosion } from "./qrmProductBomExplosion";

/** QRM Products : Bills of Material Full */
@Name("QrmProductBom")
export class QrmProductBomFull extends QrmProductBom {
    name = "QrmProductBom";
    /** QRM Product */
    public qrmProduct: QrmProduct;
    /**  Capacity Cluster */
    public capacityCluster: CapacityCluster;
    /** Capacity Cluster Quantity Unit */
    public ccqUnitOfMeasure: UnitOfMeasure;
    /** Default Batch Quantity Unit */
    public dfbUnitOfMeasure: UnitOfMeasure;
    /** Fixed Process Time Unit */
    public vptUnitOfMeasure: UnitOfMeasure;
    /** Fixed Process Time Unit */
    public fptUnitOfMeasure: UnitOfMeasure;
    /** Lead Time Unit */
    public ltmUnitOfMeasure: UnitOfMeasure;
    /** Variable Process Unit */
    public vpuUnitOfMeasure: UnitOfMeasure;

    /** Parts */
    public bomParts: Array<QrmProductBomPart>;

    /** Bom Explosion */
    public bomExplosions: Array<QrmProductBomExplosionFull>;
    public bomExplosionsTree: QrmProductBomExplosion[];

    /** <qrmProductCE> <qrmProduct?.qrmProductLB> <(defaultBatchUnitCE)> */
    public get caption(): string {
        return `${this.qrmProductBomLB} ${this.qrmProductCE}  ${
            this.qrmProductBomDS || ""
        }`;
    }
}
