import { Unsubscriber } from ".";
import { Directive, OnInit, ViewChild } from "@angular/core";
import { MatLegacyPaginator as MatPaginator } from "@angular/material/legacy-paginator";
import { IPaginationService, IPagination } from "../interfaces";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { takeUntil } from "rxjs/operators";
import { combineLatest } from "rxjs";
import { AppInjector } from "app/app.module";
@Directive()
export abstract class PaginationComponent
    extends Unsubscriber
    implements IPagination, OnInit
{
    public pageSizeOptions: Array<number>;
    public pageSize: number;
    @ViewChild(MatPaginator, { static: true })
    public paginator: MatPaginator;

    public abstract onParamsChanged(queryParams: Params, params: Params): void;

    public activatedRoute: ActivatedRoute;
    public router: Router;

    protected constructor(
        private readonly paginationService: IPaginationService
    ) {
        super();

        this.activatedRoute = AppInjector.get(ActivatedRoute);
        this.router = AppInjector.get(Router);

        this.pageSizeOptions = this.paginationService.pageSizeOptions;
    }

    public ngOnInit(): void {
        combineLatest(
            this.activatedRoute.queryParams,
            this.activatedRoute.params,
            (queryParams: Params, params: Params) => ({ queryParams, params })
        )
            .pipe(takeUntil(this.isUnsubscribing))
            .subscribe((_) => {
                const queryParams = _.queryParams;
                const params = _.params;

                this.pageSize = this.pageSizeOptions.find(
                    (p) => p === parseInt(queryParams["pageSize"])
                )
                    ? parseInt(queryParams["pageSize"])
                    : this.pageSizeOptions[0];

                this.onParamsChanged(queryParams, params);
            });

        this.paginationService.entitiesLoaded
            .pipe(takeUntil(this.isUnsubscribing))
            .subscribe((_) => {
                if (!_ || !this.paginator) return;

                this.paginator.length = _.length;
                if (this.paginator.pageIndex !== _.pageIndex) {
                    this.paginator.pageIndex = _.pageIndex;
                }
                if (this.paginator.pageSize !== _.pageSize) {
                    this.paginator.pageSize = _.pageSize;
                }
            });
    }

    public onPageChanged(evt: {
        length: number;
        pageSize: number;
        pageIndex: number;
        previousPageIndex: number;
    }) {
        this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: {
                pageSize: evt.pageSize,
                pageIndex: evt.pageIndex,
            },
            queryParamsHandling: "merge",
        });
    }
}
