import { Unsubscriber } from "../models";
import { Directive, OnInit } from "@angular/core";
import { IMetadataService } from "app/core/interfaces/iMetadataService";
import {
    IEntityDefinition,
    IEntityAttribute,
    IPossibleValueSetValue,
} from "app/core/models/base-entity/interfaces";
import { takeUntil } from "rxjs/operators";
import { BaseModel } from "app/core/models";
import { environment } from "environments/environment";
import { AppTranslationService } from "app/core/services";
import { AppInjector } from "app/app.module";

/** Base entity component. Metadata */
@Directive()
export abstract class BaseEntity<T extends BaseModel>
    extends Unsubscriber
    implements OnInit
{
    /** Entity definition */
    public entityDefinition: IEntityDefinition;
    /** Entity attributes list */
    public entityAttributes: Array<IEntityAttribute>;
    /** Attribute possible value sets */
    public attributePossibleValueSets: Array<IPossibleValueSetValue>;

    /** Entity attributes indexed by attributeID */
    public indexedEntityAttributes: { [key: string]: IEntityAttribute };
    /** Attribute possible value sets indexed by possibleVL */
    public indexedAttributePossibleValueSets: {
        [key: string]: IPossibleValueSetValue;
    };
    /** Attribute possible value sets indexed by attributeID */
    public indexedAttributeIdPossibleValueSets: {
        [key: string]: Array<IPossibleValueSetValue>;
    };

    /** Translation parameters */
    public transParams: { [key: string]: string };

    public translationService: AppTranslationService;

    protected constructor(
        private readonly metadataService: IMetadataService,
        private readonly entityType: new () => T
    ) {
        super();

        this.translationService = AppInjector.get(AppTranslationService);
    }

    public ngOnInit() {
        this.translationService.currentLanguageCode
            .pipe(takeUntil(this.isUnsubscribing))
            .subscribe((language) => {
                if (
                    language &&
                    this.metadataService.languageOfMetadata !== language
                ) {
                    this.loadMetadata(language, true);
                }
            });

        this.metadataService.asyncEntityDefinition
            .pipe(takeUntil(this.isUnsubscribing))
            .subscribe((entityDefinition) => {
                this.entityDefinition = entityDefinition;

                this.transParams = {
                    entityDS: this.entityDefinition
                        ? this.entityDefinition.entityDS
                        : "",
                };
            });

        this.metadataService.asyncEntityAttributes
            .pipe(takeUntil(this.isUnsubscribing))
            .subscribe((entityAttributes) => {
                if (entityAttributes && entityAttributes.length > 0) {
                    entityAttributes.forEach(
                        (attr) =>
                            (attr.attributePromptTX =
                                attr[
                                    `attribute${environment.attributePrompt}PromptTX`
                                ])
                    );
                }

                this.entityAttributes = entityAttributes;

                if (this.entityAttributes) {
                    this.indexedEntityAttributes = this.entityAttributes.reduce(
                        (indexedEntityAttributes, attribute) => {
                            indexedEntityAttributes[
                                attribute.attributeID.toInitLowerCase()
                            ] = attribute;
                            return indexedEntityAttributes;
                        },
                        {}
                    );
                } else {
                    this.indexedEntityAttributes = undefined;
                }
            });

        this.metadataService.asyncAttributePossibleValueSets
            .pipe(takeUntil(this.isUnsubscribing))
            .subscribe((attributePossibleValueSets) => {
                this.attributePossibleValueSets = attributePossibleValueSets;

                if (this.attributePossibleValueSets) {
                    this.indexedAttributePossibleValueSets =
                        this.attributePossibleValueSets.reduce(
                            (indexedAttributePossibleValueSets, value) => {
                                indexedAttributePossibleValueSets[
                                    value.possibleVL
                                ] = value;
                                return indexedAttributePossibleValueSets;
                            },
                            {}
                        );
                } else {
                    this.indexedAttributePossibleValueSets = undefined;
                }

                const valueAttributes = this.entityAttributes
                    ? this.entityAttributes.filter(
                          (attr) => attr.attributeTY === "VALUES"
                      )
                    : undefined;

                if (this.attributePossibleValueSets && valueAttributes) {
                    this.indexedAttributeIdPossibleValueSets = {};

                    valueAttributes.forEach((attr) => {
                        this.indexedAttributeIdPossibleValueSets[
                            attr.attributeID.toInitLowerCase()
                        ] = this.attributePossibleValueSets.filter(
                            (valueSet) =>
                                valueSet.projectCE === attr.projectCE &&
                                valueSet.possibleValueSetCE ===
                                    attr.possibleValueSetCE
                        );
                    });
                } else {
                    this.indexedAttributeIdPossibleValueSets = undefined;
                }
            });
    }

    private loadMetadata(language: string, requery?: boolean) {
        this.metadataService
            .queryMetadata(this.entityType, requery, language)
            .pipe(takeUntil(this.isUnsubscribing))
            .subscribe();
    }
}
