import { OrganizationTrans } from "app/core/models/entity-models/organizationTrans";
import { Name } from "app/core/decorators";
import { IBaseEntity } from "app/core/interfaces/IBaseEntity";

/** Organizations */
@Name("Organization")
export class Organization extends OrganizationTrans implements IBaseEntity {
    name = "Organization";
    baseEditRouteSegment = "organization";
    get id(): string {
        return this.organizationCE;
    }

    errorMessage: string;

    /** General Calendar Code */
    public generalCalendarCE: string;
    public primaryFtmsCE: string;
    public generalPreselectionPF: string;
    processTimeMethodTY: string; //"CAPACITYTIME"
    processTimeDetailTY: string; //"SHIFT";
    capacityCheckTY: string; //"CELL";
    capacityThresholdPC: number; //100;
    startOffsetTimeQT: number; //
    startOffsetTimeUnitCE: string; //"day";
    scheduleHorizonShiftNB: number; //
    scheduleHorizonHourNB: number; //
    scheduleHorizonDayNB: number; //
    scheduleHorizonWeekNB: number; //
    scheduleHorizonMonthNB: number; //
    scheduleHorizonQuarterNB: number; //
    public listItemLayout(): object {
        return {
            label: {
                property: "organizationLB",
            },
            inBrackets: {
                property: "tracingDT",
            },
            key: {
                property: "organizationCE",
            },
            title: {
                property: "organizationDS",
            },
            description: {
                property: "tracingDT",
            },
            leftBottomList: [],
            rightBottomList: [
                {
                    property: "generalCalendarCE",
                },
            ],
        };
    }
}
