import { Name } from "app/core/decorators";

/** Calendars : Work Shifts : Working Days */
@Name("CalendarWorkShiftWorkDay")
export class CalendarWorkShiftWorkDay {
    name = "CalendarWorkShiftWorkDay";
    /** Calendar Code          PK */
    public calendarCE: string;
    /** Work Shift Code        PK */
    public workShiftCE: string;
    /** Working Day Date           PK */
    public workingDayDT: Date;
    /** Shift Start Time Date */
    public shiftStartTimeDT: Date;
    /** Shift End  Time Date */
    public shiftEndTimeDT: Date;
    /** Quantity of Available Time */
    public availableTimeQT: number;
    /** Unit of Measure Code */
    public availableTimeUnitCE: string;
    private _shiftStartTS: number;
    private _shiftEndTS: number;
    public get shiftStartTS(): number
    {
        if (!this._shiftStartTS)
            this._shiftStartTS = new Date(this.shiftStartTimeDT).getTime();
        return this._shiftStartTS;
    }
    public get shiftEndTS(): number {
        if (!this._shiftEndTS)
            this._shiftEndTS = new Date(this.shiftEndTimeDT).getTime();
        return this._shiftEndTS;
    }
}
