import { ProcedureDefinitionResponse } from "./procedureDefinitionResponse";

export class EntityDefinitionResponse {
    entityId: string;
    properties: EntityDefinitionProperties;
    attributes: EntityDefinitionAttribute[];
    constraints: EntityDefinitionConstraint[];
    subSets: EntityDefinitionSubSet[];
    editableAttributes: EntityDefinitionAttribute[] =
        new Array<EntityDefinitionAttribute>();
    tracingAttributes: EntityDefinitionAttribute[] =
        new Array<EntityDefinitionAttribute>();
    virtualAttributes: EntityDefinitionAttribute[] =
        new Array<EntityDefinitionAttribute>();
    indexedAttributes: {};
    labelAttributeID: string;
    labelPrimaryKeyAttributeID: string;
    primaryKeyAttributes: EntityDefinitionAttribute[] =
        new Array<EntityDefinitionAttribute>();
    componentLayouts: {};
    attributesAreSet: boolean;
    languageCE: string;
    isStatusDriven: boolean;
    filters = new Array<EntityDefinitionAttribute>();
    rowActions: ProcedureDefinitionResponse[];
    tableActions: ProcedureDefinitionResponse[];
    get transParams() {
        return {
            entityDS: this.properties != null ? this.properties.entityDS : "",
        };
    }
    static setAttributes(definition) {
        if (definition.attributesAreSet) return;
        if (!definition.editableAttributes) {
            this;
        }
        definition.attributes.forEach(
            (attribute: EntityDefinitionAttribute) => {
                attribute.attributeID =
                    attribute.attributeID.charAt(0).toLowerCase() +
                    attribute.attributeID.slice(1);
                if (
                    attribute.attributeTY.startsWith("CREATION_") ||
                    attribute.attributeTY.startsWith("TRACING_")
                ) {
                    definition.tracingAttributes.push(attribute);
                } else {
                    if (!attribute.isAttributeForceDfltValue) {
                        definition.editableAttributes.push(attribute);
                    }
                }
                if (attribute.isLabelAttribute) {
                    definition.labelAttributeID = attribute.attributeID;
                }
                if (attribute.isVirtualAttribute) {
                    definition.virtualAttributes.push(attribute);
                }
                if (attribute.isPrimaryKeyAttribute) {
                    definition.labelPrimaryKeyAttributeID =
                        attribute.attributeID;
                }
                definition.indexedAttributes = definition.attributes.reduce(
                    (attributes, attribute) => {
                        attributes[attribute.attributeID] = attribute;
                        return attributes;
                    },
                    {}
                );
                definition.primaryKeyAttributes =
                    definition.editableAttributes.filter(
                        (attribute) => attribute.isPrimaryKeyAttribute == true
                    );
                definition.attributesAreSet = true;
            }
        );
    }
}
export class EntityDefinitionSubSet {
    constraintCE: string;
    entityID: string;
    isHiddenInUIDetails: boolean;
    entityDefinition: EntityDefinitionResponse;
    visible: boolean = false;
}
export class EntityDefinitionProperties {
    theLanguageOfTheTranslation: string;
    projectCE: string;
    entityCE: string;
    entityDS: string;
    entityHT: string;
    entityPH: string;
    entityNM: string;
    entityID: string;
    entityTY: string;
    isactive: boolean;
    islogicalDelete: boolean;
    isphysicalDelete: boolean;
    istransactionLogs: boolean;
    isentityPackage: boolean;
    isgenerateFetchWithFkRows: boolean;
    entityStatusFL1: string;
    entityStatusFL2: string;
    entityStatusFL3: string;
    isisTranslationEntity: boolean;
    primaryKeyTY: string;
    isPrimaryKeyByUser: boolean;
    pkDefaultValueAttributeCE: string;
    pkDefaultValuePackageCE: string;
    pkDefaultValueProcedureCE: string;
    pkDefaultValueParameterVL: string;
    pkDefaultValueFunctionCall: string;
    translationEntityCE: string;
    subtypeOfEntityCE: string;
    statusEntityCE: string;
    extraAttributesEntityCE: string;
    basketEntityCE: string;
    keyTranslationAttributeCE: string;
    orderByClauseTX: string;
    orderByClausePV: string;
    orderByClauseLinePV: string;
    virtualEntityPackageCE: string;
    virtualEntityProcedureCE: string;
    virtualEntityFunctionCall: string;
    entityObjectTypeNM: string;
    entityTableTypeNM: string;
    virtualEntityObjectTypeNM: string;
    virtualEntityTableTypeNM: string;
    primaryKeyConstraintCE: string;
    viewSelectStatement: string;
    viewSelectStatementPV: string;
    dfWhereClauseSourceCode: string;
    dfWhereClauseSourceCodePV: string;
    listItemLayout: any;
    detailsItemLayout: any;
    isLogicalDelete: boolean;
    isPhysicalDelete: boolean;
    icon: string;
}
export class EntityDefinitionAttribute {
    attributeCE: string;
    attributeDefinitionString: string;
    attributeDS: string;
    attributeHT: string;
    attributeID: string;
    attributeLongPromptTX: string;
    attributeMediumPromptTX: string;
    attributeNM: string;
    attributePH: string;
    attributeShortPromptTX: string;
    attributeTY: string;
    caseTY: string;
    databaseDefaultVL: string;
    dataTypeCE: string;
    defaultVL: string;
    entityID: string;
    entityPackageAttributeID: string;
    fkAttributeID: string;
    fkEntityID: string;
    fkListPreRequiredAttributeIDs: string;
    groupAttributeCE: string;
    highVL: string;
    initialVL: string;
    isActive: boolean;
    isAttributeEntityDfltValue: boolean;
    isAttributeForceDfltValue: boolean;
    isBlobContainsText: boolean;
    isCaseSensitiveSearch: boolean;
    isFK: boolean;
    isLabelAttribute: boolean;
    isNull: boolean;
    isPrimaryKeyAttribute: boolean;
    isStatusAttribute: false;
    isVirtualAttribute: boolean;
    lengthOrPrecision: number;
    lowVL: string;
    possibleValueSetCE: string;
    possibleValueSetURL: string;
    previewHypertextAttributeCE: string;
    projectCE: string;
    rangeCheckExpressionTX: string;
    scale: string;
    sequenceGeneratorCE: string;
    sequenceNO: number;
    standardAttributeCE: string;
    systemDefaultsAttributeCE: string;
    theLanguageOfTheTranslation: string;
    virtualAttributeRuleCE: string;
    isUIEditAllowed: boolean;
    isShownInUI: boolean;
}
export class EntityDefinitionConstraint {
    constraintAttributes: EntityDefinitionConstraintAttribute[];
    theLanguageOfTheTranslation: string;
    projectCE: string;
    entityCE: string;
    constraintCE: string;
    constraintDS: string;
    constraintHT: string;
    constraintPH: string;
    constraintTY: string;
    isactive: boolean;
    iscreateConstraintInDb: boolean;
    masterProjectCE: string;
    masterEntityCE: string;
    masterConstraintCE: string;
    foreignKeyTY: string;
    cardinalityTY: string;
    isidentifying: boolean;
    iscascadeDelete: boolean;
    ischeckImmediately: boolean;
    realDerivedForeignKeyTY: string;
    subtypeEntityCE: string;
}
export class EntityDefinitionConstraintAttribute {
    projectCE: string;
    entityCE: string;
    constraintCE: string;
    attributeCE: string;
    sequenceNO: string;
    masterProjectCE: string;
    masterEntityCE: string;
    masterConstraintCE: string;
    masterAttributeCE: string;
    attributeValueTY: string;
    attributeVL: string;
    defaultVL: string;
    valueCheckExpressionTX: null;
}
