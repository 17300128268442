import { Name } from "app/core/decorators";
import { ProductTrans } from "app/core/models/entity-models/productTrans";
import { IBaseEntity } from "app/core/interfaces/IBaseEntity";

/** Products */
@Name("Product")
export class Product extends ProductTrans implements IBaseEntity {
    name = "Product";
    baseEditRouteSegment = "product";

    public get id(): string {
        return this.productCE;
    }

    public errorMessage: string;

    /** Product Reference */
    public productRF: string;

    productUR: string;
    /** Procurement Type */
    public procurementTY: string;
    /** Is the Product to be Made or Purchased on Demand ? */
    public onDemandFL: string;
    /** Product Type Code */
    public productTypeCE: string;
    /** Order Policy Code */
    public orderPolicyCE: string;
    /** Stocking Unit Code */
    public stockingUnitCE: string;
    /** Lead Time */
    public leadTimeQT: number;
    /** Lead Time Unit Code */
    public leadTimeUnitCE: string;
    /** Conversion Factor for Packing Unit */
    public packingConversionFC: number;
    /** Unit of Packing Code */
    public packingConversionUnitCE: string;
    /** Conversion Factor for Length Unit */
    public lengthConversionFC: number;
    /** Unit of Length Code */
    public lengthConversionUnitCE: string;
    /** Conversion Factor for Area Unit */
    public areaConversionFC: number;
    /** Unit of Area Code */
    public areaConversionUnitCE: string;
    /** Conversion Factor for Volume Unit */
    public volumeConversionFC: number;
    /** Unit of Volume Code */
    public volumeConversionUnitCE: string;
    /** Conversion Factor for Weight Unit */
    public weightConversionFC: number;
    /** Unit of Weight Code */
    public weightConversionUnitCE: string;

    /** productCE productLB */
    public get caption(): string {
        return `${this.productCE} ${this.productLB}`;
    }

    public get onDemandFLBoolean(): boolean {
        if (this.onDemandFL === "Y") return true;
        return false;
    }

    public set onDemandFLBoolean(value: boolean) {
        if (value) this.onDemandFL = "Y";
        else this.onDemandFL = "N";
    }

    public listItemLayout(): object {
        return {
            label: { property: "productRF" },
            inBrackets: { property: "tracingDT" },
            key: { property: "productCE" },
            title: { property: "productDS" },
            description: { property: "tracingDT" },
            leftBottomList: [],
            rightBottomList: [
                { toSlice: 200, property: "procurementTY" },
                { toSlice: 200, property: "leadTimeQT" },
                { toSlice: 200, property: "stockingUnitCE" },
            ],
        };
    }
}
