import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Inject,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewChild,
    ViewContainerRef,
} from "@angular/core";
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { LazyLoaderService } from "app/layout/lazy-loading.service";
import { element } from "protractor";
import { map, Observable } from "rxjs";

@Component({
    selector: "generic-popup",
    templateUrl: "./generic-popup.component.html",
    styleUrls: ["./generic-popup.component.scss"],
})
export class GenericPopupComponent implements OnInit, AfterViewInit, OnChanges {
    public showDetails: boolean = false;
    public showMessage: boolean = false;
    public theMessage: string = "";
    componentInstance: any;

    constructor(
        private dialogRef: MatDialogRef<GenericPopupComponent>,
        private lazyLoadingService: LazyLoaderService,
        private changedDetector: ChangeDetectorRef,
        private elementRef: ElementRef,
        @Inject(MAT_DIALOG_DATA) public data?: any
    ) {
        dialogRef.disableClose=true;
        if (!data.title) {
            data.title = "";
            if (data.parentRow) {
                data.title =
                    (data.parentRow?.key || "") +
                    ":" +
                    (data.row?.parentRow || "") +
                    ":";
            }
            if (data.row) {
                data.title +=
                    (data.row?.key || "") + ":" + (data.row?.rowLB || "");
            }
        }
    }

    ngOnChanges(changes: SimpleChanges): void { }

    ngAfterViewInit(): void {
        if (this.data.module)
            this.lazyLoadingService
                .load(this.data.module, this.popupContainer)
                .then((ref) => {
                    this.componentInstance = ref.component.instance;
                    this.componentInstance.data = this.data;
                    this.componentInstance.dialogRef = this.dialogRef;
                });
        if (this.data.templateRef)
        {
            let childView =  this.templatePopupContainer.createEmbeddedView(this.data.templateRef);
            childView.detectChanges();
        }

            // this style would be applied to all '.mat-dialog-container'
            // so, moving the style in a new class and adding it only to the generic popup
            // will apply it only to the generic popup
            const elements = document.querySelectorAll('.mat-dialog-container');
        const arr = Array.from(elements);
        const el = arr[arr.length - 1];
        el.classList.add('generic-mat-dialog-container');
    }

    @ViewChild("popupContainer", { read: ViewContainerRef, static: false })
    popupContainer: ViewContainerRef;

    @ViewChild("templatePopupContainer", { read: ViewContainerRef, static: false })
    templatePopupContainer: ViewContainerRef;

    
    closeDialog(event?, save?: boolean): void {
        if (event) {
            event.stopPropagation();
        }
        if (save) {
            if (this.showMessage) {
                this.dialogRef.close(this.componentInstance.row);
            }
            else if (this.componentInstance?.save) {
                (this.componentInstance.save() as Observable<any>)
                    .pipe(
                        map((result) => {
                            if (result.message && result.message != "") {
                                this.showMessage = true;
                                this.theMessage = result.message;
                            }
                            else
                                this.dialogRef.close(this.componentInstance.row);
                            return result;
                        })
                    )
                    .subscribe();
            } else if (this.componentInstance?.dataService?.selectedRows) {
                this.dialogRef.close(
                    this.componentInstance.dataService.selectedRows
                );
            } else {
                this.dialogRef.close(true);
            }
        } else {
            this.dialogRef.close(false);
        }
    }

    ngOnInit() { }

    public get disableSave() {
        return this.componentInstance?.disableSave;
    }
}
