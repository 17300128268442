import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { filter } from "rxjs/operators";

import { FuseSidebarComponent } from "./sidebar.component";

@Injectable({
    providedIn: "root",
})
export class FuseSidebarService {
    // Private
    private _registry: { [key: string]: FuseSidebarComponent } = {};

    public onApplyFilter: BehaviorSubject<boolean>;

    public queryParams: any = {};
    /**
     * Constructor
     */
    // actual info which main component listen to
    public getQueryParams = new BehaviorSubject(null);
    public setQueryParams = new BehaviorSubject(null);
    // records how many components have updated the queryParams
    private queryParamsobserversCount = new BehaviorSubject(0);

    constructor() {
        this.onApplyFilter = new BehaviorSubject<boolean>(false);

        this.queryParamsobserversCount
            .pipe(
                filter(
                    (count) =>
                        count === this.setQueryParams.observers.length &&
                        count !== 0
                )
            )
            .subscribe(() => {
                // perform the calculations etc etc.,
                this.getQueryParams.next(this.queryParams);

                // reset the count to 0
                this.queryParamsobserversCount.next(0);
            });
    }

    public updateQueryParams(queryParams) {
        for (var attribut in queryParams) {
            this.queryParams[attribut] = queryParams[attribut];
        }

        // every time a component updates queryParams, I increment
        this.queryParamsobserversCount.next(
            this.queryParamsobserversCount.value + 1
        );
    }

    /**
     * Add the sidebar to the registry
     *
     * @param key
     * @param sidebar
     */
    register(key, sidebar): void {
        // Check if the key already being used
        if (this._registry[key]) {
            console.error(
                `The sidebar with the key '${key}' already exists. Either unregister it first or use a unique key.`
            );

            return;
        }

        // Add to the registry
        this._registry[key] = sidebar;
    }

    /**
     * Remove the sidebar from the registry
     *
     * @param key
     */
    unregister(key): void {
        // Check if the sidebar exists
        if (!this._registry[key]) {
            console.warn(
                `The sidebar with the key '${key}' doesn't exist in the registry.`
            );
        }

        // Unregister the sidebar
        delete this._registry[key];
    }

    /**
     * Return the sidebar with the given key
     *
     * @param key
     * @returns {FuseSidebarComponent}
     */
    getSidebar(key): FuseSidebarComponent {
        // Check if the sidebar exists
        if (!this._registry[key]) {
            console.warn(
                `The sidebar with the key '${key}' doesn't exist in the registry.`
            );

            return;
        }

        // Return the sidebar
        return this._registry[key];
    }
}
