import { CapacityClusterTrans } from "app/core/models/entity-models/capacityClusterTrans";
import { Name } from "app/core/decorators";
import { IBaseEntity } from "app/core/interfaces/IBaseEntity";

/** Capacity Clusters */
@Name("CapacityCluster")
export class CapacityCluster
    extends CapacityClusterTrans
    implements IBaseEntity
{
    name = "CapacityCluster";
    baseEditRouteSegment = "capacity-cluster";
    get id(): string {
        return this.capacityClusterCE;
    }
    errorMessage: string;

    /** <capacityClusterCE> <capacityClusterLB> */
    public get caption(): string {
        return `${this.capacityClusterCE} ${this.capacityClusterLB}`;
    }

    protected get displayWithChips() {
        return `${this.capacityClusterCE} - ${this.capacityClusterLB}`;
    }

    public listItemLayout(): object {
        return {
            label: { property: "capacityClusterLB" },
            inBrackets: { property: "tracingDT" },
            key: { property: "capacityClusterCE" },
            title: { property: "capacityClusterDS" },
            description: { property: "tracingDT" },
            leftBottomList: [],
            rightBottomList: [],
        };
    }
}
