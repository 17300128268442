import { IBaseEntity } from "app/core/interfaces/IBaseEntity";
import { Name } from "app/core/decorators";
import { TracingBase } from "app/core/models/tracingBase";
import { IStatusEntity } from "app/core/interfaces/IStatusEntity";
import { QrmJobTicket } from ".";
/** Sales Order Line*/
@Name("SalesOrderLine")
export class SalesOrderLine extends TracingBase implements IStatusEntity {
    name = "SalesOrderLine";
    salesOrderLineStatusCE: string;
    fwdSalesOrderLineStatusCE: string;
    revSalesOrderLineStatusCE: string;
    salesOrderLineStatusDS: string;

    public salesOrderLineStatusLB: string;
    public get statusCE(): string {
        return this.salesOrderLineStatusCE;
    }
    public set statusCE(value) {
        this.salesOrderLineStatusCE = value;
    }

    public get fwStatusCE(): string {
        return this.fwdSalesOrderLineStatusCE;
    }
    public set fwStatusCE(value) {
        this.fwdSalesOrderLineStatusCE = value;
    }
    public get revStatusCE(): string {
        return this.revSalesOrderLineStatusCE;
    }
    public set revStatusCE(value) {
        this.revSalesOrderLineStatusCE = value;
    }
    public get statusDS(): string {
        return this.salesOrderLineStatusDS;
    }
    public set statusDS(value) {
        this.salesOrderLineStatusDS = value;
    }
    public get statusLB(): string {
        return this.salesOrderLineStatusLB;
    }
    public set statusLB(value) {
        this.salesOrderLineStatusLB = value;
    }

    baseEditRouteSegment = "sales-order-line";
    public get id(): string {
        if (!this.salesOrderNO || this.salesOrderLineSQ == null) return null;
        return `${this.salesOrderNO},${this.salesOrderLineSQ}`;
    }

    public errorMessage: string;
    /** Sales Order Number */
    public salesOrderNO: string;
    /** Sales Order Line Sequence */
    public salesOrderLineSQ: string;
    /** Sales Order Line Reference */
    public salesOrderLineRF: string;
    /** Request Date */
    public requestDT:  Date;
    /** Promised Date */
    public promisedDT:  Date;
    /** Original Promised Date */
    public originalPromisedDT:  Date;
    /** Delivery Due Date */
    public deliveryDueDT:  Date;
    /** Product Code */
    public productCE: string;
    /** Sales Order Line Quantity */
    public salesOrderLineQT: number;
    /** Sales Order Line Quantity Unit Code*/
    public salesOrderLineQtUnitCE: string;

    public listItemLayout(): object {
        return {
            label: { property: "tracingDT" },
            inBrackets: { property: "tracingDT" },
            key: { property: "salesOrderNO" },
            title: { property: "productCE" },
            description: { property: "tracingDT" },
            leftBottomList: [],
            rightBottomList: [],
        };
    }
    public jobTickets: QrmJobTicket[];
    public jtsLoaded: boolean = false;
    public sldates: any[];
    public sldsLoaded: boolean = false;
}
