import { Name } from "app/core/decorators";
import { ProductStatusTrans } from "app/core/models/entity-models/productStatusTrans";
import { IBaseEntity } from "app/core/interfaces/IBaseEntity";

/** Products */
@Name("ProductStatus")
export class ProductStatus extends ProductStatusTrans implements IBaseEntity {
    name = "ProductStatus";
    public get id(): string {
        return this.productStatusCE;
    }

    public errorMessage: string;

    /** Status Sequence Number */
    public statusSQ: string;
    /** Is this Status to be used as Default Status ? */
    public defaultStatusFL: string;
    /** Force the Default at Creation ? */
    public forceDefaultAtCreationFL: string;
    /** Product Status Type */
    public productStatusTY: string;
    /** Is the Product with this Status Active ? */
    public activeFL: string;
    /** Is the Product with this Status Saleable ? */
    public saleableFL: string;
}
