import { TracingBase } from "app/core/models";
import { Name } from "app/core/decorators";
import { IBaseEntity } from "app/core/interfaces/IBaseEntity";

/** Calendars : Weekend Working Days */
@Name("CalendarWeekendWorkDay")
export class CalendarWeekendWorkDay extends TracingBase implements IBaseEntity {
    name = "CalendarWeekendWorkDay";
    get id(): string {
        if (!this.calendarCE || !this.weekendWorkingDayDT) return null;
        return `${this.calendarCE},${this.weekendWorkingDayDT}`;
    }
    errorMessage: string;

    /** Calendar Code              PK */
    public calendarCE: string;
    /** WeekendWorkingDayDT        PK */
    public weekendWorkingDayDT: Date;
}
