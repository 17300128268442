import { Navigation } from "app/layout/types/fuse-navigation";

export const navigation: Navigation[] = [
    {
        id: "NAV.CSTSLS",
        title: "NAV.CSTSLS",
        translate: "NAV.CSTSLS",
        icon: "shopping_cart",
        type: "collapsable",
        children: [
            {
                id: "NAV.CUSTOM",
                title: "NAV.CUSTOM",
                translate: "NAV.CUSTOM",
                icon: "accessibility_new",
                type: "item",
                url: "/customers",
                routerLinkActiveFor: ["/customer"],
            },
            {
                id: "NAV.SALORD",
                title: "NAV.SALORD",
                translate: "NAV.SALORD",
                icon: "shopping_cart",
                type: "item",
                url: "/sales-orders",
                routerLinkActiveFor: ["/sales-order"],
            },
            {
                id: "SALORD_LN",
                title: "NAV.SALORD_LN",
                translate: "NAV.SALORD_LN",
                icon: "shopping_basket",
                type: "item",
                url: "/sales-order-lines",
                routerLinkActiveFor: ["/sales-order-line"],
            },
            {
                id: "NAV.QCPMON",
                title: "NAV.QCPMON",
                translate: "NAV.QCPMON",
                type: "item",
                icon: "bubble_chart",
                url: "/sales-capacity-monitor",
            },
            {
                id: "NAV.PRODUCT",
                title: "NAV.PRODUCT",
                translate: "NAV.PRODUCT",
                icon: "business",
                type: "item",
                url: "/products",
            },
            {
                id: "NAV.DASHBOARD",
                title: "NAV.DASHBOARD",
                translate: "NAV.DASHBOARD",
                type: "item",
                icon: "dashboard",
                url: "/sales-dashboard",
            },
            {
                id: "NAV.PROJEC",
                title: "NAV.PROJEC",
                translate: "NAV.PROJEC",
                icon: "folder",
                type: "collapsable",
                children: [
                    {
                        id: "NAV.PROJEC",
                        title: "NAV.PROJEC",
                        translate: "NAV.PROJEC",
                        icon: "folder",
                        type: "item",
                        url: "/projects",
                        routerLinkActiveFor: ["/project"],
                    },
                    {
                        id: "NAV.PRJBGTV",
                        title: "NAV.PROJEC_BV",
                        translate: "NAV.PROJEC_BV",
                        icon: "monetization_on",
                        type: "item",
                        url: "/project-budget-versions",
                        routerLinkActiveFor: ["/project-budget-version"],
                    },
                    {
                        id: "NAV.EMPL",
                        title: "NAV.EMPLOY",
                        translate: "NAV.EMPLOY",
                        icon: "supervisor_account",
                        type: "item",
                        url: "/employees",
                        routerLinkActiveFor: ["/employee"],
                    },
                ],
            },
        ],
    },
    {
        id: "NAV.PLANNING",
        title: "NAV.PLANNING",
        translate: "NAV.PLANNING",
        icon: "memory",
        type: "collapsable",
        children: [
            {
                id: "NAV.QCPMON",
                title: "NAV.QCPMON",
                translate: "NAV.QCPMON",
                type: "item",
                icon: "bubble_chart",
                url: "/capacity-monitor",
            },
            {
                id: "NAV.QJBTCK",
                title: "NAV.QJBTCK",
                translate: "NAV.QJBTCK",
                type: "item",
                icon: "dns",
                url: "/tickets",
                routerLinkActiveFor: [
                    "/ticket",
                    "/ticket-activities",
                    "/ticket-activity",
                ],
            },
        ],
    },
    {
        id: "NAV.APP",
        title: "NAV.EXECUTION",
        translate: "NAV.EXECUTION",
        icon: "memory",
        type: "collapsable",
        children: [
            {
                id: "NAV.DASHBOARD",
                title: "NAV.DASHBOARD",
                translate: "NAV.DASHBOARD",
                type: "item",
                icon: "dashboard",
                url: "/polca-dashboard",
            },
            {
                id: "NAV.POLCA",
                title: "NAV.POLCA",
                translate: "NAV.POLCA",
                type: "item",
                icon: "credit_card",
                url: "/digital-polca",
            },
        ],
    },
    {
        id: "NAV.MANAGEMENT",
        title: "NAV.MANAGEMENT",
        translate: "NAV.MANAGEMENT",
        icon: "memory",
        type: "collapsable",
        children: [
            {
                id: "NAV.QJBTCK_MP",
                title: "NAV.QJBTCK_MP",
                translate: "NAV.QJBTCK_MP",
                type: "item",
                icon: "view_week",
                url: "/mct-map",
            },
            {
                id: "NAV.DASHBOARD",
                title: "NAV.DASHBOARD",
                translate: "NAV.DASHBOARD",
                type: "item",
                icon: "dashboard",
                url: "/dashboard",
            },
        ],
    },
    {
        id: "NAV.ADMIN",
        title: "NAV.ADMIN",
        translate: "NAV.ADMIN",
        icon: "memory",
        type: "collapsable",
        children: [
            {
                id: "NAV.PRODUCT",
                title: "NAV.PRODUCT",
                translate: "NAV.PRODUCT",
                icon: "business",
                type: "collapsable",
                children: [
                    {
                        id: "NAV.QPRODU",
                        title: "NAV.QPRODU",
                        translate: "NAV.QPRODU",
                        type: "item",
                        icon: "business",
                        url: "/qrm-products",
                        routerLinkActiveFor: ["/qrm-product"],
                    },
                    {
                        id: "NAV.QPRDFA",
                        title: "NAV.QPRDFA",
                        translate: "NAV.QPRDFA",
                        type: "item",
                        icon: "transform",
                        url: "/qrm-product-families",
                        routerLinkActiveFor: ["/qrm-product-family"],
                    },
                    {
                        id: "NAV.QPRODU_BM",
                        title: "NAV.QPRODU_BM",
                        translate: "NAV.QPRODU_BM",
                        type: "item",
                        icon: "art_track",
                        url: "/qrm-product-boms",
                        routerLinkActiveFor: ["/qrm-product-bom"],
                    },
                ],
            },
            {
                id: "NAV.CAPACITY",
                title: "NAV.CAPACITY",
                translate: "NAV.CAPACITY",
                icon: "memory",
                type: "collapsable",
                children: [
                    {
                        id: "NAV.QRCELL",
                        title: "NAV.QRCELL",
                        translate: "NAV.QRCELL",
                        type: "item",
                        icon: "widgets",
                        url: "/qrm-cells",
                        routerLinkActiveFor: ["/qrm-cell"],
                    },
                    {
                        id: "NAV.CAPCLU",
                        title: "NAV.CAPCLU",
                        translate: "NAV.CAPCLU",
                        type: "item",
                        icon: "memory",
                        url: "/capacity-clusters",
                        routerLinkActiveFor: ["/capacity-cluster"],
                    },
                    {
                        id: "NAV.QRCCRD",
                        title: "NAV.QRCCRD",
                        translate: "NAV.QRCCRD",
                        type: "item",
                        icon: "call_split",
                        url: "/qrm-routing-capacities",
                        routerLinkActiveFor: ["/qrm-routing-capacity"],
                    },
                    {
                        id: "NAV.QRCELL_RC",
                        title: "NAV.QRCELL_RC",
                        translate: "NAV.QRCELL_RC",
                        type: "item",
                        icon: "all_out",
                        url: "/qrm-cell-routing-capacity",
                    },
                    {
                        id: "NAV.QRCELL_CD",
                        title: "NAV.QRCELL_CD",
                        translate: "NAV.QRCELL_CD",
                        type: "item",
                        icon: "call_missed",
                        url: "/qrm-temp-capacity-deviations",
                        routerLinkActiveFor: ["/qrm-temp-capacity-deviation"],
                    },
                    {
                        id: "NAV.CALEND",
                        title: "NAV.CALEND",
                        translate: "NAV.CALEND",
                        icon: "date_range",
                        type: "item",
                        url: "/calendars",
                        routerLinkActiveFor: ["/calendar"],
                    },
                    {
                        id: "NAV.WSHIFT",
                        title: "NAV.WSHIFT",
                        translate: "NAV.WSHIFT",
                        icon: "query_builder",
                        type: "item",
                        url: "/work-shifts",
                        routerLinkActiveFor: ["/work-shift"],
                    },
                ],
            },

            {
                id: "NAV.UNITS",
                title: "NAV.UNITS",
                translate: "NAV.UNITS",
                icon: "category",
                type: "collapsable",
                children: [
                    {
                        id: "NAV.UNTMSR",
                        title: "NAV.UNTMSR",
                        icon: "category",
                        type: "item",
                        url: "/units-of-measure",
                        routerLinkActiveFor: ["/unit-of-measure"],
                    },
                    {
                        id: "NAV.UNTMSR_CV",
                        title: "NAV.UNTMSR_CV",
                        icon: "compare_arrows",
                        type: "item",
                        url: "/units-of-measure-conversion",
                        routerLinkActiveFor: ["/unit-of-measure-conversion"],
                    },
                ],
            },
            {
                id: "NAV.GENERAL",
                title: "NAV.GENERAL",
                icon: "note",
                type: "collapsable",
                children: [
                    {
                        id: "NAV.QJBTCK_ST",
                        title: "NAV.QJBTCK_ST",
                        type: "item",
                        icon: "restore",
                        url: "/ticket-statuses",
                    },
                    {
                        id: "ORG",
                        title: "NAV.ORGANI",
                        type: "item",
                        icon: "note",
                        url: "/organizations",
                        routerLinkActiveFor: ["/organization"],
                    },
                    {
                        id: "LANG",
                        title: "NAV.LANGUA",
                        type: "item",
                        icon: "language",
                        url: "/languages",
                        routerLinkActiveFor: ["/language"],
                    },
                    {
                        id: "SYSPR",
                        title: "NAV.SYSPRE",
                        type: "item",
                        icon: "computer",
                        url: "/system-prefixes",
                        routerLinkActiveFor: ["/system-prefix"],
                    },
                    {
                        id: "FTMS",
                        title: "NAV.FOTMAS",
                        type: "item",
                        icon: "my_location",
                        url: "/focused-trg-market-sgms",
                        routerLinkActiveFor: ["/focused-trg-market-sgm"],
                    },
                    {
                        id: "VSTL",
                        title: "NAV.VISSTY",
                        type: "item",
                        icon: "remove_red_eye",
                        url: "/visual-styles",
                        routerLinkActiveFor: ["/visual-style"],
                    },
                    {
                        id: "ADMIN",
                        title: "NAV.ADMIN",
                        type: "item",
                        icon: "pencil",
                        url: "/admin-panel",
                        routerLinkActiveFor: ["/admin-panel"],
                    },
                ],
            },
        ],
    },
];
