import { Directive, ElementRef } from "@angular/core";
import { Router } from "@angular/router";
import { Platform } from "@angular/cdk/platform";

import { FuseConfigService } from "app/core/@fuse/services/config.service";
import { FusePerfectScrollbarDirective } from "app/core/@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive";

@Directive({
    selector: "[layoutPerfectScrollbar]"
})
export class LayoutPerfectScrollbarDirective extends FusePerfectScrollbarDirective {

    /**
     * Constructor
     *
     * @param {ElementRef} elementRef
     * @param {FuseConfigService} _fuseConfigService
     * @param {Platform} _platform
     * @param {Router} _router
     */
    constructor(
        public elementRef: ElementRef,
        private readonly fuseConfigService: FuseConfigService,
        private readonly platform: Platform,
        private readonly router: Router
    ) {
        super(elementRef, fuseConfigService, platform, router);
    }


    /**
     * After view init
     */
    public ngAfterViewInit(): void {
        super.ngAfterViewInit();

        const elementResizeEvent = require("element-resize-event");
        const children = Array.from(this.elementRef.nativeElement.children);
        children.forEach((child: HTMLElement) => elementResizeEvent(child, () => {
            this.update();
        }));
    }
}
