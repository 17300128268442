import { TracingBase } from "app/core/models";
import { Name } from "app/core/decorators";
import { IStatusEntity } from "app/core/interfaces/IStatusEntity";
import { QrmJobTicketConnection } from "app/core/models/entity-models/qrmJobTicketConnection";
import { QrmJbtSalesOrderLine } from "app/core/models/entity-models/qrmJbtSalesOrderLine";
import { SalesOrder } from "app/core/models/entity-models/salesOrder";
import { QrmJobTicketConnectionFull } from ".";
import { QrmJobTicketActivity } from "./qrmJobTicketActivity";
import { jobTicketResourceGroup } from "./jobTicketResourceGrp";
import { jobTicketText } from "./jobTicketText";
import { TranslatePipe } from "../../../core/lib/translate/public_api";

/** QRM Job Tickets */
@Name("QrmJobTicket")
export class QrmJobTicket extends TracingBase implements IStatusEntity {
    name = "QrmJobTicket";
    baseEditRouteSegment = "ticket";
    /** QRM Job Ticket Number                                           PK */
    public qrmJobTicketNO: string;
    /** QRM Job Ticket Status Code */
    public qrmJobTicketStatusCE: string;
    /** Forward QRM Job Ticket Status Code                              Virtual attribute */
    public fwdQrmJobTicketStatusCE: string;
    /** Reverse QRM Job Ticket Status Code                              Virtual attribute */
    public revQrmJobTicketStatusCE: string;
    /** QRM Job Ticket Usage Type */
    public qrmJobTicketUsageTY: string;
    /** Is this a QRM Job Ticket for a Rush Job? */
    public qrmRushJobFL: string;
    /** Is the Required Extra Material Available? */
    public materialAvailableFL: string;
    /** QRM Job Ticket Reference */
    public qrmJobTicketRF: string;
    /** QRM Job Ticket Description */
    public qrmJobTicketDS: string;
    /** QRM Job Ticket Hypertext */
    public qrmJobTicketHT: string;
    /** QRM Job Ticket Drawing (Hyperlink) */
    public qrmJobTicketDrawingUR: string;
    /** Is the QRM Job Ticket the Master of the QRM Job Ticket Chain? */
    public qrmJobTicketMasterFL: string;
    /** Master QRM Job Ticket Number                                    Virtual attribute */
    public masterQrmJobTicketNO: string;
    /** QRM Product Code */
    public qrmProductCE: string;
    public QrmProductBomSQ: number;
    /** QRM Job Ticket Quantity */
    public qrmJobTicketQT: number;
    /** Unit of Measure Code */
    public qrmJobTicketQtUnitCE: string;
    /** QRM Cell Code */
    public qrmCellCE: string;
    /** Needed Capacity Cluster Quantity for the QRM Job Ticket */
    public capacityClusterQT: number;
    /** Capacity Cluster Code */
    public capacityClusterCE: string;
    /** Expected Amount of Processing Time */
    public processTimeQT: number;
    /** Expected Amount of Processing Time Unit Code */
    public processTimeUnitCE: string;
    /** Expected Processing Time Type */
    public processTimeTY: string;
    /** Authorization Date */
    public authorizationDT: Date;
    /** Expected Completion Date                                       Virtual attribute */
    public scheduledCompletionDT: Date;
    /** Earliest Start Date */
    public earliestStartDT: Date;
    /** Latest Start Date */
    public latestStartDT: Date;
    /** Actual Start Date */
    public actualStartDT: Date;
    /** Actual Completion Date */
    public actualCompletionDT: Date;
    /** Total Number of Upstream QRM Job Tickets                       Virtual attribute */
    public totalUpQrmJobTicketNB: number;
    /** Number of Finished Upstream QRM Job Tickets                    Virtual attribute */
    public finishedUpQrmJobTicketNB: number;
    /** Expected Upstream Completion Date                              Virtual attribute */
    public expectedUpCompletionDT: Date;
    /** Downstream QRM Job Ticket Number                               Virtual attribute */
    public downstreamQrmJobTicketNO: string;
    public upstreamTicketNoLST: string;
    /** QRM Cell Code of the Downstream QRM Job Ticket                 Virtual attribute */
    public downstreamQrmCellCE: string;
    /** Needed Routing Capacity (Polca) Card Number                    Virtual attribute */
    public neededRoutCapacityCardNB: number;
    /** Attached Routing Capacity (Polca) Card Number                  Virtual attribute */
    public attachedRoutCapacityCardNB: number;
    /** Expected Touch Time Percentage                                 Virtual attribute */
    public scheduledTouchTimePC: number;
    /** Actual Touch Time Percentage                                   Virtual attribute */
    public actualTouchTimePC: number;
    public qrmCellJobTicketClusterID: string;
    isLoading: boolean;
    isExpanded: boolean = false;
    isActivityExpanded: boolean = false;
    isResourceExpanded: boolean = false;
    isTextExpanded: boolean = false;
    selected: boolean = false;
    showSelected: boolean = false;
    qrmCell: any;
    downstreamQrmCell: any;
    qrmProduct: any;
    jtqUnitOfMeasure: any;
    xptUnitOfMeasure: any;
    capacityCluster: any;
    altProcessTimeQT: number;
    altProcessTimeUnitCE: string;
    expectedStartDT: Date;
    expectedCompletionDT: Date;
    actualProcessTimeQT: number;
    qrmJobTicketQueueTY: string;
    qrmJobTicketAttentionTY: string;
    qrmJobTicketAttentionCE: string;
    attentionReasonCE: string;
    indexNr: number;
    public get statusColor(): string {
        let rt = "inherit";
        if (this.qrmJobTicketStatusCE == '25') rt = 'red';
        if (this.qrmJobTicketStatusCE == '50') rt = 'orange';
        if (this.qrmJobTicketStatusCE == '60') rt = 'yellow';
        if (this.qrmJobTicketStatusCE == '65') rt = 'lightgreen';
        if (this.qrmJobTicketStatusCE == '70') rt = 'green';
        return rt;
    }
    public cellBackgrounColor:string
    public cellBackgrounColorShadow: string
    public cellColor: string
    private _hasQrmJobTicketActivity: boolean = false;
    public get hasQrmJobTicketActivity(): boolean {
        return this._hasQrmJobTicketActivity;
    }// custom
    public get polcaCardTooltip() : string {
        var rt = this.qrmCellCE + ' ' + this["qrmCellCELB"] + ' -> ';
        if (this.downstreamQrmCellCE) {
            rt += this["downstreamQrmCellCELB"];
        }
        else
            rt += '#[QJBTCK.FINISH]#';
        return rt;
    }
    public get dpToolTipText(): string {
        var rt = '';
        if (this["salesOrderNOLB"])
            rt = this["salesOrderNOLB"];
        else {

        }
        return rt;
    }
    public get dpHeaderTextBold(): string {
        var rt = '';
        rt = this.qrmJobTicketQT + " " + this.qrmJobTicketQtUnitCE;
        if (this["salesOrderLineSQLB"])
            rt = this["salesOrderLineSQLB"];
        return rt;
    }
    public get dpHeaderText1(): string {
        var rt = '';
        rt = this.qrmJobTicketRF && this.qrmJobTicketRF != this.qrmJobTicketDS ? this.qrmJobTicketRF : '';
        return rt;
    }
    public get dpHeaderText2(): string {
        var rt = '';
        //rt = this.qrmJobTicketDS ? this.qrmJobTicketDS : ''; //this["qrmProductCELB"];
        //rt = this.qrmJobTicketQT + " " + this.qrmJobTicketQtUnitCE;
        //if (this["salesOrderLineSQLB"])
        //    rt = this["salesOrderLineSQLB"];
        return rt;
    }
    public get dpHeaderText3(): string {
        var rt = this.qrmJobTicketQT + ' ' + this.qrmJobTicketQtUnitCE + ' ' + (this.qrmJobTicketDS ? this.qrmJobTicketDS : '');
        if (this["salesOrderLineSQRow"])
            if (this["salesOrderLineSQRow"]["serialReference"]) rt += ' Serial: ' + this["salesOrderLineSQRow"]["serialReference"];
        //rt = this.qrmJobTicketQT + " " + this.qrmJobTicketQtUnitCE;
        //if (this["salesOrderLineSQLB"])
        //    rt = this["salesOrderLineSQLB"];
        return rt;
    }
    /** qrmJobTicketNO qrmJobTicketDS */
    public get caption(): string {
        return `${this.qrmJobTicketNO}${this.qrmJobTicketDS ? " " : ""}${
            this.qrmJobTicketDS ? this.qrmJobTicketDS.slice(0, 150) : ""
        }${
            this.qrmJobTicketDS && this.qrmJobTicketDS.length > 150 ? "..." : ""
        }`;
    }

    /** qrmJobTicketNO qrmJobTicketDS */
    public get shortCaption(): string {
        return `${this.qrmJobTicketNO}${this.qrmJobTicketDS ? " " : ""}${
            this.qrmJobTicketDS ? this.qrmJobTicketDS.slice(0, 30) : ""
        }${
            this.qrmJobTicketDS && this.qrmJobTicketDS.length > 30 ? "..." : ""
        }`;
    }

    //#region state

    public get qrmJobTicketIsRushOrder(): boolean {
        return this.qrmRushJobFL === "Y";
    }

    public get qrmJobTicketMaterialIssue(): boolean {
        return this.materialAvailableFL !== "Y";
    }

    public get qrmJobTicketIsAuthorized(): boolean {
        if (!this.authorizationDT) {
            return false;
        }

        return new Date(this.authorizationDT).getTime() <= new Date().getTime();
    }

    public get qrmJobTicketStartedIssue(): boolean {
        if (!this.qrmJobTicketIsAuthorized) {
            return false;
        }

        const state =
            (this.scheduledCompletionDT &&
                new Date(this.scheduledCompletionDT).getTime() <=
                    new Date().getTime() &&
                !this.actualStartDT) ||
            (this.latestStartDT &&
                new Date(this.latestStartDT).getTime() <=
                    new Date().getTime() &&
                !this.actualStartDT);

        return state;
    }

    public get qrmJobTicketStartedWarning(): boolean {
        if (!this.qrmJobTicketIsAuthorized) {
            return false;
        }

        if (!this.actualStartDT) {
            return false;
        }

        const state =
            new Date(this.actualStartDT).getTime() >
                new Date(this.latestStartDT).getTime() ||
            new Date(this.actualStartDT).getTime() <
                new Date(this.earliestStartDT).getTime();

        return state;
    }

    public get qrmJobTicketCompletedIssue(): boolean {
        if (!this.qrmJobTicketIsAuthorized) {
            return false;
        }

        const state =
            this.scheduledCompletionDT &&
            new Date(this.scheduledCompletionDT).getTime() <
                new Date().getTime() &&
            !this.actualCompletionDT;

        return state;
    }

    public get qrmJobTicketCompletedWarning(): boolean {
        if (!this.qrmJobTicketIsAuthorized) {
            return false;
        }

        const state =
            new Date(this.actualCompletionDT).getTime() >
            new Date(this.scheduledCompletionDT).getTime();

        return state;
    }

    public get qrmJobTicketWarning(): boolean {
        const state = this.qrmJobTicketAttentionTY == "WARNING";

        return state;
    }

    public get qrmJobTicketIssue(): boolean {
        const state = this.qrmJobTicketAttentionTY == "ISSUE";
        return state;
    }

    public get qrmJobTicketOk(): boolean {
        const state = this.qrmJobTicketAttentionTY == "NOISSUE";

        return state;
    }

    //#endregion

    //added props
    public qrmJobTicketStatusDS: string;
    public get statusDS(): string {
        return this.qrmJobTicketStatusDS;
    }
    public set statusDS(value) {
        this.qrmJobTicketStatusDS = value;
    }
    public qrmJobTicketStatusLB: string;
    public get statusLB(): string {
        return this.qrmJobTicketStatusLB;
    }
    public set statusLB(value) {
        this.qrmJobTicketStatusLB = value;
    }

    public get rushOrderTicketFLBoolean(): boolean {
        return this.qrmRushJobFL === "Y";
    }
    public set rushOrderTicketFLBoolean(value: boolean) {
        this.qrmRushJobFL = value ? "Y" : "N";
    }
    public get materialAvailableFLBoolean(): boolean {
        return this.materialAvailableFL === "Y";
    }
    public set materialAvailableFLBoolean(value: boolean) {
        this.materialAvailableFL = value ? "Y" : "N";
    }
    public get notStarted() {
        let rt: boolean = false;
        if (this.qrmJobTicketStatusCE == "25" || this.qrmJobTicketStatusCE == "50") {
            rt = true;
        }
        return rt;
    }
    public get ended() {
        let rt: boolean = false;
        if (this.qrmJobTicketStatusCE == "65" || this.qrmJobTicketStatusCE == "70" || this.qrmJobTicketStatusCE == "80") {
            rt = true;
        }
        return rt;
    }

    public get id(): string {
        return this.qrmJobTicketNO;
    }

    public set id(value: string) {
        this.qrmJobTicketNO = value;
    }

    public errorMessage: string;

    public get statusCE(): string {
        return this.qrmJobTicketStatusCE;
    }
    public set statusCE(value) {
        this.qrmJobTicketStatusCE = value;
    }

    public get fwStatusCE(): string {
        return this.fwdQrmJobTicketStatusCE;
    }
    public set fwStatusCE(value) {
        this.fwdQrmJobTicketStatusCE = value;
    }
    public get revStatusCE(): string {
        return this.revQrmJobTicketStatusCE;
    }
    public set revStatusCE(value) {
        this.revQrmJobTicketStatusCE = value;
    }

    public get unitCE(): string {
        return this.qrmJobTicketQtUnitCE;
    }
    public set unitCE(value) {
        this.qrmJobTicketQtUnitCE = value;
    }

    public get secondUnitCE(): string {
        return this.processTimeUnitCE;
    }
    public set secondUnitCE(value) {
        this.processTimeUnitCE = value;
    }

    public upstreamQrmJobTicket: QrmJobTicketConnectionFull[];
    public downstreamQrmJobTicket: QrmJobTicketConnectionFull[];
    public qrmJobTicketPart: any[]
    public masterQrmJobTicket: QrmJobTicketConnectionFull;
    public salesOrderLine: QrmJbtSalesOrderLine;
    public salesOrder: SalesOrder;
    public masterSalesOrderLine: QrmJbtSalesOrderLine;
    public masterSalesOrder: SalesOrder;
    public isAnalysisTY = (): boolean => this.qrmJobTicketUsageTY === "ANAL";
    public loadedSales: boolean = false;
    public loadedProduct: boolean = false;
    public loadedRelations: boolean = false;
    public loadedParts: boolean = false;
    public loadedTexts: boolean = false;
    public loadedResGrp: boolean = false;
    public loadedActivities: boolean = false;
    public isInStatusChange: boolean = false;

    public children: Array<QrmJobTicket>;
    public _activities: Array<any>;
    public _resources: Array<any>;
    public _resgrp: Array<jobTicketResourceGroup>;
    public _texts: Array<jobTicketText>;
    public get resources() {
        return this._resources;
    }
    public set resources(val: any[]) {
        this._resources = val;
    }
    public get texts() {
        return this._texts;
    }
    public set texts(val: any[]) {
        this._texts = val;
    }
    public get resgrp() {
        return this._resgrp;
    }
    public set resgrp(val: any[]) {
        this._resgrp = val;
    }
    public get totalResources(): number {
        var tmp = 0;
        if (this._resources) tmp += this._resources.length;
        if (this._resgrp) tmp += this._resgrp.length;
        return tmp;
    }
    public get totalTexts(): number {
        var tmp = 1;
        if (this._texts) tmp += this._texts.length;
        return tmp;
    }
    public get assignedResources(): number {
        var tmp = 0;
        if (this._resources) tmp += this._resources.length;
        if (this._resgrp) tmp += this._resgrp.filter(_ => _.resourceItemID).length;
        return tmp;
    }
    public get activities() {
        return this._activities;
    }
    public get touchactivities() {
        if (this._activities)
            return this._activities.filter(_ => _.touchTimeFL == 'Y');
        else
            return this._activities;
    }
    public get nontouchactivities() {
        if (this._activities)
            return this._activities.filter(_ => _.touchTimeFL == 'N');
        else
            return this._activities;
    }
    public set activities(val: any[]) {
        this._activities = val;
        if (this.touchactivities)
            this._hasQrmJobTicketActivity = this.touchactivities.some(x => x.activityEndDT == null && x.measuringMethodTY != 'DURATION');
        else this._hasQrmJobTicketActivity = false;
        if (this.touchactivities) {
            this._totalActivityTime = this.touchactivities.map((at: QrmJobTicketActivity) => {
                return at.duration;
            }).reduce((a, b) => a + b, 0);
        }
        else this._totalActivityTime = 0;
    }

    private _totalActivityTime: number = 0;
    public get totalActivityTime(): number {
        return this._totalActivityTime;
    }

    public get title(): string {
        return `${this.qrmJobTicketNO}(${this.qrmJobTicketDS})`;
    }

    public listItemLayout(): object {
        return {
            label: { property: "tracingDT" },
            inBrackets: { property: "statusDS" },
            key: { property: "qrmJobTicketNO" },
            title: { property: "caption" },
            description: { property: "qrmJobTicketDS" },
            leftBottomList: [],
            rightBottomList: [],
        };
    }
}
