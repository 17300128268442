import { NgModule } from '@angular/core';
import { LowerCaseDirective } from './directive/lower-case-input.directive';
import { UpperCaseDirective } from './directive/upper-case-input.directive';
import { VariableCaseDirective } from './directive/variable-case-input.directive';


@NgModule({
    imports: [
    ],
    declarations: [LowerCaseDirective, UpperCaseDirective, VariableCaseDirective],
    exports: [LowerCaseDirective, UpperCaseDirective, VariableCaseDirective]
})
export class InputCaseModule { }