import { TransTracingBase } from "app/core/models";
import { Name } from "app/core/decorators";

/** Project (member) Roles - Translations */
@Name("ProjectRoleTrans")
export class ProjectRoleTrans extends TransTracingBase {
    name = "ProjectRoleTrans";
    /** Project Role Code                 PK */
    public projectRoleCE: string;
    /** Project Role Label */
    public projectRoleLB: string;
    /** Project Role Description */
    public projectRoleDS: string;
    /** Project Role Info (Hyperlink) */
    public projectRoleUR: string;
    /** Project Role Info (Hypertext) */
    public projectRoleHT: string;
}
