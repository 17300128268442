import { ChangeDetectorRef, Component, Input } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { merge, Subject } from "rxjs";
import { takeUntil, filter } from "rxjs/operators";

import { fuseAnimations } from "app/core/@fuse/animations";
import { FuseNavigationService } from "app/core/@fuse/components/navigation/navigation.service";
import { FuseNavVerticalCollapsableComponent } from "app/core/@fuse/components/navigation/vertical/collapsable/collapsable.component";
import { NavigationItem } from "app/layout/types/fuse-navigation";

@Component({
    selector: "nav-vertical-collapsable",
    templateUrl: "./collapsable.component.html",
    styleUrls: ["./collapsable.component.scss"],
    animations: fuseAnimations,
})
export class NavVerticalCollapsableComponent extends FuseNavVerticalCollapsableComponent {
    @Input()
    public item: NavigationItem;

    // Private
    private readonly unsubscribeAll: Subject<any>;

    constructor(
        private readonly changeDetectorRef: ChangeDetectorRef,
        private readonly fuseNavigationService: FuseNavigationService,
        private readonly router: Router
    ) {
        super(changeDetectorRef, fuseNavigationService, router);

        // Set the private defaults
        this.unsubscribeAll = new Subject();
    }

    public ngOnInit(): void {
        super.ngOnInit();

        //collapse route opened item when expanding other item
        this.fuseNavigationService.onItemCollapsed
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((clickedItem) => {
                if (clickedItem && clickedItem.children) {
                    if (
                        this.item !== clickedItem &&
                        this.isUrlInChildren(this.item, this.router.url)
                    ) {
                        // this.collapse();
                    }
                }
            });

        // Subscribe to navigation item
        merge(
            this.fuseNavigationService.onNavigationItemAdded,
            this.fuseNavigationService.onNavigationItemUpdated,
            this.fuseNavigationService.onNavigationItemRemoved
        )
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe(() => {
                this.checkToExpand();
                // Mark for check
                this.changeDetectorRef.markForCheck();
            });

        this.router.events
            .pipe(filter((_) => _ instanceof NavigationEnd))
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((event: NavigationEnd) => {
                const isActiveForOtherRoute = this.setActiveForOtherRoute();
                if (isActiveForOtherRoute) {
                    this.expand();
                }
            });
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();
        // Unsubscribe from all subscriptions
        this.unsubscribeAll.next({});
        this.unsubscribeAll.complete();
    }

    private checkToExpand() {
        // Check if the url can be found in
        // one of the children of this item
        if (
            this.isUrlInChildren(this.item, this.router.url) ||
            this.setActiveForOtherRoute()
        ) {
            this.expand();
        } else {
            this.collapse();
        }
    }

    private setActiveForOtherRoute(): boolean {
        if (!this.item.children) {
            return false;
        }
        const otherLinks = this.item.children
            .filter((c) => (c as NavigationItem).routerLinkActiveFor)
            .map((x) => (x as NavigationItem).routerLinkActiveFor)
            .flat();

        if (!otherLinks || otherLinks.length === 0) {
            return false;
        }

        const link = this.router.url
            .split("/")
            .filter((s) => s)[0]
            .split("?")[0];

        const isActiveForOtherRoute =
            otherLinks.find(
                (route) => link === route.split("/").filter((s) => s)[0]
            ) !== undefined;

        return isActiveForOtherRoute;
    }
}
