import { TransTracingBase } from "app/core/models";
import { Name } from "app/core/decorators";

/** Visual Styles - Translations */
@Name("VisualStyleTrans")
export class VisualStyleTrans extends TransTracingBase {
    name = "VisualStyleTrans";
    /** Visual Style Code                                PK */
    public visualStyleCE: string;
    /** Visual Style Label */
    public visualStyleLB: string;
    /** Visual Style Description */
    public visualStyleDS: string;
    /** Visual Style Info (Hyperlinkk) */
    public visualStyleUR: string;
    /** Visual Style Info (Hypertext) */
    public visualStyleHT: string;
}
