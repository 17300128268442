import { AutoRefreshComponent } from ".";
import { ChangeDetectorRef, Directive, OnInit, ViewChild } from "@angular/core";
import { MatLegacyPaginator as MatPaginator } from "@angular/material/legacy-paginator";
import { IPaginationService, IPagination } from "../interfaces";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { concatMap, map, switchMap, takeUntil } from "rxjs/operators";
import { combineLatest, forkJoin, Observable, of } from "rxjs";
import { AppInjector } from "app/app.module";
import { IEntityAttribute } from "app/core/models/base-entity/interfaces";
import { PageEvent } from "@angular/material/paginator";
@Directive()
export abstract class PaginationAutoRefreshComponent
    extends AutoRefreshComponent
    implements IPagination, OnInit
{
    public pageSizeOptions: Array<number>;
    public pageSize: number;
    @ViewChild(MatPaginator, { static: true })
    public paginator: MatPaginator;
    filter: unknown;
    procedureParameters: unknown;
    entityAttributes: { [key: string]: IEntityAttribute };
    metaData: unknown;

    public abstract onParamsChanged(queryParams: Params, params: Params): void;

    public activatedRoute: ActivatedRoute;
    public router: Router;

    protected constructor(
        private readonly paginationService: IPaginationService,
        public changeDetectorRef?: ChangeDetectorRef,
        public defaultFilters?: any
    ) {
        super(paginationService);

        this.activatedRoute = AppInjector.get(ActivatedRoute);
        this.router = AppInjector.get(Router);

        this.pageSizeOptions = this.paginationService.pageSizeOptions;
    }

    public ngOnInit(): void {
        combineLatest(
            this.activatedRoute.queryParams,
            this.activatedRoute.params,
            (queryParams: Params, params: Params) => ({ queryParams, params })
        )
            .pipe(
                takeUntil(this.isUnsubscribing),
                switchMap((_: any) => {
                    const queryParams = _.queryParams;
                    const params = _.params;

                    this.pageSize = this.pageSizeOptions.find(
                        (p) => p === parseInt(queryParams["pageSize"])
                    )
                        ? parseInt(queryParams["pageSize"])
                        : this.pageSizeOptions[0];

                    return this.getDefaultFilter(queryParams).pipe(
                        switchMap((filter) => {
                            this.onParamsChanged(queryParams, params);
                            return of(true);
                        })
                    );
                })
            )
            .subscribe();

        this.paginationService.entitiesLoaded
            .pipe(takeUntil(this.isUnsubscribing))
            .subscribe((_) => {
                if (!_ || !this.paginator) return;

                this.paginator.length = _.length;
                if (this.paginator.pageIndex !== _.pageIndex) {
                    this.paginator.pageIndex = _.pageIndex;
                }
                if (this.paginator.pageSize !== _.pageSize) {
                    this.paginator.pageSize = _.pageSize;
                }
            });
    }

    getMetaData() {
        return this.paginationService.metaDataReady.pipe(
            concatMap((metaData) => {
                if (!metaData) return of(null);
                this.metaData = metaData;
                //this.entityAttributesObs = metadata.entityAttributes;
                return forkJoin(
                    this.getEntityAttributes(metaData.getEntityAttributes),
                    this.getProcedureParameters(metaData.getProcedureParameters)
                );
            })
        );
    }

    private getProcedureParameters(getProcedureParameters: any) {
        return getProcedureParameters.pipe(
            map((result) => {
                this.procedureParameters = result;
                this.changeDetectorRef.detectChanges();
                return this.procedureParameters;
            })
        );
    }

    private getEntityAttributes(
        getEntityAttributes: Observable<{ [key: string]: IEntityAttribute }>
    ) {
        return getEntityAttributes.pipe(
            map((result) => {
                this.entityAttributes = result;
                this.changeDetectorRef.detectChanges();
                return this.entityAttributes;
            })
        );
    }
    private getDefaultFilter(queryParams) {
        return this.paginationService.getFilters(queryParams).pipe(
            map((filter) => {
                this.filter = filter;

                return filter;
            })
        );
    }
    public onPageChanged(evt: PageEvent) {
        this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: {
                pageSize: evt.pageSize,
                pageIndex: evt.pageIndex,
            },
            queryParamsHandling: "merge",
        });
    }
}
