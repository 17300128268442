
export class PaginationModel {
    pageSizeOptions: Array<number> = [10, 20, 30];
    fromRowNo: number;
    numberOfRows: number;
    totalRowCount: number = 0;
    isLoading: boolean = false;
    constructor(fromRowNo: number = 1, numberOfRows?: number) {
        this.fromRowNo = fromRowNo;
        this.numberOfRows = numberOfRows || this.pageSizeOptions[0];
    }
    pageIndex() {
        return Math.round(this.fromRowNo / this.numberOfRows);
    }
}
