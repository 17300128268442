import { QrmProductFamilyTrans } from "app/core/models/entity-models/qrmProductFamilyTrans";
import { Name } from "app/core/decorators";
import { IBaseEntity } from "app/core/interfaces/IBaseEntity";
import { QrmProductFamilyFull } from "app/core/models/entity-models/qrmProductFamilyFull";

/** QRM Product Families */
@Name("QrmProductFamily")
export class QrmProductFamily
    extends QrmProductFamilyTrans
    implements IBaseEntity
{
    name = "QrmProductFamily";
    baseEditRouteSegment = "qrm-product-family";
    hideSelect: boolean;
    get id(): string {
        return this.qrmProductFamilyCE;
    }
    errorMessage: string;

    /** Parent QRM Product Family Code */
    public parentQrmProductFamilyCE: string;

    /** <qrmProductFamilyCE> <qrmProductFamilyLB> */
    public get caption(): string {
        return `${this.qrmProductFamilyCE} ${this.qrmProductFamilyLB}`;
    }

    children: Array<QrmProductFamily> = new Array<QrmProductFamily>();
    static getTree(
        allFamilies: Array<QrmProductFamily>
    ): Array<QrmProductFamily> {
        //create the children for each product family
        //O(n^2)?
        allFamilies.forEach(
            (pf) =>
                (pf.children = pf.children.concat(
                    allFamilies.filter(
                        (x) =>
                            x.parentQrmProductFamilyCE === pf.qrmProductFamilyCE
                    )
                ))
        );

        //return only the top level families aka those with no parents
        return allFamilies.filter((af) => !af.parentQrmProductFamilyCE);
    }

    public listItemLayout(): object {
        return {
            label: { property: "qrmProductFamilyLB" },
            inBrackets: { property: "tracingDT" },
            key: { property: "qrmProductFamilyCE" },
            title: { property: "qrmProductFamilyDS" },
            description: { property: "tracingDT" },
            leftBottomList: [],
            rightBottomList: [{ property: "parentQrmProductFamilyCE" }],
        };
    }
}
