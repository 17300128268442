import { Injectable } from "@angular/core";
import { Location } from "@angular/common";
import {
    AbstractControl,
    UntypedFormGroup,
    UntypedFormArray,
    UntypedFormControl,
} from "@angular/forms";
import * as _ from "lodash";

declare function require(url: string): any;

@Injectable({
    providedIn: "root",
})
export class UtilService {
    constructor(private _location: Location) {}

    /**
     * Returns input string with first char in lower case type
     * @param text
     */
    public initLower(text: string): string {
        return text
            ? `${text.slice(0, 1).toLowerCase()}${text.slice(1)}`
            : text;
    }

    /**
     * Returns input string with first char in upper case type
     * @param text
     */
    public initUpper(text: string): string {
        return text
            ? `${text.slice(0, 1).toUpperCase()}${text.slice(1)}`
            : text;
    }

    /**
     * Adds HTML markup to the input string
     * @param text
     * @param newLineEscapeChar
     */
    public renderText(text: string): string {
        if (!text || typeof text !== "string") {
            return text;
        }

        let newLineText = text.replace(/(?:\r\n|\r|\n)/g, " <br>");
        let htmlText = "";
        //hez to match urls
        const urlMatch = new RegExp(
            "([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[a-z]{2,4})(:[0-9]+)?([^ ])+"
        );

        //If the string matches the expression,
        //it will return an Array containing the entire matched string as the first element,
        //followed by any results captured in parentheses.If there were no matches, null is returned.
        let urlList = newLineText.match(urlMatch);

        while (urlList) {
            const url = urlList[0];

            //we format the link
            let clickableUrl = url.startsWith("www.") ? "http://" + url : url;

            //fix for content issue when '.'is present after the link without space between
            if (clickableUrl.endsWith(".")) {
                clickableUrl = clickableUrl.slice(0, -1);
            }

            //create the link
            const htmlLink = `<a href=${clickableUrl} target='_blank'>${url}</a>`;

            const urlIndex = newLineText.indexOf(url);
            htmlText += newLineText.substr(0, urlIndex) + htmlLink;

            newLineText = newLineText.substring(urlIndex + url.length);

            urlList = newLineText.match(urlMatch);
        }

        htmlText += newLineText;

        return htmlText;
    }

    /**
     * Add HTML <mark> tag for the searchTerm in input string
     * @param text
     * @param searchTerm
     */
    public highlightTerm(text: string, term: string): string {
        if (!text || (term && term.length <= 0)) {
            return text;
        }

        if (text.includes(` target='_blank'>`)) {
            const linkText = text.slice(
                text.indexOf(" target='_blank'>") + 17,
                text.indexOf("</a>")
            );
            const markedLinkText = linkText.replace(
                new RegExp(`(${term})`, "gi"),
                (match) => {
                    return `<mark>${match}</mark>`;
                }
            );
            return `${text.slice(
                0,
                text.indexOf(" target='_blank'>") + 17
            )}${markedLinkText}${text.slice(text.indexOf("</a>"))}`;
        }
        return text.replace(new RegExp(`(${term})`, "gi"), (match) => {
            return `<mark>${match}</mark>`;
        });
    }

    /**
     * Returns the last JSON string from the input. Returned string is not formatted.
     * @param text
     */
    public jsonText(text: string): string {
        if (!text || typeof text !== "string") {
            return text;
        }

        const startIdx1 = text.indexOf("[");
        const endIdx1 = text.lastIndexOf("]");
        const startIdx2 = text.indexOf("{");
        const endIdx2 = text.lastIndexOf("}");
        const startIdx = Math.min(
            ...[startIdx1, startIdx2].filter((_) => _ !== -1)
        );
        const endIdx = startIdx1 < startIdx2 ? endIdx1 : endIdx2;

        if (startIdx < 0 || endIdx < 0) {
            return undefined;
        }

        const jsonString = text.substring(startIdx, endIdx + 1);
        return jsonString;
    }

    /**
     * Return formatted JSON string
     * @param text
     */
    public renderJsonText(text: string): string {
        if (!text || typeof text !== "string") {
            return text;
        }

        let formattedText: string;
        formattedText = text.split("[").join("[<br>");
        formattedText = formattedText.split("{").join("{<br>");
        formattedText = formattedText.split("]").join("<br>]");
        formattedText = formattedText.split("}").join("<br>}");
        formattedText = formattedText.split(',"').join(',<br>"');
        formattedText = formattedText.split('":').join('": ');

        let indentNO: number = 0;
        const lines = formattedText.split("<br>");
        lines.forEach((value, i) => {
            if (
                lines[i - 1] &&
                (lines[i - 1].slice(lines[i - 1].length - 1) === "[" ||
                    lines[i - 1].slice(lines[i - 1].length - 1) === "{")
            ) {
                indentNO++;
            }
            if (
                value &&
                (value.slice(value.length - 1) === "]" ||
                    value.slice(value.length - 1) === "}" ||
                    value.slice(value.length - 2) === "},")
            ) {
                indentNO--;
            }

            let indentedText: string = "";
            for (let j = 0; j < indentNO; j++) {
                indentedText = `&emsp;${indentedText}`;
            }

            lines[i] = `${indentedText}${value}`;
        });

        return lines.join("<br>");
    }

    /**
     * Returns true if the input object have properties
     * @param target
     */
    public isEmptyObject(target: any): boolean {
        return Object.getOwnPropertyNames(target).length <= 0;
    }

    /**
     * Returns clone of target
     * @param target
     */
    public clone(target: any): any {
        let clone = {};
        clone = Object.assign(clone, target);
        return clone;
    }

    /**
     * Assign obj2 to obj1
     * @param obj1
     * @param obj2
     */
    public assignObject(obj1: any, obj2: any): any {
        return Object.assign(obj1, obj2);
    }

    /**
     * Get .png image from /assets if exists. If does not exists gets the default image.
     * @param name
     */
    public getPngImage(name: string): string {
        return this.pngImageExists(name)
            ? this._location.prepareExternalUrl(`assets/${name}.png`)
            : this.getDefaultPngImage(name);
    }

    /**
     * Check if .png image exists in /assets
     * @param name
     */
    public pngImageExists(name: string): boolean {
        let resourceValue: any;
        try {
            //resourceValue = require(`assets/${name}.png`);
            return true;
        } catch (err) {
            return false;
        }
    }

    /**
     * Gets the default .png image from /assets
     * @param name
     */
    public getDefaultPngImage(name?: string): string {
        //console.log(`File not found: assets/${name}.png. Using default image file: assets/Unknown.png`);
        return this._location.prepareExternalUrl("assets/Unknown.png");
    }

    /**
     * Get enum string values
     * @param type
     */
    public enumStringValues(type: any): Array<string> {
        const enumValues: Array<string> = new Array<string>();

        if (!type) {
            return undefined;
        }
        const enumProps = Object.getOwnPropertyNames(type).filter(
            (_) => Object.getOwnPropertyDescriptor(type, _).enumerable
        );
        if (!enumProps) {
            return undefined;
        }

        for (let enumMember in type) {
            const isValueProperty = parseInt(enumMember, 10) >= 0;
            if (isValueProperty) enumValues.push(type[enumMember]);
        }

        return enumValues;
    }

    /**
     * Get enum string value
     * @param type
     */
    public enumStringValue(type: any, value: any): string {
        const enumValues: Array<string> = new Array<string>();

        if (!type) {
            return undefined;
        }
        const enumProps = Object.getOwnPropertyNames(type).filter(
            (_) => Object.getOwnPropertyDescriptor(type, _).enumerable
        );
        if (!enumProps) {
            return undefined;
        }

        return type[value];
    }

    /**
     * Marks form controls as touched
     * @param formControls
     */
    public markFormGroupTouched(
        formControls: { [key: string]: AbstractControl } | AbstractControl[]
    ): void {
        _.forOwn(formControls, (ctrl, controlKey) => {
            if (ctrl instanceof UntypedFormGroup || ctrl instanceof UntypedFormArray) {
                this.markFormGroupTouched(ctrl.controls);
            } else if (ctrl instanceof UntypedFormControl) {
                ctrl.markAsTouched();
            }
        });
    }

    /**
     * Mark form controls as untouched
     * @param formControls
     */
    public markFormGroupUntouched(
        formControls: { [key: string]: AbstractControl } | AbstractControl[]
    ): void {
        _.forOwn(formControls, (ctrl, controlKey) => {
            if (ctrl instanceof UntypedFormGroup || ctrl instanceof UntypedFormArray) {
                this.markFormGroupUntouched(ctrl.controls);
            } else if (ctrl instanceof UntypedFormControl) {
                ctrl.markAsUntouched();
            }
        });
    }

    /**
     * Marks form controls as pristine
     * @param formControls
     */
    public markFormGroupPristine(
        formControls: { [key: string]: AbstractControl } | AbstractControl[]
    ): void {
        _.forOwn(formControls, (ctrl, controlKey) => {
            if (ctrl instanceof UntypedFormGroup || ctrl instanceof UntypedFormArray) {
                this.markFormGroupTouched(ctrl.controls);
            } else if (ctrl instanceof UntypedFormControl) {
                ctrl.markAsPristine();
            }
        });
    }

    /**
     * Update form controls value and validity
     * @param formControls
     */
    public updateFormGroupValueAndValidity(
        formControls: { [key: string]: AbstractControl } | AbstractControl[]
    ): void {
        _.forOwn(formControls, (ctrl, controlKey) => {
            if (ctrl instanceof UntypedFormGroup || ctrl instanceof UntypedFormArray) {
                this.markFormGroupTouched(ctrl.controls);
            } else if (ctrl instanceof UntypedFormControl) {
                ctrl.updateValueAndValidity();
            }
        });
    }

    /**
     * Resetform controls
     * Mark form controls as untouched
     * Marks form controls as pristine
     * Update form controls value and validity
     * @param formControls
     */
    public resetFormGroup(
        formControls: { [key: string]: AbstractControl } | AbstractControl[]
    ): void {
        _.forOwn(formControls, (ctrl, controlKey) => {
            if (ctrl instanceof UntypedFormGroup || ctrl instanceof UntypedFormArray) {
                this.markFormGroupTouched(ctrl.controls);
            } else if (ctrl instanceof UntypedFormControl) {
                ctrl.reset();
            }
        });

        this.markFormGroupUntouched(formControls);
        this.markFormGroupPristine(formControls);
        this.updateFormGroupValueAndValidity(formControls);
    }

    /**
     * Get minimum date
     * @param date1
     * @param date2
     */
    public getMinDate(date1: Date, date2: Date): Date {
        const dDate1 = new Date(date1);
        const dDate2 = new Date(date2);
        if (isNaN(dDate1.getTime()) && isNaN(dDate2.getTime())) {
            return undefined;
        } else if (isNaN(dDate1.getTime())) {
            return dDate2;
        } else if (isNaN(dDate2.getTime())) {
            return dDate1;
        } else {
            return dDate1.getTime() < dDate2.getTime() ? dDate1 : dDate2;
        }
    }

    /**
     * Get maximum date
     * @param date1
     * @param date2
     */
    public getMaxDate(date1: Date, date2: Date): Date {
        const dDate1 = new Date(date1);
        const dDate2 = new Date(date2);

        if (isNaN(dDate1.getTime()) && isNaN(dDate2.getTime())) {
            return undefined;
        } else if (isNaN(dDate1.getTime())) {
            return dDate2;
        } else if (isNaN(dDate2.getTime())) {
            return dDate1;
        } else {
            return dDate1.getTime() < dDate2.getTime() ? dDate2 : dDate1;
        }
    }

    /**
     * return -1 if date < currentDate, or 1 if date > currentDate, or 0 if date == currentDate
     * @param date
     */
    public comparedDateWithCurrentDate(date: Date | string): number {
        const dDate = new Date(date);
        const currentDate = new Date();
        return dDate.getTime() < currentDate.getTime()
            ? -1
            : dDate.getTime() > currentDate.getTime()
            ? 1
            : 0;
    }

    /**
     * Checks if given date is valid
     * @param date
     */
    public isDateValid(date: Date): boolean {
        if (!date) {
            return false;
        }

        const dDate = new Date(date);
        return dDate instanceof Date && !isNaN(dDate.getTime());
    }

    /**
     * Returns unique values of array
     * @param value
     * @param index
     * @param self
     */
    public uniqueArray(value, index, self) {
        return self.indexOf(value) === index;
    }

    /**
     * Changes the shade of the given hex color
     * @param color
     * @param shade
     */
    public changeColorShade(color, shade): string {
        let prefix = false;

        if (color[0] === "#") {
            color = color.slice(1);
            prefix = true;
        }

        const num = parseInt(color, 16);

        let r = (num >> 16) + shade;

        if (r > 255) r = 255;
        else if (r < 0) r = 0;

        let b = ((num >> 8) & 0x00ff) + shade;

        if (b > 255) b = 255;
        else if (b < 0) b = 0;

        let g = (num & 0x0000ff) + shade;

        if (g > 255) {
            g = 255;
        } else if (g < 0) {
            g = 0;
        }

        return (
            (prefix ? "#" : "") +
            (g | (b << 8) | (r << 16)).toString(16).padStart(6, "0")
        );
    }

    /**
     * Replace undefined or null values of properties that ends with "ColorVL" with 0
     * @param visualStyle
     */
    public visualStyleUndefinedToZero(visualStyle: any) {
        Object.getOwnPropertyNames(visualStyle)
            .filter((propName) => propName.endsWith("ColorVL"))
            .forEach((propName) => {
                const colorVL = visualStyle[propName] as number;
                visualStyle[propName] =
                    colorVL === undefined || colorVL === null ? 0 : colorVL;
            });
    }
}
