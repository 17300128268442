import { TransTracingBase } from "app/core/models";
import { Name } from "app/core/decorators";

/** Products : Statuses - Translations */
@Name("ProductStatusTrans")
export class ProductStatusTrans extends TransTracingBase {
    name = "ProductStatusTrans";
    /** Product Status Code                 PK */
    public productStatusCE: string;
    /** Product Status Label */
    public productStatusLB: string;
    /** Product Status Description */
    public productStatusDS: string;
    /** Product Status Info (Hyperlink) */
    public productStatusUR: string;
    /** Product Status Info (Hypertext) */
    public productStatusHT: string;
    /** Product Status Action Description */
    public productStatusActionDS: string;
    /** Product Status Reversal Description */
    public productStatusReversalDS: string;
}
