import { Name } from "app/core/decorators";
import { QrmJobTicketMctMap } from "app/core/models/entity-models/qrmJobTicketMctMap";
import { UnitOfMeasure } from "app/core/models/entity-models/unitOfMeasure";
import { QrmJobTicket } from "app/core/models/entity-models/qrmJobTicket";
import { QrmCell } from "app/core/models/entity-models/qrmCell";
import { QrmProduct } from "app/core/models/entity-models/qrmProduct";

/** QrmJobTicketMctMap Full */
@Name("QrmJobTicketMctMap")
export class QrmJobTicketMctMapFull extends QrmJobTicketMctMap {
    name = "QrmJobTicketMctMap";
    /** MCT Map Unit of Time */
    public unitOfMeasure: UnitOfMeasure;
    /** MCT Map QRM Job Ticket Number */
    public mmpQrmJobTicket: QrmJobTicket;
    /** QRM Job Ticket */
    public qjbtQrmJobTicket: QrmJobTicket;
    /** QRM Cell */
    public qrmCell: QrmCell;
    /** QRM Product */
    public qrmProduct: QrmProduct;
}
