import { TransTracingBase } from "app/core/models";
import { Name } from "app/core/decorators";

/** Project Statuses - Translations */
@Name("ProjectStatusTrans")
export class ProjectStatusTrans extends TransTracingBase {
    name = "ProjectStatusTrans";
    /** Project Status Code                 PK */
    public projectStatusCE: string;
    /** Project Status Label */
    public projectStatusLB: string;
    /** Project Status Description */
    public projectStatusDS: string;
    /** Project Status Info (Hyperlink) */
    public projectStatusUR: string;
    /** Project Status Info (Hypertext) */
    public projectStatusHT: string;
}
