import { TracingBase } from "..";
import { IBaseEntity } from "app/core/interfaces/IBaseEntity";
import { Name } from "app/core/decorators";

/** Employees */
@Name("Employee")
export class Employee extends TracingBase implements IBaseEntity {
    baseEditRouteSegment = "employee";
    public get id(): string {
        return this.employeeID;
    }

    public errorMessage: string;

    /** Employee Identification                 PK */
    public employeeID: string;
    /** Employee Name */
    public employeeNM: string;

    public listItemLayout(): object {
        return {
            label: { property: "employeeNM" },
            inBrackets: { property: "tracingDT" },
            key: { property: "employeeID" },
            title: { property: "tracingDT" },
            description: { property: "tracingDT" },
            leftBottomList: {},
            rightBottomList: [],
        };
    }
}
