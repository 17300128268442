import { Name } from "app/core/decorators";
import { IBaseEntity } from "app/core/interfaces/IBaseEntity";

/** QrmJobTicketMctMap */
@Name("QrmJobTicketMctMap")
export class QrmJobTicketMctMap implements IBaseEntity {
    name = "QrmJobTicketMctMap";
    baseEditRouteSegment: string;
    isLogicallyDeleted: boolean;
    public get id(): string {
        return `${this.mctMapQrmJobTicketNO},${this.mctMapRowSQ}`;
    }

    /** MCT Map QRM Job Ticket Number                               PK */
    public mctMapQrmJobTicketNO: string;
    /** MCT Map Row Sequence Number                                 PK */
    public mctMapRowSQ: number;
    /** QRM Job Ticket Number */
    public qrmJobTicketNO: string;
    /** QRM Job Ticket Reference */
    public qrmJobTicketRF: string;
    /** QRM Job Ticket Description */
    public qrmJobTicketDS: string;
    /** QRM Cell Code */
    public qrmCellCE: string;
    /** QRM Product Code */
    public qrmProductCE: string;
    /** QRM Job Ticket Usage Type */
    public qrmJobTicketUsageTY: string;
    /** MCT Map Row Status Type */
    public mctMapRowStatusTY: string;
    /** Is the QRM Job on the Critical Path ? */
    public criticalPathFL: string;
    /** MCT Map Unit of Time Code */
    public mctMapTimeUnitCE: string;
    /** QRM Job Ticket Offset Time Quantity */
    public qrmJobTicketOffsetTimeQT: number;
    /** QRM Job Ticket MCT Quantity */
    public qrmJobTicketMctQT: number;
    /** QRM Job Ticket Non-Touch Time Quantity */
    public qrmJobTicketNonTouchTimeQT: number;
    /** QRM Job Ticket Touch Time Quantity */
    public qrmJobTicketTouchTimeQT: number;
    /** QRM Job Ticket Touch Time Percentage */
    public qrmJobTicketTouchTimePC: number;
    /** MCT Map Row Pattern Text */
    public mctMapRowPatternTX: string;

    /** qrmJobTicketNO qrmJobTicketDS */
    public get caption(): string {
        return `${this.qrmJobTicketNO}${this.qrmJobTicketDS ? " " : ""}${
            this.qrmJobTicketDS ? this.qrmJobTicketDS.slice(0, 150) : ""
        }${
            this.qrmJobTicketDS && this.qrmJobTicketDS.length > 150 ? "..." : ""
        }`;
    }
}
