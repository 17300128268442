<ng-container *ngIf="!item.hidden">

    <div class="group-title" [ngClass]="item.classes">
        <span class="hint-text" matTooltip="{{item.title | translate}}">
            {{item.title | translate}}
        </span>
    </div>

    <div class="group-items">
        <ng-container *ngFor="let item of item.children">
            <nav-vertical-group *ngIf="item.type === 'group'" [item]="item"></nav-vertical-group>
            <nav-vertical-collapsable *ngIf="item.type === 'collapsable'"
                                           [item]="item"></nav-vertical-collapsable>
            <nav-vertical-item *ngIf="item.type === 'item'" [item]="item"></nav-vertical-item>
        </ng-container>
    </div>

</ng-container>