import { ITracing } from "./interfaces";
import { BaseModel } from "./baseModel";

/** Traceability Model */
export class TracingBase extends BaseModel implements ITracing {
    /** Application where the Record was Created */
    public creationAP: string;
    /** Timestamp of the Creation of the Record */
    public creationDT: Date;
    /** Project where the Record was Created */
    public creationPR: string;
    /** User who has Created the Record */
    public creationUS: string;

    /** Application where the Last Modification was Executed */
    public tracingAP: string;
    /** Inserted, Updated or Deleted */
    public tracingCE: string;
    /** Timestamp of the Last Modification */
    public tracingDT: Date;
    /** Project where the Last Modification was Executed */
    public tracingPR: string;
    /** User who has Executed the Last Modification */
    public tracingUS: string;

    public get isLogicallyDeleted(): boolean {
        return this.tracingCE === "D";
    }
    baseEditRouteSegment: string;
}
