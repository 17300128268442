import { NgModule } from "@angular/core";
import { LayoutPerfectScrollbarDirective } from "./layout-perfect-scrollbar/layout-perfect-scrollbar.directive";

@NgModule({
    declarations: [
        LayoutPerfectScrollbarDirective
    ],
    imports: [],
    exports: [
        LayoutPerfectScrollbarDirective
    ]
})
export class LayoutDirectivesModule {
}
