import { TracingBase } from "app/core/models";
import { Name } from "app/core/decorators";
import { IBaseEntity } from "app/core/interfaces/IBaseEntity";

/** QRM Job Tickets : Activities */
@Name("jobTicketText")
export class jobTicketText extends TracingBase implements IBaseEntity {
    name = "jobTicketResourceGroup";
    /** QRM Job Ticket Number                                           PK */
    public qrmJobTicketNO: string;
    public jobTicketTextSQ: number;

}
