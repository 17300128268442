import { TransTracingBase } from "app/core/models";
import { Name } from "app/core/decorators";

/** Products - Translations */
@Name("ProductTrans")
export class ProductTrans extends TransTracingBase {
    name = "ProductTrans";

    /** Product Code                 PK */
    public productCE: string;
    /** Product Label */
    public productLB: string;
    /** Product Description */
    public productDS: string;
    /** Product Info (Hyperlink) */
    public productUR: string;
    /** Product Info (Hypertext) */
    public productHT: string;
}
