import { NgModule } from "@angular/core";
import { DocViewerComponent } from "./components/doc-viewer/doc-viewer.component";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { CommonModule } from "@angular/common";
import { DocViewerPopupDirective } from "./directive/doc-viewer-popup";
import { LayoutDirectivesModule } from "app/layout/directives/directives";
import { FlexLayoutModule } from "@angular/flex-layout";
import { NgxDocViewerModule } from "ngx-doc-viewer";
import { MatLegacyButtonModule as MatButtonModule } from "@angular/material/legacy-button";
import { MatLegacyDialogModule as MatDialogModule } from "@angular/material/legacy-dialog";
import { MatLegacyProgressBarModule as MatProgressBarModule } from "@angular/material/legacy-progress-bar";
import { TranslateModule } from "app/core/lib/translate/public_api";

@NgModule({
    imports: [
        CommonModule,
        MatDialogModule,
        MatButtonModule,
        MatProgressBarModule,
        DragDropModule,
        TranslateModule,
        FlexLayoutModule,
        LayoutDirectivesModule,
        NgxDocViewerModule,
    ],
    declarations: [DocViewerPopupDirective, DocViewerComponent],
    exports: [DocViewerPopupDirective],
})
export class DocViewerPopupModule {}
