import { ChangeDetectionStrategy, Component, ViewEncapsulation } from "@angular/core";
import { FuseNavigationComponent } from "app/core/@fuse/components/navigation/navigation.component";

@Component({
    selector: "fuse-navigation",
    templateUrl: "./navigation.component.html",
    styleUrls: ["./navigation.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class NavigationComponent extends FuseNavigationComponent{
    
}
