import { IAutoRefresh, IAutoRefreshService } from "../interfaces";
import { Unsubscriber } from ".";
import { Directive } from "@angular/core";
@Directive()
export abstract class AutoRefreshComponent
    extends Unsubscriber
    implements IAutoRefresh {
    public refreshInterval: number;
    public refresher: NodeJS.Timer;

    public abstract onRefresh(): void;

    protected constructor(
        private readonly autoRefreshService: IAutoRefreshService
    ) {
        super();

        this.resetInterval();
    }

    public resetInterval() {
        if (this.refresher) {
            clearInterval(this.refresher);
        }

        this.refreshInterval = this.autoRefreshService.refreshInterval;
        this.refresher = setInterval(() => {
            this.onRefresh();
        }, this.refreshInterval);
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();

        if (this.refresher) {
            clearInterval(this.refresher);
        }
    }
}
