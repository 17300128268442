import { Name } from "app/core/decorators";
import { Project } from "app/core/models/entity-models/project";
import { ProjectStatus } from "app/core/models/entity-models/projectStatus";
import { Customer } from "app/core/models/entity-models/customer";
import { ProjectReportType } from "app/core/models/entity-models/projectReportType";

/** Projects */
@Name("Project")
export class ProjectFull extends Project {
    name = "Project";

    /** Project Status */
    public projectStatus: ProjectStatus;
    /** Forward Project Status             Virtual Attribute */
    public fwdProjectStatus: ProjectStatus;
    /** Reverse Project Status             Virtual Attribute */
    public revProjectStatus: ProjectStatus;
    /** Project Type */
    public projectTY: string;
    /** Customer */
    public customer: Customer;
    /** Project Report Type*/
    public projectReportType: ProjectReportType;
}
