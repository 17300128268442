import { Directive, Inject, SimpleChanges } from "@angular/core";
import { AutoSave } from "./AutoSave";
import { takeUntil, finalize } from "rxjs/operators";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable, combineLatest, forkJoin } from "rxjs";
import {
    EDIT_SERVICE,
    IBaseEditService,
} from "app/core/interfaces/IBaseEditService";
import { BaseModel } from "app/core/models";

@Directive()
export abstract class BaseEntityAutoSave<
    T extends BaseModel
> extends AutoSave<T> {
    ngOnChanges(changes: SimpleChanges): void {}

    private _currentEntity: T;
    private navigatedURL: string;
    private metadataLanguage: string;

    public translationLanguageCE: string;
    public isLoadingRelatedMetadata: boolean;
    public currentEntityCE: string;
    public get currentEntity(): T {
        return this._currentEntity;
    }
    public set currentEntity(entity: T) {
        this._currentEntity = entity;

        //shallow copy
        this.initialEntity = { ...entity } as any as T;
        //shllow copy kills functions so we need to put it back in
        if (entity) {
            this.initialEntity.id = entity.id;
        }
    }

    public initialEntity: T;
    public currentEntityObservable: Observable<T>;
    public languageAttributeId: string;
    public masterId: string;
    public lockCode: boolean;
    public isKeyMandatory: boolean;

    public get isNew(): boolean {
        return !this.currentEntityCE;
    }

    protected constructor(
        entityType: new () => T,
        @Inject(EDIT_SERVICE) protected detailsService: IBaseEditService<T>,
        protected activatedRoute?: ActivatedRoute,
        protected router?: Router
    ) {
        super(entityType, detailsService);
    }

    public ngOnInit() {
        super.ngOnInit();

        this.subscribeToSelectionModelChanges();

        this.detailsService.asyncEntityDefinition
            .pipe(takeUntil(this.isUnsubscribing))
            .subscribe((entityDefinition) => {
                if (
                    entityDefinition &&
                    entityDefinition.primaryKeyByUserFL === "N"
                ) {
                    this.isKeyMandatory = false;
                }
            });

        this.detailsService.asyncEntityAttributes
            .pipe(takeUntil(this.isUnsubscribing))
            .subscribe((entityAttributes) => {
                if (
                    entityAttributes &&
                    this.entityDefinition &&
                    this.entityDefinition.primaryKeyByUserFL === "Y"
                ) {
                    const requiredKeyAttributes = entityAttributes.filter(
                        (attr) =>
                            attr.primaryKeyAttributeFL === "Y" &&
                            attr.nullFL === "N" &&
                            attr.entityCE === this.entityDefinition.entityCE
                    );

                    if (requiredKeyAttributes.length < 1) {
                        this.isKeyMandatory = undefined;
                    } else {
                        if (this.entityDefinition.primaryKeyTY === "USER") {
                            this.isKeyMandatory = true;
                        } else {
                            this.isKeyMandatory = false;
                        }
                    }

                    this.detailsService.showTranslations =
                        entityAttributes.some(
                            (_) =>
                                _.attributeTY.localeCompare("TRANSLATION") === 0
                        );
                    const langAttribute =
                        this.detailsService
                            .projectLanguageTransEntityKeyAttribute ||
                        this.detailsService.projectLanguageAttribute;
                    this.languageAttributeId = langAttribute
                        ? langAttribute.attributeID.toInitLowerCase()
                        : undefined;
                }
            });

        if (this.activatedRoute) {
            combineLatest(
                this.activatedRoute.queryParams,
                this.activatedRoute.params,
                this.detailsService.asyncMetadataReady,
                (queryParams, params, isMetadataReady) => ({
                    queryParams,
                    params,
                    isMetadataReady,
                })
            )
                .pipe(takeUntil(this.isUnsubscribing))
                .subscribe((res) => {
                    if (
                        !res.isMetadataReady ||
                        (res.isMetadataReady &&
                            this.metadataLanguage ===
                                this.detailsService.languageOfMetadata)
                    ) {
                        if (!this.detailsService.isLoadingMetadata) {
                            this.translationService.currentLanguageCode.next(
                                this.translationService.getCurrentLanguageCode()
                            );
                        }
                        return;
                    }

                    this.metadataLanguage =
                        this.detailsService.languageOfMetadata;

                    if (res.queryParams["from"]) {
                        this.masterId = decodeURIComponent(
                            res.queryParams["from"]
                        );
                    }
                    if (!res.queryParams["from"]) {
                        this.checkForMasterId();
                    }
                    if (
                        res &&
                        res.queryParams &&
                        res.queryParams.hasOwnProperty("lang")
                    )
                        this.translationLanguageCE = res.queryParams["lang"];

                    this.errorMessage = "";

                    this.currentEntityCE =
                        res.params["id"] === undefined
                            ? undefined
                            : decodeURIComponent(res.params["id"]);

                    //this.resetSubscription();

                    this.initCurrentEntityObservables();

                    this.loadBaseData();

                    this.loadCurrentEntity();

                    this.loadData();
                });
        }

        this.detailsService.setEntity
            .pipe(takeUntil(this.isUnsubscribing))
            .subscribe((ce) => {
                if (ce && ce === this.currentEntityCE) {
                    this.loadCurrentEntity();
                }
            });

        this.popupService.selectedEntities
            .pipe(takeUntil(this.isUnsubscribing))
            .subscribe((data) => {
                if (data) {
                    this.onRelatedEntitySelected(data);
                }
            });
    }

    protected loadBaseData() {
        this.detailsService.onLogicalDelete.next(undefined);
        this.detailsService.onLogicalDelete
            .pipe(takeUntil(this.isUnsubscribing))
            .subscribe((isLogicalDeleted: boolean) => {
                if (isLogicalDeleted) this.loadCurrentEntity();
            });
    }

    private checkForMasterId() {
        //get master id
        if (
            this.detailsService &&
            this.detailsService
                .isInRelatedTab /*&& this.detailsService.isPopup*/
        ) {
            const routeNode =
                this.activatedRoute.snapshot["_routerState"]["_root"];
            if (
                routeNode &&
                routeNode.children &&
                routeNode.children.length > 0
            ) {
                let node = routeNode.children[0];

                while (
                    (Object.keys(node.value.params).length === 0 ||
                        !node.value.params["id"]) &&
                    node.children &&
                    node.children.length > 0
                ) {
                    node = node.children[0];
                }

                this.masterId = decodeURIComponent(node.value.params["id"]);
            }
        }
    }

    public initCurrentEntityObservables() {
        if (this.currentEntityCE) {
            this.currentEntityObservable = this.detailsService.getCurrentEntity(
                this.currentEntityCE,
                true,
                this.translationLanguageCE
            );
        } else {
            if (this.currentEntity) {
                this.currentEntity = null;
            }
            this.currentEntityObservable =
                this.detailsService.getCurrentEntityDefault();
        }

        this.initObservables();
    }

    protected loadCurrentEntity() {
        this.errorMessage = "";
        this.isLoading = true;

        this.currentEntityObservable
            .pipe(
                takeUntil(this.isUnsubscribing),
                finalize(() => (this.isLoading = false))
            )
            .subscribe(
                (data: T) => {
                    this.currentEntity = data;

                    this.detailsService.setCurrentEntity(data);

                    if (this.currentEntity.id) {
                        this.initNewLinkedData();
                    } else {
                        this.loadWhenNoId();
                    }

                    this.loadCurrentEntityLinkedData();

                    //this.isLoading = false;

                    this.checkChanges();
                }
                //(error: Error) => {
                //    this.errorMessage = (error as any);
                //    this.isLoading = false;
                //}
            );
    }

    protected loadWhenNoId() {}

    protected loadCurrentEntityLinkedData() {
        if (this.initialEntity.id) this.lockCode = true;
    }

    protected lockCurrentEntityId() {
        this.lockCode = true;
    }

    protected abstract subscribeToSelectionModelChanges();

    protected abstract loadData();

    protected abstract initNewLinkedData();

    protected checkChanges() {}

    protected abstract initObservables();

    protected openInPopup(
        modalUri: string,
        modalQueryParams?: { [key: string]: any }
    ) {
        if (modalQueryParams && modalQueryParams.for.startsWith("FK:")) {
            this.popupService.okForSave.next(true);
        }
        this.router.navigate(["", { outlets: { modal: [modalUri] } }], {
            relativeTo: this.activatedRoute,
            skipLocationChange: true,
            queryParams: modalQueryParams ? modalQueryParams : null,
        });
    }

    public onRelatedEntitySelected(
        data: { for: string; entities: Array<any> },
        relatedPropertyName?: string
    ) {
        if (
            !this.currentEntity ||
            !data ||
            !data.for ||
            !data.for.startsWith("FK:") ||
            !data.entities
        ) {
            return;
        }

        const property = data.for.split(":")[1];

        (this.currentEntity as any)[property] =
            data.entities[0][relatedPropertyName || property];

        this.forceDirty();
    }

    public delete(item?: any) {
        this.loadData();
    }

    public loadRelatedEntityMetadata<
        TT extends { new (...args: any[]): any; prototype: any }
    >(
        types: Array<TT>,
        relatedEntities: Array<{ entityId: string; caption: string }>
    ) {
        const calls = types.map((type) =>
            this.detailsService.getOneEntity(type)
        );

        this.isLoadingRelatedMetadata = true;
        this.isLoading = this.isLoading || this.isLoadingRelatedMetadata;
        forkJoin(calls)
            .pipe(
                takeUntil(this.isUnsubscribing),
                finalize(() => {
                    this.isLoadingRelatedMetadata = false;
                    this.isLoading =
                        this.isLoading || this.isLoadingRelatedMetadata;
                })
            )
            .subscribe((entityDefinitions) => {
                entityDefinitions.forEach((entityDefinition) => {
                    const relatedEntity = relatedEntities.find(
                        (e) => e.entityId === entityDefinition.entityID
                    );

                    if (relatedEntity) {
                        relatedEntity.caption = entityDefinition.entityDS;
                    }
                });
            });
    }
}
