import { NgModule } from "@angular/core";
import { MatDividerModule } from "@angular/material/divider";
import { MatLegacyListModule as MatListModule } from "@angular/material/legacy-list";

import { MatLegacySlideToggleModule as MatSlideToggleModule } from "@angular/material/legacy-slide-toggle";
import { FuseSharedModule } from "app/core/@fuse/shared.module";
import { QuickPanelComponent } from "app/layout/components/quick-panel/quick-panel.component";

@NgModule({
    declarations: [QuickPanelComponent],
    imports: [
        MatDividerModule,
        MatListModule,
        MatSlideToggleModule,
        FuseSharedModule,
    ],
    exports: [QuickPanelComponent],
})
export class QuickPanelModule {}
