import { QrmCell } from "app/core/models/entity-models/qrmCell";
import { CapacityCluster } from "app/core/models/entity-models/capacityCluster";
import { Calendar } from "app/core/models/entity-models/calendar";
import { VisualStyle } from "app/core/models/entity-models/visualStyle";
import { UnitOfMeasure } from "app/core/models/entity-models/unitOfMeasure";
import { FocusedTrgMarketSgm } from "app/core/models/entity-models/focusedTrgMarketSgm";
import { Name } from "app/core/decorators";

/** QRM Cells Full */
@Name("QrmCell")
export class QrmCellFull extends QrmCell {
    name = "QrmCell";
    /** Cell Capacity Cluster */
    public capacityCluster: CapacityCluster;
    /** Cell Calendar */
    public calendar: Calendar;
    /** Cell Focused Target Market Segment */
    public focusedTrgMarketSgm: FocusedTrgMarketSgm;
    /** Cell Unit of Time */
    public unitOfMeasure: UnitOfMeasure;
    /** Cell Visual Style */
    public visualStyle: VisualStyle;
    /** Polca Card Capacity Cluster Code */
    public polcaCardCapacityCluster: CapacityCluster;
}
