<!-- modal.component.html -->
<div class="popup-cdk-overlay-container">
    <div class="cdk-overlay-backdrop cdk-overlay-dark-backdrop cdk-overlay-backdrop-showing"></div>
    <div class="cdk-global-overlay-wrapper">
        <div tabindex="0"
             class="cdk-visually-hidden cdk-focus-trap-anchor"></div>
        <div class="cdk-overlay-pane">
            <div class="modal">
                <div class="modal-content">
                    <router-outlet *ngIf="true"></router-outlet>
                </div>
                <div mat-dialog-actions
                     fxLayout="row"
                     fxLayoutAlign="end center">
                    <button id="cancel"
                            mat-button
                            (click)="closeModal($event, true)">{{'DIALOG.CANCEL' | translate}}</button>
                    <button *ngIf="showSave"
                            id="save"
                            mat-button
                            color="primary"
                            type="button"
                            (click)="onSave($event)">{{(showOkForSave ? 'GENERAL.OK' : 'CRUD.SAVE')
                        |translate}}</button>

                </div>
            </div>
        </div>
        <div tabindex="0"
             class="cdk-visually-hidden cdk-focus-trap-anchor"></div>
    </div>
</div>