import { TransTracingBase } from "app/core/models";
import { Name } from "app/core/decorators";

/** QRM Job Tickets : Statuses - Translations */
@Name("QrmJobTicketStatusTrans")
export class QrmJobTicketStatusTrans extends TransTracingBase {
    name = "QrmJobTicketStatusTrans";
    /** QRM Ticket Status Code                                PK */
    public qrmJobTicketStatusCE: string;
    /** QRM Ticket Status Label */
    public qrmJobTicketStatusLB: string;
    /** QRM Ticket Status Description */
    public qrmJobTicketStatusDS: string;
    /** QRM Ticket Status Info (Hyperlink) */
    public qrmJobTicketStatusUR: string;
    /** QRM Ticket Status Info (Hypertext) */
    public qrmJobTicketStatusHT: string;
}
