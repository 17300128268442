import { IStatusEntity } from "app/core/interfaces/IStatusEntity";
import { BaseEntityAutoSave } from "app/core/component-base/models";
import { BehaviorSubject } from "rxjs";
import { EDIT_SERVICE } from "app/core/interfaces/IBaseEditService";
import { Inject, ChangeDetectorRef, Directive } from "@angular/core";
import { IStatusEntityEditService } from "app/core/interfaces/IStatusEntityEditService";
import { ActivatedRoute } from "@angular/router";
import { takeUntil, finalize } from "rxjs/operators";
import { BaseModel } from "app/core/models";

@Directive()
export abstract class StatusEntityAutoSave<
    T extends BaseModel
> extends BaseEntityAutoSave<T> {
    public statusChanged: BehaviorSubject<any>;

    //currentEntityCE: string;
    //currentEntity: T;
    //currentEntityObservable: Observable<T>;

    constructor(
        entityType: new () => T,
        @Inject(EDIT_SERVICE)
        protected detailsService: IStatusEntityEditService<any>,
        protected activatedRoute?: ActivatedRoute,
        protected changeDetect?: ChangeDetectorRef
    ) {
        super(entityType, detailsService);
        this.statusChanged = new BehaviorSubject<any>(null);
    }

    public ngOnInit() {
        super.ngOnInit();
    }

    protected loadCurrentEntityChanges(currentEntity: IStatusEntity) {
        if (currentEntity && this.currentEntity.id) {
            this.isLoading = true;
            this.detailsService
                .getCurrentEntity(this.currentEntity.id, true)
                .pipe(takeUntil(this.isUnsubscribing))
                .subscribe(
                    (data: IStatusEntity) => {
                        //currentEntity.statusCE = data.statusCE;
                        //currentEntity.fwStatusCE = data.fwStatusCE;
                        //currentEntity.revStatusCE = data.revStatusCE;
                        this.copyAttributes(currentEntity, data);

                        this.initialEntity = { ...currentEntity } as any;

                        this.toggleReadOnly(currentEntity);
                        this.isLoading = false;
                    },
                    () => (this.isLoading = false)
                );
        }
    }

    public toggleReadOnly(currentEntity: IStatusEntity) {
        this.readOnly =
            this.isViewMode ||
            (currentEntity &&
                (currentEntity.statusCE === "85" ||
                    (currentEntity as any).isReadOnly));
    }

    protected checkChanges() {
        this.toggleReadOnly((this.currentEntity as any) as IStatusEntity);
    }

    public loadBaseData() {
        super.loadBaseData();
        this.isLoading = true;
        this.detailsService.onCurrentEntityChangeStatus
            .pipe(
                takeUntil(this.isUnsubscribing),
                finalize(() => (this.isLoading = false))
            )
            .subscribe((statusCE) => {
                if (statusCE) {
                    this.loadCurrentEntityChanges(
                        (this.currentEntity as any) as IStatusEntity
                    );
                    this.statusChanged.next(this.currentEntity);
                }
            });
    }

    private copyAttributes(currentEntity: any, newEntity: any) {
        if (!this.indexedEntityAttributes) {
            return;
        }
        Object.getOwnPropertyNames(this.indexedEntityAttributes).forEach(
            (prop) => {
                currentEntity[prop] = newEntity[prop];
            }
        );
    }
}
