import { IUnsubscriber } from "../interfaces";
import { Subject } from "rxjs";
import { Directive } from "@angular/core";
@Directive()
export abstract class Unsubscriber implements IUnsubscriber {
    public isUnsubscribing: Subject<any>;
    public emptyString = "-                    -";
    protected constructor() {
        this.resetSubscription();
    }

    public unsubscribe(): void {
        if (this.isUnsubscribing) {
            this.isUnsubscribing.next({});
            this.isUnsubscribing.complete();
        }
    }

    public resetSubscription(): void {
        this.unsubscribe();
        this.isUnsubscribing = new Subject<any>();
    }

    public ngOnDestroy(): void {
        this.unsubscribe();
    }
}
