import { Name } from "app/core/decorators";
import { Customer } from "app/core/models/entity-models/customer";
import { SalesOrder } from "app/core/models/entity-models/salesOrder";
import { SalesOrderLineFull } from "app/core/models/entity-models/salesOrderLineFull";
import { Language } from "app/core/models/entity-models/language";

/** Sales Orders Full */
@Name("SalesOrder")
export class SalesOrderFull extends SalesOrder {
    public customer: Customer;
    public salesOrderLines: Array<SalesOrderLineFull>;
    public language: Language;
}
