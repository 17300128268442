import { Name } from "app/core/decorators";
import { QrmSimulatedJobTicket } from "app/core/models/entity-models/qrmSimulatedJobTicket";
import { CapacityCluster } from "app/core/models/entity-models/capacityCluster";
import { QrmCell } from "app/core/models/entity-models/qrmCell";
import { UnitOfMeasure } from "app/core/models/entity-models/unitOfMeasure";
import { QrmProduct } from "app/core/models/entity-models/qrmProduct";

/** QRM Simulated Job Tickets */
@Name("QrmSimulatedJobTicket")
export class QrmSimulatedJobTicketFull extends QrmSimulatedJobTicket {
    name = "QrmSimulatedJobTicket";
    /** Needed Capacity Cluster Quantity for the Simulated QRM Job Ticket */
    public capacityCluster: CapacityCluster;
    /** QRM Cell */
    public qrmCell: QrmCell;
    /** Unit of Measure */
    public unitOfMeasure: UnitOfMeasure;
    /** QRM Product */
    public qrmProduct: QrmProduct;
    /** QRM Simulated Job Ticket */
    public qrmSimulatedJobTicket: QrmSimulatedJobTicket;
}
