import { Name } from "app/core/decorators";
import { IBaseEntity } from "app/core/interfaces/IBaseEntity";

/** QrmJobTicketMctMap */
@Name("MctMapTimePosition")
export class MctMapTimePosition implements IBaseEntity {
    name = "MctMapTimePosition";
    rowChanged: string;
    rowFocus: string;
    mctMapID: string;
    mctMapPartSQ: number;
    mctMapRowSQ: number;
    mctMapTimePositionSQ: number;
    mctMapPartTY: string;
    mctMapTimePositionTY: string;
    touchTimeFL: string;
    overdueFL: string;
    criticalPathFL: string;
    mctMapGroupID: string;
    parentMctMapRowSQ: number;
    mctMapTimeUnitCE: string;
    qrmCellCE: string;
    outsourceFL: string;
    qrmProductCE: string;
    qrmProductBomSQ: number;
    qrmJobTicketNO: string;
    theoreticalTouchTimePC: number;
    actualTouchTimePC: number;
    startTimePositionQT: number;
    endTimePositionQT: number;
    currentTimePositionQT: number;
    keyConcat: string;

    baseEditRouteSegment: string;
    isLogicallyDeleted: boolean;
    public get id(): string {
        return `${this.qrmJobTicketNO},${this.mctMapRowSQ}`;
    }

    /** qrmJobTicketNO qrmJobTicketDS */
    public get caption(): string {
        return `${this.qrmJobTicketNO}${this.keyConcat ? " " : ""}${
            this.keyConcat ? this.keyConcat.slice(0, 150) : ""
        }${this.keyConcat && this.keyConcat.length > 150 ? "..." : ""}`;
    }
}
