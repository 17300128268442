import { Name } from "app/core/decorators";
import { ProjectRoleTrans } from "app/core/models/entity-models/projectRoleTrans";
import { IBaseEntity } from "app/core/interfaces/IBaseEntity";

/** Project (member) Roles - Translations */
@Name("ProjectRole")
export class ProjectRole extends ProjectRoleTrans implements IBaseEntity {
    name = "ProjectRole";
    public get id(): string {
        return this.projectRoleCE;
    }
    public errorMessage: string;

    /** Does the Role Allow Managing the Project  */
    public ManagingAllowedFL: string;
    /** Does the Role Allow Budgeting on the Project ? */
    public BudgetingAllowedFL: string;
    /** Does the Role Allow Planning on the Project ? */
    public PlanningAllowedFL: string;
    /** Does the Role Allow Reporting on the Project ? */
    public ReportingAllowedFL: string;
}
