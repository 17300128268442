import { CalendarWeekendWorkDay } from "app/core/models/entity-models/calendarWeekendWorkDay";
import { Calendar } from "app/core/models/entity-models/calendar";
import { Name } from "../../decorators";

/** Calendars : Weekend Working Days Full */
@Name("CalendarWeekendWorkDay")
export class CalendarWeekendWorkDayFull extends CalendarWeekendWorkDay {
    name = "CalendarWeekendWorkDay";
    /** Calendar */
    public calendar: Calendar;
}
